


import React, { useEffect, useState } from 'react'
import { authHeader } from '../_helpers';
import axios from 'axios'
import moment from 'moment';
import Sidebar from './component/Sidebar'
import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,Card,CardContent,CardActions } from '@mui/material'
import PageLoaderComp from '../component/pageLoaderComp';

import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Navigate, useParams } from 'react-router-dom'
import ToastMessageComp from '../component/ToastMessageComp';
import { alertActions } from '../_actions';
import InputAdornment from '@mui/material/InputAdornment';
//-- add ccode
import IconButton from '@mui/material/IconButton';
import { Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateIcon from '@mui/icons-material/Create';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Anchor from '../_helpers/anchor';
import { MdDeleteOutline, MdEditSquare } from 'react-icons/md'
import { LoadingButton } from '@mui/lab';
import {Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@mui/material';
export default function Claimdetails() {
  const params = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [civilite, setCivilite] = useState('');
  const [dialogStatus, setDialogeStatus] = useState("");
  const [confirmType, setConfirmType] = useState("");
  const [claimType, setClaimType] = useState("");
  const [dialoge, setDialoge] = useState({"title":"","msg":""});
  
  const [claimDetails, setClaimDetails] = useState('');

  //-- add code
  const [anchorEl, setAnchorEl] = useState(null);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleClick = (e, rowData) => {
    setConfirmOpen(true);
    setDeleteId(rowData.id);
  };

  const alert = useSelector(state => state.alert);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getClaimDetailsVerification(params.id,params.type);
  }, []);

  async function getClaimDetailsVerification(id,type) {
    setPageLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/admin/get-details-claim/${type}/${id}`);
    
      if(response.data.data.length>0){
        response.data.data.map((val,i)=>{
          let claim_json = val.claim_json_parse;

          let parsedSchedule = claim_json.openinghoursspecification?JSON.parse(claim_json.openinghoursspecification):[];
          
          let claim_json_set = [
            { key:"Nom de la société",value:claim_json.name},
            { key:"Adresse",value:claim_json.streetaddress},
            { key:"Complément d’adresse",value:claim_json.complete_address},
            { key:"Code postal",value:claim_json.postalcode},
            { key:"Ville",value:claim_json.addresslocality},
            { key:"Téléphone",value:claim_json.telephone},
            { key:"Prénom et Nom du Dirigeant",value:claim_json.people_data?JSON.parse(claim_json.people_data):""},
            { key:"Présentation",value:claim_json.presentation},
            { key:"Métier",value:claim_json.professions},
            { key:"Compétences",value:claim_json.skills},
            { key:"Secteurs d’activité",value:claim_json.activity_area?claim_json.activity_area:""},
            { key:"Langues",value:claim_json.languages},
            { key:"Certifications",value:claim_json.certifications?JSON.parse(claim_json.certifications):""},
            { key:"Horaires d’ouverture",value:parsedSchedule},
            { key:"Partager votre profil société",value:[{"key":"Website URL", "value":claim_json.website_url},{"key":"Linkedin URL", "value":claim_json.linkedin_url},{"key":"Youtube URL", "value":claim_json.youtube_url},{"key":"Viadeo URL", "value":claim_json.viadeo_url}]},
            { key:"Logo",value:claim_json.logo}
          ]
          if(type === "avocats"){
            claim_json_set = [
              { key:"Nom de la société",value:claim_json.cbRaisonSociale},
              { key:"Nom",value:claim_json.avNom},
              { key:"Prenom",value:claim_json.avPrenom},
              { key:"Adresse",value:claim_json.cbAdresse1},
              { key:"Complément d’adresse",value:claim_json.cbAdresse2},
              { key:"Code postal",value:claim_json.cbCp},
              { key:"Ville",value:claim_json.cbVille},
              { key:"Téléphone",value:claim_json.telephone},
              { key:"Prénom et Nom du Dirigeant",value:claim_json.people_data?JSON.parse(claim_json.people_data):""},
              { key:"Présentation",value:claim_json.presentation},
              { key:"Métier",value:claim_json.professions},
              { key:"Compétences",value:claim_json.skills},
              { key:"Secteurs d’activité",value:claim_json.activity_area?claim_json.activity_area:""},
              { key:"Langues",value:claim_json.avLang},
              { key:"Certifications",value:claim_json.certifications?JSON.parse(claim_json.certifications):""},
              { key:"Horaires d’ouverture",value:parsedSchedule},
              { key:"Partager votre profil société",value:[{"key":"Website URL", "value":claim_json.website_url},{"key":"Linkedin URL", "value":claim_json.linkedin_url},{"key":"Youtube URL", "value":claim_json.youtube_url},{"key":"Viadeo URL", "value":claim_json.viadeo_url}]},
              { key:"Logo",value:claim_json.logo}
            ]
          }
          if(type === "notairs"){
            claim_json_set = [
              { key:"Nom de la société",value:claim_json.name},             
              { key:"Adresse",value:claim_json.address_line},
              { key:"Complément d’adresse",value:claim_json.complete_address},
              { key:"Code postal",value:claim_json.postal_code},
              { key:"Ville",value:claim_json.locality},
              { key:"Téléphone",value:claim_json.phone},
              { key:"Prénom et Nom du Dirigeant",value:claim_json.people_data?JSON.parse(claim_json.people_data):""},
              { key:"Présentation",value:claim_json.presentation},
              { key:"Métier",value:claim_json.professions},
              { key:"Compétences",value:claim_json.skills},
              { key:"Secteurs d’activité",value:claim_json.activity_area?claim_json.activity_area:""},
              { key:"Langues",value:claim_json.spoken_languages?JSON.parse(claim_json.spoken_languages):""},
              { key:"Certifications",value:claim_json.certifications?JSON.parse(claim_json.certifications):""},
              { key:"Horaires d’ouverture",value:parsedSchedule},
              { key:"Partager votre profil société",value:[{"key":"Website URL", "value":claim_json.website},{"key":"Linkedin URL", "value":claim_json.linkedin_url},{"key":"Youtube URL", "value":claim_json.youtube_url},{"key":"Viadeo URL", "value":claim_json.viadeo_url}]},
              { key:"Logo",value:claim_json.logo}
            ]
          }
          val.claim_json_set = claim_json_set;
          return val;
        })  
      }
      setClaimDetails(response.data.data);
      setPageLoading(false)

    } catch (error) {
      console.error(error);
      setPageLoading(false)

    }
  };

  useEffect(() => {
    if(dialogStatus==="approved" || dialogStatus==="approve"){
      approvedClaim();
    }
    if(dialogStatus==="gold"){
      approvedClaim();
    }
    if(dialogStatus==="delete"){
      // deleteClaim();
    }
  }, [dialogStatus]);

  const handleClickOpen = (e,rowData,type,claimTypeGet) => {
    setConfirmType(type);
    setClaimType(claimTypeGet);
    setOpen(true);
    if(type === "approve"){
      setDialoge({"title":"Approuver la réclamation","msg":"Etes-vous sûr de vouloir approuver ?"});

    }else if(type === "gold"){
      setDialoge({"title":"Convertir Approuver en or","msg":"Etes-vous sûr de vouloir convertir ?"});

    }else if(type === "delete"){
      setDialoge({"title":"Supprimer la réclamation","msg":"Etes-vous sûr de vouloir supprimer ?"});
    }else{
      setDialoge({"title":"","msg":""});
    }
  };
  const deleteClaim= async()=>{
    setSuccessful(false);
    setLoading(true);
    try {
      let sendData = {
        // documentId: claimDetails[0].claim_json_parse.documentId,
        documentId: claimDetails[0]._id,
        accountant_id: claimDetails[0].claim_json_parse.id,
        id: claimDetails[0].claim_json_parse.id,
        type: claimType
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/delete-claim`, sendData);

      setSuccessful(true);
      setLoading(false);  
      if(response.data.success === false){
        dispatch(alertActions.error(response.data.msg));       
      }else{
        dispatch(alertActions.success(response.data.msg));        
      }
          
      
    } catch (error) {
      console.error(error);      
    }
  }
  const approvedClaim=async()=>{
    setSuccessful(false);
    setLoading(true);
    setPageLoading(true)
    try {
      let sendData = {
        // documentId: claimDetails[0].claim_json_parse.documentId,
        documentId: claimDetails[0]._id,
        accountant_id: claimDetails[0].claim_json_parse.id,
        id: claimDetails[0].claim_json_parse.id,
        type: params.type,
        partner_status:confirmType==="approve"?"approved":confirmType,
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/claim-verification`, sendData);
      setSuccessful(true);
      setLoading(false);  
      setPageLoading(false);  
      if(response.data.success === false){
        dispatch(alertActions.error(response.data.msg));       
      }else{
        dispatch(alertActions.success(response.data.msg));    
        await getClaimDetailsVerification(params.id,params.type);    
      }  
      
    } catch (error) {
      console.error(error);  
      setSuccessful(true);
      dispatch(alertActions.error(error.message));
      setLoading(false);
      setPageLoading(false)         
    }
  }
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/">
      Tableau de bord
    </Link>,
    <Link underline="hover" key="1" color="inherit" to="/admin/claims">
      Fiches professionnelles
    </Link>,
    <Typography key="3"  >
      Détails des réclamations
    </Typography>,
  ];


  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  

  const currentAdmin = useSelector(state => state.administrator.admin);

  if (!currentAdmin) {
    return <Navigate to="/admin/login" />;
  }

  return (
    <>
      {successful ?
        <ToastMessageComp message={alert.message} type={alert.type} />
        : ""}
       {pageLoading ?
        <PageLoaderComp />
        : ""}
      
              <div className=' w-full h-full bg-white p-8'>
                <Typography variant="h4" component="h4" fontWeight={500} color={'#1CBE93'}>Détails des réclamations</Typography>
                <Stack spacing={2} mb={3} >
                  <Breadcrumbs separator="›" aria-label="breadcrumb">
                    {breadcrumbs}
                  </Breadcrumbs>
                </Stack>
                <Grid container spacing={4}>                  
                  <Grid xs={12}>
                    <Item>
                      <Grid container spacing={4}>
                        <Grid xs={12} className={"p-5"}>  
                        {
                          claimDetails && claimDetails.length>0  && claimDetails[0].claim_json_set && claimDetails[0].claim_json_set.length>0?claimDetails[0].claim_json_set.map((claimData,claimIndex)=>{
                            let claimDataVal = claimData.value;
                            
                            if(Array.isArray(claimDataVal) || typeof claimDataVal === 'object'){
                              if(claimData.key === "Prénom et Nom du Dirigeant"){
                                let claimDataValStr = claimDataVal.map((cerVal,cerKey)=>{
                                  return(
                                    <>
                                    <p variant="h6" component="h6" fontWeight={500} sx={{ textAlign: 'left' }} color={'#27295B'}>{cerVal.name}</p>
                                    </>
                                  )
                                  
                                })
                                claimDataVal = claimDataValStr;
                                
                              }else if(claimData.key === "Certifications"){
                                let claimDataValStr = claimDataVal.map((cerVal,cerKey)=>{
                                  return(
                                    <div className="flex flex-row  gap-4 " key={"claimCer_"+cerKey} >
                                    <Typography variant="subtitle1" component="subtitle1" fontWeight={500} sx={{ textAlign: 'left' }} color={'#27295B'}>Certifications name: {cerVal.certifications_name}</Typography>, 
                                    <Typography variant="subtitle1" component="subtitle1" fontWeight={500} sx={{ textAlign: 'left' }} color={'#27295B'}>Certifications Number {cerVal.certifications_number}</Typography></div>
                                  )
                                  
                                })
                                claimDataVal = claimDataValStr;
                                
                              }else if(claimData.key === "Partager votre profil société"){
                                let claimDataValStr = claimDataVal.map((cerVal,cerKey)=>{
                                  return(
                                    <div className="flex flex-row  gap-4 " key={"claimCer_"+cerKey} >
                                    <Typography variant="subtitle1" component="subtitle1" fontWeight={500} sx={{ textAlign: 'left' }} color={'#27295B'}>{cerVal.key}: <Link underline="hover" key="1" color="inherit" target='_blank' to={cerVal.value}>{cerVal.value}</Link></Typography></div>
                                  )
                                  
                                })
                                claimDataVal = claimDataValStr;
                                
                              }else if(claimData.key === "Horaires d’ouverture"){
                                if (Array.isArray(claimDataVal)) {
                                  let claimDataValStr = claimDataVal.map((cerVal,cerKey)=>{
                                    return(
                                      cerVal.openingHours_evg.checked?
                                      <div className="flex flex-row  gap-4 " key={"claimT_"+cerKey} >
                                      <Typography variant="subtitle1" component="subtitle1" fontWeight={500} sx={{ textAlign: 'left' }} color={'#27295B'}>{cerVal.dayName}:</Typography>
                                      <Typography variant="subtitle1" component="subtitle1" fontWeight={500} sx={{ textAlign: 'left' }} color={'#27295B'}>{cerVal.openingHours_evg.type}: {cerVal.openingHours_evg.time}</Typography>,
                                      <Typography variant="subtitle1" component="subtitle1" fontWeight={500} sx={{ textAlign: 'left' }} color={'#27295B'}>{cerVal.openingHours_mrg.type}: {cerVal.openingHours_mrg.time}</Typography>
                                      </div>:""
                                    )
                                    
                                  })
                                
                                  claimDataVal = claimDataValStr;

                                }else if(typeof claimDataVal === 'object'){
                                    const claimDataValStr = Object.keys(claimDataVal).map((day) => {
                                      const slots = claimDataVal[day];
                                      return slots.some(slot => slot.checked) ? (
                                          <div className="flex flex-row gap-4" key={`claimT_${day}`}>
                                              <Typography variant="subtitle1" component="subtitle1" fontWeight={500} sx={{ textAlign: 'left' }} color={'#27295B'}>
                                                  {day}:
                                              </Typography>
                                              {slots.map((slot, index) => slot.checked && (
                                                  <Typography key={index} variant="subtitle1" component="subtitle1" fontWeight={500} sx={{ textAlign: 'left' }} color={'#27295B'}>
                                                      {slot.type}: {slot.value}
                                                  </Typography>
                                              ))}
                                          </div>
                                      ) : null;
                                    });
                                    claimDataVal = claimDataValStr;
                                  }else{
                                     // Filter out null values
                                    let claimDataValStr = claimDataVal.map((cerVal,cerKey)=>{
                                      return(
                                        cerVal.openingHours_evg.checked?
                                        <div className="flex flex-row  gap-4 " key={"claimT_"+cerKey} >
                                        <Typography variant="subtitle1" component="subtitle1" fontWeight={500} sx={{ textAlign: 'left' }} color={'#27295B'}>{cerVal.dayName}:</Typography>
                                        <Typography variant="subtitle1" component="subtitle1" fontWeight={500} sx={{ textAlign: 'left' }} color={'#27295B'}>{cerVal.openingHours_evg.type}: {cerVal.openingHours_evg.time}</Typography>,
                                        <Typography variant="subtitle1" component="subtitle1" fontWeight={500} sx={{ textAlign: 'left' }} color={'#27295B'}>{cerVal.openingHours_mrg.type}: {cerVal.openingHours_mrg.time}</Typography>
                                        </div>:""
                                      )
                                      
                                    })
                                  
                                    claimDataVal = claimDataValStr;
                                    
                                  }
                                
                                
                                
                              }else{
                                claimDataVal = (claimData.value).join(",");
                              }
                              
                            }
                              return (
                                <div className="flex flex-row  gap-4 p-1 " key={"claim_"+claimIndex} >
                                    <Typography variant="h6" component="h6" fontWeight={600} sx={{ textAlign: 'left', minWidth:300 }} color={'#27295B'}>{claimData.key}:</Typography> 
                                    <Typography variant="subtitle1" component="subtitle1" fontWeight={500} sx={{ textAlign: 'left' }} color={'#27295B'}>                                     
                                      {claimData.key === "Logo" && claimData.value!=''?<Link underline="hover" key="1" color="inherit" target='_blank' to={claimData.value}><img src={claimData.value} alt={claimData.value} width={"200px"} height={"200px"} /></Link>:claimDataVal}
                                    </Typography>
                                </div>
                              )
                          }):""
                        }                        
                         <Stack spacing={2} className='mt-5' direction="row">
                         {
                            claimDetails && claimDetails.length>0  && claimDetails[0].claim_verified && claimDetails[0]._source.partner_status === "approved"?
                            <LoadingButton
                              size="small"
                              onClick={(e)=>handleClickOpen(e,claimDetails,"gold","accountant")}
                              // startIcon={<SaveIcon />}
                              loading={loading}
                              loadingPosition="start"
                              variant="contained"
                              className=' me-2' 
                              style={{"background":"#ccb017"}}
                              >
                              <span>convertir en or</span>
                              </LoadingButton>  
                          :
                          <LoadingButton
                            size="medium"
                            onClick={(e)=>handleClickOpen(e,claimDetails,"approve","accountant")}
                            // startIcon={<SaveIcon />}
                            loading={loading}
                            loadingPosition="start"
                            variant="contained"
                            className={claimDetails && claimDetails.length>0  && claimDetails[0].claim_verified && claimDetails[0]._source.partner_status === "gold" ?'hidden me-2':"me-2"}                                    >
                            <span>Approuver</span>
                          </LoadingButton>
                          }

                          <LoadingButton
                            size="medium"
                            onClick={(e)=>handleClickOpen(e,claimDetails,"delete","accountant")}
                            // onClick={approvedClaim}
                            // loading={loading}
                            // loadingPosition="start"
                            variant="contained"
                            color="error"
                            className={!(claimDetails && claimDetails.length>0  && claimDetails[0].claim_verified)?'me-2':"hidden me-2"}
                            startIcon={<DeleteIcon />} 
                          >
                            <span>Supprimer</span>
                          </LoadingButton>
                        </Stack>
                        </Grid>
                        
                      </Grid>

                    </Item>
                  </Grid>

                </Grid>

              </div>
            
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialoge.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialoge.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={(e)=>{
            setDialogeStatus(confirmType);
            handleClose();
          }} 
          autoFocus >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      
    </>
  )
}
