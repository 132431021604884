import { adminConstants } from '../_constants';
import { adminService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';


export const adminActions = {
    login,
    logout,
    register,
    getAll,
    update,
    addAddress, 
    createJournal,
    updateJournal,     
    delete: _delete,
   
};

function login(userdata) {    
    return dispatch => {
        dispatch(request({ username:userdata.username }));
        return adminService.login(userdata)
        .then(
            admin => { 
               
                if(admin.sucess === true){
                    dispatch(success(admin));
                    // history.push(from);  
                    dispatch(alertActions.success(admin.msg)); 
                    return Promise.resolve(admin);   
                }else{
                    dispatch(failure(admin.msg));
                    dispatch(alertActions.error(admin.msg));  
                    return Promise.reject(admin);
                }                                  
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));  
                return Promise.reject();                 
            }
        );
    };

    function request(admin) { return { type: adminConstants.LOGIN_REQUEST, admin } }
    function success(admin) { return { type: adminConstants.LOGIN_SUCCESS, admin } }
    function failure(error) { return { type: adminConstants.LOGIN_FAILURE, error } }
}

function logout() {
    adminService.logout();
    return { type: adminConstants.LOGOUT };
}
function addAddress(formData){
    return dispatch => {
        dispatch(request(formData));
        return adminService.addAddress(formData)
            .then(
                user_address => { 
                   
                    dispatch(success(user_address.user_address)); 
                    dispatch(alertActions.success(user_address.msg));                 
                    return Promise.resolve(user_address.user_address);                      
                    
                },
                error => {                   
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    return Promise.reject();
                }
            );
    };
    function request(user_address) { return { type: adminConstants.ADDRESS_REQUEST, user_address } }
    function success(user_address) { return { type: adminConstants.ADDRESS_SUCCESS, user_address } }
    function failure(error) { return { type: adminConstants.ADDRESS_FAILURE, error } }
}
function register(user) {
    if(user.signup_type === 'google' || user.signup_type === 'facebook'){
        return dispatch => {
            dispatch(requestSocial(user));
            return adminService.register(user)
                .then(
                    user => {  
                        if(user.success === true){
                            dispatch(successSocial(user)); 
                            dispatch(alertActions.success(user.msg));                 
                            return Promise.resolve(user);   
                        }else{
                            dispatch(failureSocial("Une erreur s'est produite. Veuillez réessayer!"));
                            dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!"));
                            return Promise.reject();
                        }   
                                           
                        
                    },
                    error => {
                       
                        dispatch(failureSocial(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                        return Promise.reject();
                    }
                );
        };
    }else{
        return dispatch => {
            dispatch(request(user));
            return adminService.register(user)
                .then(
                    user => { 
                        if(user.sucess === true){
                            dispatch(success(user)); 
                            dispatch(alertActions.success(user.msg));                 
                            return Promise.resolve(user);   
                        }else{
                            dispatch(failure("Une erreur s'est produite. Veuillez réessayer!"));
                            dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!"));
                            return Promise.reject();
                        }               
                        
                    },
                    error => {
                       
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                        return Promise.reject();
                    }
                );
        };

    }
    
    function requestSocial(user) { return { type: adminConstants.LOGIN_REQUEST, user } }
    function successSocial(user) { return { type: adminConstants.LOGIN_SUCCESS, user } }
    function failureSocial(error) { return { type: adminConstants.LOGIN_FAILURE, error } }

    function request(user) { return { type: adminConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: adminConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: adminConstants.REGISTER_FAILURE, error } }
}

function update(adminData) {
    return dispatch => {
        dispatch(request(adminData));
        return adminService.update(adminData)
            .then(
                adminRes => {                  

                    if(adminRes.sucess === true){
                        dispatch(success(adminRes));
                        dispatch(alertActions.success(adminRes.msg));                    
                        return Promise.resolve(); 
                    }else{
                        dispatch(failure("Une erreur s'est produite. Veuillez réessayer!"));
                        dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!"));
                        return Promise.reject();
                    }                      
                    
                },
                error => {
                   
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    return Promise.reject();
                }
            );
    };    
    function request(admin) { return { type: adminConstants.LOGIN_REQUEST, admin } }
    function success(admin) { return { type: adminConstants.LOGIN_SUCCESS, admin } }
    function failure(error) { return { type: adminConstants.LOGIN_FAILURE, error } }
}
function getAll() {
    return dispatch => {
        dispatch(request());

        adminService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: adminConstants.GETALL_REQUEST } }
    function success(users) { return { type: adminConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: adminConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        adminService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: adminConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: adminConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: adminConstants.DELETE_FAILURE, id, error } }
}

function createJournal(data) {
    
    return dispatch => {
        dispatch(request(data));
        return adminService.createJournal(data)
            .then(
                result => {                  

                    if(result.sucess === true){
                        dispatch(success(result));
                        dispatch(alertActions.success(result.msg));                    
                        return Promise.resolve(result); 
                    }else{
                        dispatch(failure("Une erreur s'est produite. Veuillez réessayer!"));
                        dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!"));
                        return Promise.reject();
                    }                      
                    
                },
                error => {
                   
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    return Promise.reject();
                }
            );
    };    
    function request(journal) { return { type: adminConstants.JOURNAL_CREATE_REQUEST, journal } }
    function success(journal) { return { type: adminConstants.JOURNAL_CREATE_SUCCESS, journal } }
    function failure(error) { return { type: adminConstants.JOURNAL_CREATE_FAILURE, error } }
}
function updateJournal(data) {
    return dispatch => {
        dispatch(request(data));
        return adminService.updateJournal(data)
            .then(
                result => {                  

                    if(result.sucess === true){
                        dispatch(success(result));
                        dispatch(alertActions.success(result.msg));                    
                        return Promise.resolve(result); 
                    }else{
                        dispatch(failure("Une erreur s'est produite. Veuillez réessayer!"));
                        dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!"));
                        return Promise.reject();
                    }                      
                    
                },
                error => {
                   
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    return Promise.reject();
                }
            );
    };    
    function request(journal) { return { type: adminConstants.JOURNAL_UPDATE_REQUEST, journal } }
    function success(journal) { return { type: adminConstants.JOURNAL_UPDATE_SUCCESS, journal } }
    function failure(error) { return { type: adminConstants.JOURNAL_UPDATE_FAILURE, error } }
}