import React from "react";
import WebRoutes from "./Routes/WebRoutes";

export default function App() {
  
  
  /* const location = useLocation();
  const navigate = useNavigate();
  console.log("location.pathname",location.pathname) */
 /*  useEffect(()=>{
    if (location.pathname.match(/\/{2,}/g)) {
      // push(location.pathname.replace(/\/{2,}/g, "/"));
      console.log(`location.pathname.replace(/\/{2,}/g, "/")`,location.pathname.replace(/\/{2,}/g, "/"))
      navigate(location.pathname.replace(/\/{2,}/g, "/"), { replace: true });
    }
  }) */
  return <WebRoutes />; 
}
