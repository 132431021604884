import React from 'react'
import { HiOutlineMenuAlt1 } from 'react-icons/hi'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { Link,NavLink,useNavigate } from 'react-router-dom'
import { Menu } from '@headlessui/react'
import { useSelector,useDispatch } from 'react-redux';
import { userActions } from '../_actions'
import Avatar from '@mui/material/Avatar';
import { alertActions } from '../_actions';
import { useState } from 'react'
import ToastMessageComp from './ToastMessageComp'
export default function MobileMenu(props) {
  const alert = useSelector(state => state.alert);
  const user = useSelector(state => state.authentication.user);
  const [successful, setSuccessful] = useState(false);
  const dispatch = useDispatch(); 
  let navigate = useNavigate();
  const logoutHandler=()=>{
    setSuccessful(true);
    props.handelClick();
    dispatch(userActions.logout())
    dispatch(alertActions.success("Vous avez été déconnecté avec succès !"));
    setTimeout(() => {
      navigate("/login");        
    }, 1000);
  }
  
  return (
    <> 
     {successful?
      <ToastMessageComp  message={alert.message} type={alert.type} />
      :""}   
    <ul className='flex flex-col gap-6 mt-5'>
    <li >
        <div className='flex items-center sm:gap-8 gap-5'>
          <div className='flex items-center  sm:gap-8 gap-6'>
            <Link to="tel:01 53 65 16 66" className=' text-base font-medium text-primary hidden xl:flex items-center gap-2 hover:text-secondary' >  <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_3449_3899)">
                <path d="M24.7706 30.4165C19.4971 30.4097 14.4415 28.3117 10.7126 24.5828C6.98366 20.8539 4.88574 15.7983 4.87891 10.5248C4.87891 8.94899 5.5049 7.43771 6.61918 6.32343C7.73346 5.20915 9.24474 4.58316 10.8206 4.58316C11.1543 4.58061 11.4874 4.6109 11.8152 4.67357C12.132 4.72046 12.4435 4.79833 12.7452 4.90607C12.9573 4.98051 13.1464 5.10904 13.2936 5.27894C13.4409 5.44884 13.5412 5.65424 13.5847 5.87482L15.3543 13.6248C15.402 13.8352 15.3963 14.0541 15.3376 14.2617C15.279 14.4693 15.1692 14.6589 15.0185 14.8132C14.8506 14.994 14.8377 15.0069 13.2489 15.8336C14.5212 18.6247 16.7535 20.8661 19.5393 22.1498C20.3789 20.5482 20.3918 20.5352 20.5727 20.3673C20.7269 20.2166 20.9165 20.1069 21.1241 20.0482C21.3317 19.9895 21.5506 19.9838 21.761 20.0315L29.511 21.8011C29.7245 21.8506 29.9219 21.9537 30.0846 22.1006C30.2473 22.2474 30.3699 22.4333 30.441 22.6407C30.55 22.9472 30.6321 23.2627 30.6864 23.5836C30.7384 23.9082 30.7643 24.2365 30.7639 24.5652C30.7401 26.1343 30.0965 27.6303 28.9736 28.7265C27.8507 29.8228 26.3398 30.4303 24.7706 30.4165Z" fill="#1CBE93" />
              </g>
              <defs>
                <filter id="filter0_d_3449_3899" x="0.294922" y="0" width="35" height="35" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_3449_3899" />
                  <feOffset />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.01 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3449_3899" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3449_3899" result="shape" />
                </filter>
              </defs>
            </svg>
              01 53 65 16 66</Link>

            <Link to={"/login"} onClick={props.handelClick} className={user && user.data ?"hidden text-sm sm:text-base font-medium text-primary bg-transparent flex items-center gap-2 hover:text-secondary":"text-sm sm:text-base font-medium text-primary bg-transparent flex items-center gap-2 hover:text-secondary"} >
              <svg width="33" height="33" className='w-[28px] h-[28px] sm:w-auto sm-h-auto' viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.2949 0.5C7.46292 0.5 0.294922 7.668 0.294922 16.5C0.294922 25.332 7.46292 32.5 16.2949 32.5C25.1269 32.5 32.2949 25.332 32.2949 16.5C32.2949 7.668 25.1269 0.5 16.2949 0.5ZM16.2949 6.9C19.3829 6.9 21.8949 9.412 21.8949 12.5C21.8949 15.588 19.3829 18.1 16.2949 18.1C13.2069 18.1 10.6949 15.588 10.6949 12.5C10.6949 9.412 13.2069 6.9 16.2949 6.9ZM16.2949 29.3C13.0469 29.3 9.20692 27.988 6.47092 24.692C9.27349 22.4932 12.7327 21.2981 16.2949 21.2981C19.8571 21.2981 23.3164 22.4932 26.1189 24.692C23.3829 27.988 19.5429 29.3 16.2949 29.3Z" fill="#1CBE93" />
              </svg>
              Se connecter</Link>

            <ul>
              <li className='relative group'>
              <Menu>
                  <Menu.Button className={user && user.data ?"text-sm sm:text-base font-medium text-primary bg-transparent flex items-center gap-2 hover:text-secondary":"hidden text-sm sm:text-base font-medium text-primary bg-transparent flex items-center gap-2 hover:text-secondary"}  >
                    {user && user.data && user.data.profile_image?
                    <Avatar alt={user.data.profile_image} src={user.data.profile_image} sx={{ width: 33, height: 33 }} />
                    : 
                    <svg width="33" height="33" className='w-[28px] h-[28px] sm:w-auto sm-h-auto' viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.2949 0.5C7.46292 0.5 0.294922 7.668 0.294922 16.5C0.294922 25.332 7.46292 32.5 16.2949 32.5C25.1269 32.5 32.2949 25.332 32.2949 16.5C32.2949 7.668 25.1269 0.5 16.2949 0.5ZM16.2949 6.9C19.3829 6.9 21.8949 9.412 21.8949 12.5C21.8949 15.588 19.3829 18.1 16.2949 18.1C13.2069 18.1 10.6949 15.588 10.6949 12.5C10.6949 9.412 13.2069 6.9 16.2949 6.9ZM16.2949 29.3C13.0469 29.3 9.20692 27.988 6.47092 24.692C9.27349 22.4932 12.7327 21.2981 16.2949 21.2981C19.8571 21.2981 23.3164 22.4932 26.1189 24.692C23.3829 27.988 19.5429 29.3 16.2949 29.3Z" fill="#1CBE93" />
                    </svg>
                    }
                    {user && user.data ?user.data.firstname+" "+user.data.lastname:" Se connecter"}
                      <svg width="16" height="9" viewBox="0 0 16 9" className='group-hover:fill-secondary mt-1' fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.347684 0.311086C0.570373 0.111898 0.872364 0 1.18725 0C1.50213 0 1.80412 0.111898 2.02681 0.311086L7.90493 5.57046L13.7831 0.311086C14.007 0.117543 14.307 0.0104483 14.6183 0.0128692C14.9297 0.01529 15.2275 0.127032 15.4477 0.324028C15.6679 0.521025 15.7928 0.787513 15.7955 1.0661C15.7982 1.34468 15.6785 1.61307 15.4622 1.81346L8.7445 7.82402C8.52181 8.02321 8.21982 8.13511 7.90493 8.13511C7.59005 8.13511 7.28806 8.02321 7.06537 7.82402L0.347684 1.81346C0.125062 1.61421 0 1.34401 0 1.06227C0 0.780536 0.125062 0.510334 0.347684 0.311086V0.311086Z" fill="#27295B" className='group-hover:fill-secondary' />
                    </svg>
                  </Menu.Button>
                  <Menu.Items className=" top-2  text-left w-[250px]  focus:outline-none transform opacity-100 scale-100">
                    <Menu.Item className="group flex justify-left w-full items-center rounded-md px-2 py-2 text-base font-medium text-primary hover:text-secondary">
                      {({ active }) => (
                        <Link to={"/dashboard"}  onClick={props.handelClick}
                          className={`${active && 'bg-blue-500'}`}
                          
                        >
                          Mon tableau de bord
                        </Link>
                      )}
                    </Menu.Item>

                    <Menu.Item className="group flex w-full items-left justify-left rounded-md px-2 py-2 text-base font-medium text-primary hover:text-secondary">
                      {({ active }) => (
                        <Link to={"/profile"}
                          className={`${active && 'bg-blue-500'}`}
                          onClick={props.handelClick}
                        >
                          Mon compte
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item className="group flex w-full items-center justify-left rounded-md px-2 py-2 text-base font-medium text-primary hover:text-secondary">
                      {({ active }) => (
                        <Link onClick={logoutHandler}
                          className={`${active && 'bg-blue-500'}`}
                          
                        >
                          Me déconnecter
                        </Link>
                      )}
                      
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
                </li>
            </ul>       
          </div>
        </div>
    </li>  
   <li onClick={props.handelClick} ><Link className={'text-sm sm:text-base font-medium text-primary'} to='/nos-formulaires' > Nos formulaires</Link></li>  
    <li onClick={props.handelClick} ><Link className='text-sm sm:text-base font-medium text-primary' to='/journaux-habilites'>Journaux habilités</Link></li>   
    <li className='relative' >
      <Menu>
        <Menu.Button className='text-sm sm:text-base font-medium text-primary flex items-baseline gap-3'  >Annuaires

          <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.347684 0.311086C0.570373 0.111898 0.872364 0 1.18725 0C1.50213 0 1.80412 0.111898 2.02681 0.311086L7.90493 5.57046L13.7831 0.311086C14.007 0.117543 14.307 0.0104483 14.6183 0.0128692C14.9297 0.01529 15.2275 0.127032 15.4477 0.324028C15.6679 0.521025 15.7928 0.787513 15.7955 1.0661C15.7982 1.34468 15.6785 1.61307 15.4622 1.81346L8.7445 7.82402C8.52181 8.02321 8.21982 8.13511 7.90493 8.13511C7.59005 8.13511 7.28806 8.02321 7.06537 7.82402L0.347684 1.81346C0.125062 1.61421 0 1.34401 0 1.06227C0 0.780536 0.125062 0.510334 0.347684 0.311086V0.311086Z" fill="#27295B" />
          </svg>
        </Menu.Button>
        <Menu.Items className="DropItems relative  top-2  text-left w-[250px]  focus:outline-none transform opacity-100 scale-100">
          <Menu.Item className="group flex justify-start w-full items-center rounded-md   py-2  text-sm sm:text-base font-medium text-primary" onClick={props.handelClick}>
            {({ active }) => (
              <Link
                className={`${active && 'bg-blue-500'}`}
                to="/greffes-de-france"  
               
              >
                Greffes de France
              </Link>
            )}
          </Menu.Item>
          <Menu.Item className="group flex w-full items-center justify-start rounded-md   py-2   text-sm sm:text-base font-medium text-primary" onClick={props.handelClick}>
            {({ active }) => (
              <Link
                className={`${active && 'bg-blue-500'}`}
                to="/experts-comptables"
              >
                Experts-comptables
              </Link>
            )}
          </Menu.Item>
          <Menu.Item className="group flex w-full items-center justify-start rounded-md   py-2   text-sm sm:text-base font-medium text-primary" onClick={props.handelClick} >
            {({ active }) => (
              <Link
                className={`${active && 'bg-blue-500'}`}
                to="/avocats"
              >
                Avocats
              </Link>
            )}
          </Menu.Item>
          <Menu.Item className="group flex w-full items-center justify-start rounded-md   py-2   text-sm sm:text-base font-medium text-primary" onClick={props.handelClick}>
            {({ active }) => (
              <Link
                className={`${active && 'bg-blue-500'}`}
                to="/notaires"
              >
                Notaires
              </Link>
            )}
          </Menu.Item>
          <Menu.Item className="group flex w-full items-center justify-start rounded-md   py-2   text-sm sm:text-base font-medium text-primary" onClick={props.handelClick}>
            {({ active }) => (
              <Link
                className={`${active && 'bg-blue-500'}`}
                to="/recherche-des-annoces-legales"
              >
                Recherche des annonces légales
              </Link>
            )}
          </Menu.Item>
         
        </Menu.Items>
      </Menu> 
    </li>
      
    <li>
      <Link to={process.env.REACT_APP_PRO_WEB_URL}  target="_blank"  >
      <button className='text-sm sm:text-base font-medium bg-primary flex  flex-col justify-center leading-[18px] items-center px-1 text-center whitespace-nowrap rounded-[10px] w-[249px] h-[59px]'>
        <h2 className='font-bold text-base sm:text-lg  text-white m-0'>Professionnels</h2>
        <p className='font-medium text-xs text-white '>Avocats, Experts-comptables, Notaires</p>

      </button>
      </Link>
    </li>

  </ul>
  </>
  )
}
