import { TextField } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../_actions";
import ToastMessageComp from "../component/ToastMessageComp";

import axios from "axios";
import logo from "../BusyLogo.svg";
import logoWebP from "../BusyLogo.webp";

export default function TwoFAVerify() {
  let navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    otp: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [successful, setSuccessful] = useState(false);
  function handleChange(e) {
    const { name, value } = e.target;
    setUser((user) => ({ ...user, [name]: value }));
  }
  async function handleSubmit(e) {
    e.preventDefault();
    setSuccessful(false);
    setSubmitted(true);
    if (user.otp !== "") {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/admin/verify-otp`,
        { id, ...user }
      );
      setSuccessful(true);
      if (res.data.status === 200) {
        dispatch(alertActions.success(res.data.message));
        localStorage.setItem("admin", JSON.stringify(res.data));
        setTimeout(() => {
          navigate("/admin");
        }, 1000);
      } else {
        dispatch(alertActions.error(res.data.message));
      }
    } else {
      setSuccessful(true);
      dispatch(alertActions.error("OTP est obligatoire"));
    }
  }
  return (
    <>
      {successful ? (
        <ToastMessageComp message={alert.message} type={alert.type} />
      ) : (
        ""
      )}

      <form className="" onSubmit={handleSubmit}>
        <div className="bg-[#F5F5F5] w-full h-screen py-7 3xl:py-12">
          <div className="md:max-w-[1477px] relative w-full h-full max-h-[684px] flex items-center justify-center m-auto  ">
            <div className="max-w-[640px] sm:max-w-[720px] w-full h-full bg-white px-5 sm:px-12 py-8 sm:py-5 xl:p-12 shadow-md rounded-[33px]">
              <div className="flex items-center px-2 justify-around gap-14 py-8 3xl:py-6">
                <picture>
                  <source type="image/webp" srcSet={logoWebP} />
                  <img
                    src={logo}
                    alt="busyplace_logo"
                    style={{
                      maxWidth: "100%",
                      width: "170px",
                      maxHeight: "auto",
                      height: "fit-content",
                    }}
                  />
                </picture>
              </div>
              <div className="flex items-center px-2 justify-around gap-14 mt-8 py-2 3xl:py-4">
                <h3 className="text-2xl sm:text-3xl 3xl:text-4xl font-semibold border-b-[6px] border-primary py-4 text-primary ">
                  vérifier OTP
                </h3>
              </div>

              <div className="my-8 block">
                <TextField
                  sx={{
                    "& .MuiInputLabel-root": { color: "#797979" },
                    "& fieldset": { border: "1px solid #eee" },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      color: "#797979",
                      background: "rgb(39 41 91 / 6%)",
                    },
                  }}
                  className="w-full"
                  label="OTP"
                  variant="outlined"
                  type="otp"
                  error={submitted && !user.otp ? true : false}
                  name="otp"
                  value={user.otp}
                  onChange={handleChange}
                />
              </div>
              <button
                type="submit"
                className="bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white  mt-4 sm:mt-4
                3xl:mt-10 px-6 w-full py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white"
              >
                vérifier OTP
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
