import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Home from "../pages/Home";
import Header from "../component/Header";
import NoPage from "../pages/NoPage";
import Login from "../pages/Login";
import AuthVerify from "../pages/AuthVerify";
import Registration from "../pages/Registration";
import Demo from "../pages/Demo";
import StepperForm from "../pages/Form/StepperForm";
import MentionsLegales from "../pages/MentionsLegales";
import ConditionsGeneralesVente from "../pages/ConditionsGeneralesVente";
import Faq from "../pages/Faq";
import GreffesDeFrance from "../pages/Directory/GreffesDeFrance";
import ExpertsComptables from "../pages/Directory/ExpertsComptables";
import AnnuaireDesAvocats from "../pages/Directory/AnnuaireDesAvocats";
import AnnuaireDesNotaires from "../pages/Directory/AnnuaireDesNotaires";
import JournauxHabilites from "../pages/Form/JournauxHabilites";
import Dashboard from "../Dashboard/Dashboard";
import AnnoncesPubliees from "../Dashboard/AnnoncesPubliees";
import AnnoncesPublieesDetails from "../Dashboard/AnnoncesPublieesDetails";
import AnnoncesEnAttente from "../Dashboard/AnnoncesEnAttente";
import Factures from "../Dashboard/Factures";
import AnnonceLegale from "../Dashboard/AnnonceLegale";
import DirectoryOfCourtOffices from "../pages/DirectoryOfCourtOffices";
import DirectoryOfChartered from "../pages/DirectoryOfChartered";
import DirectoryOfGreffe from "../pages/DirectoryOfGreffe";
import FicheExpertsComptable from "../pages/FicheExpertsComptable";
import GreffeDeFranceSélectionGreffe from "../pages/Form/GreffeDeFranceSélectionGreffe";
import FormulaireInscriptionPro from "../pages/Form/FormulaireInscriptionPro";
import ModifierLannonce from "../pages/Form/ModifierLannonce";
import Profile from "../Dashboard/Profile";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import InvoiceTemplate from "../component/pdf/InvoiceTemplate";
import AdminHeader from "../admin/component/Header";
import AdminDashboard from "../admin/Dashboard";
import Clients from "../admin/Clients";
import Claims from "../admin/Claims";
import Messages from "../admin/messages";
import Claimdetails from "../admin/claimdetails";
import Flaterate from "../admin/Flaterate";
import AdminProfile from "../admin/profile";
import Journal from "../admin/Journal";
import Invoice from "../admin/Invoice";
import Devis from "../admin/Devis";
import QuoteComp from "../admin/quote";
import Managerates from "../admin/Managerates";
import Clientdetails from "../admin/Clientdetails";
import JournalUpdate from "../admin/JournalUpdate";
import JournalAdd from "../admin/JournalAdd";
import AdminLogin from "../admin/Login";

import Protected from "./Protected";
import FicheAvocats from "../pages/Directory/FicheAvocats";
import AnnuaireDesAvocatSelection from "../pages/Directory/AnnuaireDesAvocatSelection";
import AnnuaireDesNotaireSelection from "../pages/Directory/AnnuaireDesNotaireSelection";
import FicheNotaires from "../pages/Directory/FicheNotaires";
import NosFormulairesMain from "../pages/NosFormulairesMain";
import FicheGreffe from "../pages/FicheGreffe";
import DirectoryOfAvocats from "../pages/DirectoryOfAvocats";
import DirectoryOfNotaires from "../pages/DirectoryOfNotaires";

import DirectorySearchResults from "../pages/DirectorySearchResults"
import RechercheDesAnnocesLegales from "../pages/RechercheDesAnnocesLegales"
import RechercheDesAnnocesLegalesDetails from "../pages/RechercheDesAnnocesLegalesDetails"
import NotFound from "../pro/NotFound";
import { decodedStringValue } from "../_helpers";
// import { ModellibreStepperForm } from "../pages/Form/stepperForms";
import axios from 'axios';
import { ModellibreForm } from "../admin/ModellibreForm";
import TwoFAVerify from "../admin/TwoFAVerify";


function WebRoutes() {
  const location = useLocation();
  const [currentLocation, setcurrentLocation] = useState(location.pathname);
  useEffect(() => {
   
    if (location.pathname.match(/\/{2,}/g)) {
      console.log(
        `location.pathname.replace(/\/{2,}/g, "/")`,
        location.pathname.replace(/\/{2,}/g, "/")
      );
      setcurrentLocation(location.pathname.replace(/\/{2,}/g, "/"), {
        replace: true,
      });
     
    }
  });

  useEffect(() => {
    if(currentLocation) {
      (async () => {        
          
        if(currentLocation.includes("directory_search_result")){
          // console.log("directory_search_result")
          let sendData = {
            "sitemapData":[{
              "loc": `${process.env.REACT_APP_WEB_URL+currentLocation}`,
              "lastmod": "2024-02-01T14:42:13+01:00",
              "priority": 0.8
            }],
            "filename":"sitemap_greffe.xml"
          }
          const response = await axios.post(`${process.env.REACT_APP_API_URL}api/add_update_sitemap`,sendData);
          // console.log("response",response)
         
        }
        if(currentLocation.includes("annuaire-des-experts-comptable-selection")){
          // console.log("annuaire-des-experts-comptable-selection")
          let sendData = {
            "sitemapData":[{
              "loc": `${process.env.REACT_APP_WEB_URL+currentLocation}`,
              "lastmod": "2024-02-01T14:42:13+01:00",
              "priority": 0.8
            }],
            "filename":"sitemap_experts_comptables_3.xml"
          }
          const response = await axios.post(`${process.env.REACT_APP_API_URL}api/add_update_sitemap`,sendData);
          // console.log("response",response)
         
        }
        if(currentLocation.includes("annuaire-des-avocats-selection")){
          // console.log("annuaire-des-avocats-selection")
          let sendData = {
            "sitemapData":[{
              "loc": `${process.env.REACT_APP_WEB_URL+currentLocation}`,
              "lastmod": "2024-02-01T14:42:13+01:00",
              "priority": 0.8
            }],
            "filename":"sitemap_avocats.xml"
          }
         const response = await axios.post(`${process.env.REACT_APP_API_URL}api/add_update_sitemap`,sendData);
          // console.log("response",response)
         
        }

        if(currentLocation.includes("annuaire-des-notaires-selection")){
          // console.log("annuaire-des-notaires-selection")
          let sendData = {
            "sitemapData":[{
              "loc": `${process.env.REACT_APP_WEB_URL+currentLocation}`,
              "lastmod": "2024-02-01T14:42:13+01:00",
              "priority": 0.8
            }],
            "filename":"sitemap_notaires.xml"
          }
          const response = await axios.post(`${process.env.REACT_APP_API_URL}api/add_update_sitemap`,sendData);
          // console.log("response",response)
         
        }
          /* const response_data = await axios.get(`${process.env.REACT_APP_API_URL}api/admin/getSitemapData/accountant/30246`);
          console.log("response_data",response_data)
          if(response_data.data.data.length>0){
            let sitemapData =[];
            await Promise.all(response_data.data.data.map(async(val,key)=>{
              sitemapData.push({
                "loc": `https://www.busyplace.fr/fiche-experts-comptables/${val.slug}/${val.accountant_id}`,
                "lastmod": "2024-02-01T14:42:13+01:00",
                "priority": 0.8
              })
            }))
            let sendData = {
              "sitemapData":sitemapData,
              "filename":"sitemap_experts_comptables_3.xml"
            }
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}api/add_update_sitemap`,sendData);
            // console.log("response",response)
          } */

          /* const response_data = await axios.get(`${process.env.REACT_APP_API_URL}api/admin/getSitemapData/avocats/17773`);
          console.log("response_data",response_data)
          if(response_data.data.data.length>0){
            let sitemapData =[];
            await Promise.all(response_data.data.data.map(async(val,key)=>{
              sitemapData.push({
                "loc": `https://www.busyplace.fr/fiche-avocats/${val.slug}/${val.avocats_id}`,
                "lastmod": "2024-02-01T14:42:13+01:00",
                "priority": 0.8
              })
            }))
            let sendData = {
              "sitemapData":sitemapData,
              "filename":"sitemap_avocats.xml"
            }
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}api/add_update_sitemap`,sendData);
            // console.log("response",response)
          } */

          /* const response_data = await axios.get(`${process.env.REACT_APP_API_URL}api/admin/getSitemapData/notaires/0`);
          console.log("response_data",response_data)
          if(response_data.data.data.length>0){
            let sitemapData =[];
            await Promise.all(response_data.data.data.map(async(val,key)=>{
              sitemapData.push({
                "loc": `https://www.busyplace.fr/fiche-notaires/${val.slug}/${val.notaires_id}`,
                "lastmod": "2024-02-01T14:42:13+01:00",
                "priority": 0.8
              })
            }))
            let sendData = {
              "sitemapData":sitemapData,
              "filename":"sitemap_notaires.xml"
            }
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}api/add_update_sitemap`,sendData);
            // console.log("response",response)
          }  */
        
      })();
    }
  },[currentLocation]);
  

  return (
    <Routes>
      <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<Navigate to='/' />} />
      <Route path="/pdfView" element={<InvoiceTemplate />} />
      <Route path="/" element={<Header />}>
        {/* <Route path="/home" element={<Home />} /> */}
        <Route index element={<Home />} />

        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/auth/verify/:email/:token" element={<AuthVerify />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/resetpassword/:email/:token"
          element={<ResetPassword />}
        />

        <Route path="/nos-formulaires" element={<NosFormulairesMain />} />
        <Route
          path="/nos-formulaires/:ads/:adsname"
          element={<StepperForm />}
        />
        <Route
          path="/nos-formulaires/:ads/:adsname/:id"
          element={<StepperForm />}
        />
        <Route path="/modifier-lannonce" element={<ModifierLannonce />} />

        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route
          path="/conditions-generales-vente"
          element={<ConditionsGeneralesVente />}
        />
        <Route path="/faq" element={<Faq />} />
       
        <Route path="/greffes-de-france" element={<GreffesDeFrance />} />
        <Route path="/experts-comptables" element={<ExpertsComptables />} />
        <Route path="/avocats" element={<AnnuaireDesAvocats />} />
        <Route path="/fiche-avocats/:name/:id" element={<FicheAvocats />} />
        {/* <Route
          path="/annuaire-des-avocats-selection"
          element={<AnnuaireDesAvocatSelection />}
        /> */}

        <Route path="/notaires" element={<AnnuaireDesNotaires />} />
        <Route path="/fiche-notaires/:name/:id" element={<FicheNotaires />} />
        {/* <Route
          path="/annuaire-des-notaires-selection"
          element={<AnnuaireDesNotaireSelection />}
        /> */}

        <Route path="/journaux-habilites" element={<JournauxHabilites />} />
        <Route
          path="/annuaire-des-greffes-de-france"
          element={<DirectoryOfCourtOffices />}
        />
        <Route
          path="/annuaire-des-experts-comptable-selection/:city/:postalCode"
          element={<DirectoryOfChartered />}
          exact 
        />
         <Route
          path="/annuaire-des-greffe-selection/:city/:postalCode"
          element={<DirectoryOfGreffe />}
        />
         <Route
          path="/annuaire-des-avocats-selection/:city/:postalCode"
          element={<DirectoryOfAvocats />}
        />
        <Route
          path="/annuaire-des-notaires-selection/:city/:postalCode"
          element={<DirectoryOfNotaires />}
        />
        <Route
          path="/fiche-experts-comptables/:name/:id"
          element={<FicheExpertsComptable />}
        />
        <Route
          path="/directory_search_result/:type/:searchKey/:size/:currentPage"
          element={<DirectorySearchResults />}
        />
        <Route
          path="/fiche-greffe/:id"
          element={<FicheGreffe />}
        />
        <Route
          path="/recherche-des-annoces-legales/:id"
          element={<RechercheDesAnnocesLegalesDetails />}
        />
        <Route
          path="/recherche-des-annoces-legales"
          element={<RechercheDesAnnocesLegales />}
        />
        <Route
          path="/greffe-de-France-selection-greffe"
          element={<GreffeDeFranceSélectionGreffe />}
        />

        <Route
          path="/formulaire-inscription-pro/:type/:name/:id"
          element={<FormulaireInscriptionPro />}
        />
        {/* <Route
          path="/formulaire-inscription-pro/:id"
          element={<FormulaireInscriptionPro />}
        />
 */}
        <Route path="/demo" element={<Demo />} />
        <Route path="*" element={<NoPage />} />

        {/* =========================|Admin Routes|============================ */}
        <Route
          path="/dashboard"
          element={
            <Protected>
              <Dashboard />
            </Protected>
          }
        />
        <Route
          path="/profile"
          element={
            <Protected>
              <Profile />
            </Protected>
          }
        />
        <Route
          path="/userdashboard/annonces-publiees"
          element={
            <Protected>
              <AnnoncesPubliees />
            </Protected>
          }
        />
        <Route
          path="/userdashboard/annonces-publiees-details/:id"
          element={
            <Protected>
              <AnnoncesPublieesDetails />
            </Protected>
          }
        />
        <Route
          path="/userdashboard/annonces-en-attente"
          element={
            <Protected>
              <AnnoncesEnAttente />
            </Protected>
          }
        />
        <Route
          path="/userdashboard/factures"
          element={
            <Protected>
              <Factures />
            </Protected>
          }
        />
        <Route
          path="/userdashboard/annonce-legale"
          element={
            <Protected>
              <AnnonceLegale />
            </Protected>
          }
        />
      </Route>
      <Route
        path="/admin/login"
        element={
          <Protected>
            <AdminLogin />
          </Protected>
        }
      />
      <Route
        path="/otp-vérifier/:id"
        element={
          <Protected>
            <TwoFAVerify />
          </Protected>
        }
      />
      <Route path="/" element={<AdminHeader />}>
     

        {/*  =========================|Admin Routes|============================ / */}

        <Route
          path="/admin"
          element={
            <Protected>
              <AdminDashboard />
            </Protected>
          }
        />
        <Route
          path="/admin/home"
          element={
            <Protected>
              <AdminDashboard />
            </Protected>
          }
        />
        <Route
          path="/admin/clients"
          element={
            <Protected>
              <Clients />
            </Protected>
          }
        />
        <Route
          path="/admin/messages"
          element={
            <Protected>
              <Messages />
            </Protected>
          }
        />
        <Route
          path="/admin/claims"
          element={
            <Protected>
              <Claims />
            </Protected>
          }
        />
         <Route
          path="/admin/claimdetails/:type/:id"
          element={
            <Protected>
              <Claimdetails />
            </Protected>
          }
        />

        <Route
          path="/admin/flaterate"
          element={
            <Protected>
              <Flaterate />
            </Protected>
          }
        />
        <Route
          path="/admin/profile"
          element={
            <Protected>
              <AdminProfile />
            </Protected>
          }
        />
        <Route
          path="/admin/journal"
          element={
            <Protected>
              <Journal />
            </Protected>
          }
        />

        <Route
          path="/admin/invoice"
          element={
            <Protected>
              <Invoice />
            </Protected>
          }
        />

        <Route
          path="/admin/devis"
          element={
            <Protected>
              <Devis />
            </Protected>
          }
        />
        <Route
          path="/admin/quote"
          element={
            <Protected>
              <QuoteComp />
            </Protected>
          }
        />

        <Route
          path="/admin/managerates"
          element={
            <Protected>
              <Managerates />
            </Protected>
          }
        />

        <Route
          path="/admin/clientdetails/:user_id"
          element={
            <Protected>
              <Clientdetails />
            </Protected>
          }
        />

        <Route
          path="/admin/modelelibre/:user_id/:annonce_id"
          element={
          <Protected>
            <ModellibreForm />
          </Protected>
          }
        />

        <Route
          path="/admin/journal_update/:id"
          element={
            <Protected>
              <JournalUpdate />
            </Protected>
          }
        />

        <Route
          path="/admin/journal_add"
          element={
            <Protected>
              <JournalAdd />
            </Protected>
          }
        />
      </Route>


    </Routes>
  );
}



export default WebRoutes;
