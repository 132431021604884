import React, { useRef } from 'react'
import NosFormulaires from '../pages/HomePage/NosFormulaires'
import CommentPublier from '../pages/HomePage/CommentPublier'
import LesFormulaires from '../pages/HomePage/LesFormulaires'
import Actualites from '../pages/HomePage/Actualites'
import Footer from '../component/Footer'
import Hero from '../pages/HomePage/Hero'
import ToutSavoir from '../pages/HomePage/ToutSavoir'
import { Helmet } from 'react-helmet';
export default function Home() {
  const lesFormulairesRef = useRef(null); // Create a ref

  const scrollToLesFormulaires = () => {
    lesFormulairesRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  return (
    <>
      <Helmet>
        <title>Annonces légales instantanées pas cher partout en France</title>
        <meta name="description" content="Annonce légale au prix le plus juste ; publiez toutes vos annonces légales simplement et au meilleur prix. Publication immédiate apres paiement sur tous les départements de France et DOM TOM." />
        {/* <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/`} /> */}
      </Helmet>
      <div className='w-full h-full bg-white '>
        {/* <Hero /> */}
        <Hero scrollToLesFormulaires={scrollToLesFormulaires} />
        <NosFormulaires />
        <CommentPublier />
        <ToutSavoir />
        {/* <LesFormulaires /> */}
        <div ref={lesFormulairesRef}><LesFormulaires /></div> {/* Assign the ref to this div */}
        <Actualites />
        <Footer />
      </div>

    </>
  )
}
