import React, { useEffect } from 'react'
 
import { BsFillQuestionCircleFill, BsPlusCircle } from 'react-icons/bs'
import { MdEuroSymbol } from 'react-icons/md'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr.js';
import { frFR } from '@mui/x-date-pickers/locales';
import Tooltip from '@mui/material/Tooltip';
import { FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import Procedures from './FormComponent/Procedures';
import Express from './FormComponent/Express';
import PaymentSideBlock from './FormComponent/PaymentSideBlock';
import Breadcrumb from '../../component/Breadcrumb';

export default function ModifierLannonce() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const StyledTooltip = ({ title, children, ...props }) => (
    <Tooltip
      {...props}
      title={title}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            background: '#DEE3E2',
            width: '129px',
            padding: '10px',
            color: '#27295B',
            fontSize: '14px',
            fontWeight: '400'
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
  return (
    <>

      <div className='bg-mainBg'>
        <div className='md:max-w-[1477px] relative w-full h-full m-auto py-7 px-4 sm:px-14 myForm'>
          <Breadcrumb Accueil="Accueil" page="Constitution d’une société commerciale" />

          <div className='relative   bg-mainBg my-4 sm:my-12 '>
            <div className='grid grid-cols-12 gap-5  '>
              <div className='mb-8 col-span-12 2lg:col-span-8'>
                <div className='bg-white max-w-[974px] rounded-xl  ' style={{ border: '0.5px solid #DEE3E2' }}>
                  <div className='py-6 px-3 sm:px-6' style={{ borderBottom: '0.5px solid #DEE3E2' }}>
                    <h1 className='text-lg sm:text-2xl font-black text-primary'>Modification annonce légale : Création de SARL</h1>
                  </div>
                  <div className='py-3 sm:py-6 px-3 sm:px-6'>
                    <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Les statuts</h2>

                    <div className='grid grid-cols-12 items-center mt-2 sm:mt-6'>
                      <label className='col-span-12 sm:col-span-3 my-2 sm:mb-0 text-base font-normal text-black-light'>Type d’acte</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7'>
                        <FormControl size="small" className='w-full'>
                          <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Sélectionnez"
                          >
                            <MenuItem value={10}>Acte sous seing privé (acte rédigé par les parties concernées)</MenuItem>
                            <MenuItem value={20}>Acte authentique (acte rédigé par un notaire)</MenuItem>

                          </Select>
                        </FormControl>
                      </div>
                    </div>


                    <div className='grid grid-cols-12 items-center mt-6'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Date de l’acte</label>
                      <div className='col-span-12 sm:col-span-9 mr-7 md:mr-0 md:col-span-7 lg:col-span-4'>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText} >
                          <DemoContainer components={['DatePicker']}  >
                            <DatePicker slotProps={{ textField: { size: 'small' } }}  format={"DD/MM/YYYY"}  />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>


                    </div>
                  </div>


                  <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
                    <h2 className='text-lg sm:text-[22px] font-bold text-primary'>La société</h2>

                    <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full outline-secondary focus:outline-secondary'
                        />
                        <StyledTooltip title="Indiquez le nom de la Société tel que mentionné dans les statuts.">
                          <div className='p-0 m-0 w-auto'>
                            <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                          </div>
                        </StyledTooltip>
                      </div>
                    </div>

                    <div className='grid grid-cols-12 items-center mt-6'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Sigle</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                        />
                        <StyledTooltip title="Sigle à renseigner uniquement si indiqué dans les statuts.">
                          <div className='p-0 m-0 w-auto'>
                            <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                          </div>
                        </StyledTooltip>
                      </div>
                    </div>

                    <div className='grid grid-cols-12 items-center mt-6'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom commercial</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                        />
                        <StyledTooltip title="Nom sous lequel l'activité de votre société sera connue du public.">
                          <div className='p-0 m-0 w-auto'>
                            <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                          </div>
                        </StyledTooltip>
                      </div>
                    </div>

                    <div className='grid grid-cols-12 items-center mt-6'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-8'>
                        <FormControl size="small" className='w-full'>
                          <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Sélectionnez"
                          >
                            <MenuItem value={10}>Société à responsabilité limitée (SARL)</MenuItem>
                            <MenuItem value={20}>Société à responsabilité limitée à capital variable</MenuItem>
                            <MenuItem value={20}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                            <MenuItem value={20}>Société à responsabilité limitée à associé unique à capital variable</MenuItem>

                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className='grid grid-cols-12 items-start mt-6'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Objet</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
                        <TextField

                          hiddenLabel
                          multiline
                          minRows={4}
                          className='w-full'
                        />
                        <StyledTooltip title="Nom sous lequel l'activité de votre société sera connue du public.">
                          <div className='p-0 m-0 w-auto'>
                            <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                          </div>
                        </StyledTooltip>
                      </div>
                    </div>

                    <div className='grid grid-cols-12 items-center mt-6'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>

                        <FormControl size="small" className='w-full'>
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                          />
                        </FormControl>

                        <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                          <div className='p-0 m-0 w-auto'>
                            <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                          </div>
                        </StyledTooltip>

                      </div>
                    </div>
                    <div className='grid grid-cols-12 items-center mt-6'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Durée</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>

                        <FormControl size="small" className='w-full'>
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={<InputAdornment position="end" className='font-medium text-primary'><span className='font-medium text-primary'> ans</span></InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                          />
                        </FormControl>

                        <StyledTooltip title="La durée maximale est de 99 ans. Elle pourra être prorogée une ou plusieurs fois.">
                          <div className='p-0 m-0 w-auto'>
                            <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                          </div>
                        </StyledTooltip>

                      </div>
                    </div>


                  </div>

                  <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
                    <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Adresse du siège social</h2>

                    <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                        />
                      </div>
                    </div>

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                        />
                      </div>
                    </div>

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                        />
                      </div>
                    </div>

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
                    <h2 className='text-lg sm:text-[22px] font-bold text-primary'>La gérance</h2>

                    <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-5 md:mr-10 inline-flex items-center gap-2'>
                        <FormControl size="small" className='w-full'>
                          <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Sélectionnez"
                          >
                            <MenuItem value={10}>Personne physique</MenuItem>
                            <MenuItem value={20}>Personne morale</MenuItem>

                          </Select>
                        </FormControl>
                        <StyledTooltip title="La durée maximale est de 99 ans. Elle pourra être prorogée une ou plusieurs fois.">
                          <div className='p-0 m-0 w-auto'>
                            <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                          </div>
                        </StyledTooltip>
                      </div>
                    </div>
                    <div className='grid grid-cols-12 items-start mt-6'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                        <FormControl size="small" className='w-full'>
                          <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Sélectionnez"
                          >
                            <MenuItem value={10}>Madame</MenuItem>
                            <MenuItem value={20}>Monsieur</MenuItem>

                          </Select>
                        </FormControl>

                      </div>
                    </div>

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                        />
                      </div>
                    </div>

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                        />
                      </div>
                    </div>

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                        />
                      </div>
                    </div>

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                        />
                      </div>
                    </div>

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Résidant à l’étranger</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <FormControl size="small" className='w-full'>
                          <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Sélectionnez"
                          >
                            <MenuItem value={10}>Non</MenuItem>
                            <MenuItem value={20}>Oui</MenuItem>

                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Pays</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <FormControl size="small" className='w-full'>
                          <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Sélectionnez"
                          >
                            <MenuItem value={10}>France</MenuItem>
                            <MenuItem value={20}>Afrique du Sud</MenuItem>
                            <MenuItem value={30}>Albanie</MenuItem>

                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                        />
                      </div>
                    </div>

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='px-5 group flex items-center gap-2 text-primary hover:text-secondary mt-12 cursor-pointer '>
                    <BsPlusCircle className='text-3xl ' />
                    <span className='text-base font-normal'>Ajouter un gérant</span>
                  </div>

                  <div className='mt-8 sm:mt-6 '>
                    <div className='sm:py-6 px-3 sm:px-6' >
                      <h1 className='text-[22px] font-bold text-primary pb-4'>Informations personnelles</h1>
                      <p className='text-[15px] font-semibold text-primary'>Pourquoi renseigner un numéro de téléphone et un e-mail valide ?</p>
                      <p className='text-[15px] font-normal text-primary'>Une adresse e-mail valide est indispensable pour vous envoyer votre attestation au format numérique et pour le suivi de votre dossier. Nos formalistes effectuent une relecture de vos informations et vous contacte par téléphone en cas d’erreur.</p>
                      <p className='text-[15px] font-semibold text-primary'>Nous ne divulguerons jamais vos informations.</p>
                    </div>

                    <div className='py-0 sm:pt-3 sm:pb-8 px-3 sm:px-6  '>
                      <div className='grid grid-cols-12 items-center  '>
                        <label className='col-span-12 sm:col-span-2 text-base font-normal text-gray'>Email</label>
                        <div className='col-span-12 sm:col-span-7 inline-flex items-center gap-2'>
                          <TextField
                            placeholder='j.delpierre@gmail.com'

                            size="small"
                            className='w-full outline-secondary focus:outline-secondary'
                          />
                        </div>
                      </div>
                      <div className='grid grid-cols-12 items-center mt-6'>
                        <label className='col-span-12 sm:col-span-2 text-base font-normal text-gray'>Téléphone</label>
                        <div className='col-span-12 sm:col-span-7 inline-flex items-center gap-2'>
                          <TextField
                            placeholder='0155823256'
                            id="outlined-size-small"
                            size="small"
                            className='w-full outline-secondary focus:outline-secondary'
                          />
                        </div>
                      </div>

                    </div>

                  </div>



                </div>
                <div className='py-5 flex gap-12 justify-end w-full'>
                  <button className='text-[20px] font-medium text-gray'>Annuler</button>
                   
                  <button className=' px-5 h-12 rounded-md bg-thColor font-extrabold text-lg 3xl:text-xl text-white transition-all duration-300 hover:bg-secondary hover:btnShadow'>Enregistrer les modifications</button>
                </div>
              </div>
              <div className=' col-span-12 2lg:col-span-4  '>
                <Procedures />
                <Express />
                <PaymentSideBlock />
              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  )
}
