import { Navigate } from "react-router-dom";
import { authHeader } from "../_helpers";
import axios from "axios";

export const adminService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    addAddress,
    forgot_password,
    createJournal,
    updateJournal,
    delete: _delete
};

async function login(userdata) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username:userdata.username,password: userdata.password })
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/admin/login`, requestOptions);
    const adminData = await handleResponse(response);
    return adminData;
    
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('admin');
    localStorage.removeItem('announceCurrentUrl');
}

async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`/users`, requestOptions);
    return handleResponse(response);
}

async function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`/users/${id}`, requestOptions);
    return handleResponse(response);
}

async function addAddress(formData) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/user_address`, requestOptions);    
    const get_user_address = await handleResponse(response);
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    if(get_user_address.sucess === true){
        let userData = localStorage.getItem('user');
        let userDataParse = JSON.parse(userData);
        userDataParse.user_address = get_user_address.user_address;
        localStorage.setItem('user', JSON.stringify(userDataParse));
    }
   
    return get_user_address;
}
async function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/register`, requestOptions);
    
    if(user.signup_type === "google" || user.signup_type === "facebook"  ){
        const user = await handleResponse(response);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if(user.sucess === true){
            localStorage.setItem('user', JSON.stringify(user));

        }
        return user;
    }else{
        return handleResponse(response);
      
    }
    
}

async function update(admin) {
    /* let formData = new FormData();
    
    Object.keys(admin).forEach(fieldName => {
        
        formData.append(fieldName, admin[fieldName]);
    }); */
    
    const requestOptions = {
        method:"PUT",
        headers:authHeader(),      
        body:JSON.stringify(admin)
    };
    
    const response = await fetch(process.env.REACT_APP_API_URL+`api/admin/update/${admin.id}`, requestOptions);    
    const user_data = await handleResponse(response);
    
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    if(localStorage.getItem('admin')){
        let getData = JSON.parse(localStorage.getItem('admin'));
        getData.data = user_data.data;
        localStorage.setItem('admin', JSON.stringify(getData));
    }else{
        localStorage.setItem('admin', JSON.stringify(user_data));
    }
    return user_data;
}
async function createJournal(journalData) {
    delete journalData["id"];
    let formData = new FormData();
    
    Object.keys(journalData).forEach(fieldName => {
        
        formData.append(fieldName, journalData[fieldName]);
    });
   /*  const requestOptions = {
        method:"POST",
        headers:authHeader(),      
        body:formData
    }; */
    
    // const response = await fetch(process.env.REACT_APP_API_URL+`api/admin/journal`, requestOptions);    
    const response = await axios.post(process.env.REACT_APP_API_URL+`api/admin/journal`, formData,authHeader());
       
    // const journal_data = await handleResponse(response);
    
    return response.data;
}
async function updateJournal(journalData) {
    let formData = new FormData();
    
    Object.keys(journalData).forEach(fieldName => {
        
        formData.append(fieldName, journalData[fieldName]);
    });
   
    /* const requestOptions = {
        method:"PUT",
        headers:authHeader(),      
        body:formData
    }; */
    
    /* const response = await fetch(process.env.REACT_APP_API_URL+`api/admin/journal/${journalData.id}`, requestOptions);    
    const journalDataGet = await handleResponse(response); */

    const response = await axios.put(process.env.REACT_APP_API_URL+`api/admin/journal/${journalData.id}`, formData ,authHeader());
    
    return response.data;
}
// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    const response = await fetch(`/users/${id}`, requestOptions);
    return handleResponse(response);
}

async function forgot_password(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/forgotpassword`, requestOptions);
    const user = await handleResponse(response);    
    return user;
}

function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // eslint-disable-next-line no-restricted-globals
                location.reload(true);
            }

            const error = (data && data.msg) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}