/* eslint-disable no-undef */
import React,{useState,useCallback} from 'react';
import { Link,useNavigate,useLocation } from 'react-router-dom'
import { LoginSocialGoogle, LoginSocialFacebook} from 'reactjs-social-login'
import Facebook01 from '../assets/Social/Facebook01.svg'
import ToastMessageComp from '../component/ToastMessageComp';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions,userActions } from '../_actions';

export default function SocialLoginComp(props) {   
    let navigate = useNavigate(); 
    const REDIRECT_URI = process.env.REACT_APP_FB_REDIRECT;
    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null);
    const [successful, setSuccessful] = useState(false);
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch(); 

    const [user, setUser] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        adresse: '',
        postalcode: '',
        ville: '',
        signup_type: '',
    });

    const onLoginStart = useCallback(() => {
        // // console.log("login start");
    }, [])

    const onLogoutSuccess = useCallback(() => {
        setProfile(null)
        setProvider('')
        // // console.log('logout success')
    }, [])
   
    const onLoginSuccess = useCallback((provider,data) => {
        setSuccessful(false);   
        if(provider === "facebook"){
            var userData = {
                firstname: data?.first_name,
                lastname: data?.last_name,
                email: data?.email,
                profile_image: data?.picture.data.url,
                token: data?.accessToken,
                adresse: '',
                postalcode: '',
                ville: '',
                signup_type: provider,
            }
        }else{
            var userData = {
                firstname: data?.given_name,
                lastname: data?.family_name,
                email: data?.email,
                profile_image: data?.picture,
                token: data?.access_token,
                adresse: '',
                postalcode: '',
                ville: '',
                signup_type: provider,
            }
        }
        
        dispatch(userActions.register(userData)).then((response) => {
            
            setSuccessful(true);   
            if(response.sucess === true){
                dispatch(alertActions.success(response.msg));
                // navigate("/login");  
                setTimeout(() => {           
                  navigate(-1)          
                }, 1000);
            }else{
                dispatch(alertActions.error(response.msg));
                // navigate(-1)       
            }             
          
        })
        .catch(() => {
            setSuccessful(true);         
        });
        // setProvider(provider)
        // setProfile(data)
    }, [])
    


  return (
    <>
    {successful?
      <ToastMessageComp  message={alert.message} type={alert.type} />
    :""}
    <LoginSocialFacebook
    redirect_uri={REDIRECT_URI}
    fields="id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
    scope="email,public_profile"
    appId={process.env.REACT_APP_FB_APP_ID || ''}
    onLoginStart={onLoginStart}
    onResolve={({ provider, data }) => {
      /*  // console.log("provider",provider);
       // console.log("data",data); */
        setProvider(provider)
        setProfile(data)
        onLoginSuccess(provider,data);
    }}
    onReject={(err) => {
        // // console.log(err)
    }}
    >
    <span  className='w-[53px] h-[53px] rounded-full flex items-center justify-center' style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}><img src={Facebook01}  alt="facebook" style={{ width: '38px', height: '38px',cursor:"pointer" }} /></span>
    
    </LoginSocialFacebook>
    <LoginSocialGoogle
        // redirect_uri={REDIRECT_URI}
        scope="openid profile email"
        // access_type="offline"
        client_id={process.env.REACT_APP_GG_APP_ID}
        onLoginStart={onLoginStart}
        onResolve={({ provider, data }) => {
            onLoginSuccess(provider,data);
           
        }}
        onReject={(err) => {
            // // console.log(err)
        }}
    >
    <span className='w-[53px] h-[53px] rounded-full flex items-center justify-center' style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" ,cursor:"pointer"}} ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="51" height="51"><path fill="#4285F4" d="M23.6 14.23c0-.71-.06-1.4-.18-2.05H14v3.87h5.38a4.6 4.6 0 01-2 3.02v2.5h3.24c1.89-1.73 2.98-4.3 2.98-7.34z"></path><path fill="#34A853" d="M14 24c2.7 0 4.96-.9 6.62-2.42l-3.23-2.51c-.9.6-2.04.95-3.39.95-2.6 0-4.8-1.76-5.6-4.12H5.06v2.6A10 10 0 0014 24z"></path><path fill="#FBBC05" d="M8.4 15.9a6.01 6.01 0 010-3.8V9.5H5.06a10 10 0 000 9l3.34-2.6z"></path><path fill="#EA4335" d="M14 7.98c1.47 0 2.79.5 3.82 1.5L20.7 6.6A10 10 0 005.06 9.5l3.34 2.6c.8-2.36 3-4.12 5.6-4.12z"></path></svg></span> 
    </LoginSocialGoogle>
    
    </>
  )
}
