import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Sidebar from "./component/Sidebar";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import { Breadcrumbs, Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { alertActions } from "../_actions";
import ToastMessageComp from "../component/ToastMessageComp";
import PageLoaderComp from "../component/pageLoaderComp";

const breadcrumbs = [
  <Link underline="hover" key="1" color="inherit" href="/">
    Tableau de bord
  </Link>,

  <Typography key="3">Journal habilité</Typography>,
];

function createData(
  JournaLogo,
  JournalName,
  DptID,
  Department,
  ADRESSEEMAIL,
  Telephone,
  Actions
) {
  return {
    JournaLogo,
    JournalName,
    DptID,
    Department,
    ADRESSEEMAIL,
    Telephone,
    Actions,
  };
}

const rows = [
  createData(
    "https://www.annonces.liti.fr/images/journal/1573471726.jpg",
    `Voix de l'Ain`,
    "01",
    "Ain",
    "rizwan.itpro@gmail.com",
    "0184791680",
    "Friday 13 January 2023",
    "action"
  ),
  createData(
    "https://www.annonces.liti.fr/images/journal/1573538999.jpg",
    `manash`,
    "02",
    "Asine",
    "manash@gmail.com",
    "5897112820",
    "Friday 13 January 2023",
    "action"
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#fff",
      color: "#797979",
      fontSize: "16px",
      fontWeight: "400",
    },
  }));

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "normal" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  // console.log("props",props)
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%", marginTop: "15px" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            inputProps={{ "aria-label": "search " }}
            value={props.searchQuery}
            onChange={(e) => props.setSearchQuery(e.target.value)}
          />

          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Typography>

      <Tooltip title="Add New">
        <Button
          component={Link}
          to="/admin/journal_add"
          variant="outlined"
          color="primary"
          style={{textWrap: 'nowrap'}}
        >
          Add New
        </Button>
      </Tooltip>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function JournalComp() {
  const currentAdmin = useSelector((state) => state.administrator.admin);
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [ConfirmValue, setConfirmValue] = useState("");
  const [onError, setOnError] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [headCells, setHeadCells] = useState([
    {
      id: "JournaLogo",
      numeric: false,
      disablePadding: true,
      label: "Journal logo",
    },
    {
      id: "JournalName",
      numeric: true,
      disablePadding: false,
      label: "Journal name",
    },
    {
      id: "DptID ",
      numeric: true,
      disablePadding: false,
      label: "Dpt ID",
    },
    {
      id: "Department",
      numeric: true,
      disablePadding: false,
      label: "Department",
    },
    {
      id: "ADRESSEEMAIL",
      numeric: true,
      disablePadding: false,
      label: "ADRESSE EMAIL",
    },
    {
      id: "Telephone",
      numeric: true,
      disablePadding: false,
      label: "TÉLÉPHONE",
    },
    {
      id: "Actions",
      numeric: true,
      disablePadding: false,
      label: "Actions",
    },
  ]);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  // const [visibleRows, setvisibleRows] = useState([]);

  // Filter rows based on searchQuery
  const filteredRows = useMemo(() => {
    return rows.filter((row) => {
      return (
        row?.department?.department_num
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        row?.department?.department_name
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        row?.journal_empowered_name
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
      // Customize this condition based on your search requirements
      /*  return Object.values(row).some(value =>{
        console.log("value",value)
        return value?value.toString().toLowerCase().includes(searchQuery.toLowerCase()):value
        }
        
      ); */
    });
  }, [rows, searchQuery]);

  const visibleRows = useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredRows]
  );

  useEffect(() => {
    getAllJournal();
  }, []);

  const handleClose = (newValue) => {
    setConfirmOpen(false);

    if (newValue) {
      setConfirmValue(newValue);
    }
  };

  async function getAllJournal() {
    setPageLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/admin/journal`
      );

      setRows(response.data.data);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.error(error);
    }
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const options = ["Afficher", "Supprimer", "Active"];
  const ActionOnclickOpen = async (event, row, type) => {
    if (type === "Afficher") {
      navigate(`/admin/journal_update/${row.id}`);
    }
    if (type === "Supprimer") {
      setConfirmOpen(true);
      setDeleteId(row.id);
    }
    if (type === "Active") {
      let postStatus = 1;
      if (row.status === "1") {
        postStatus = 0;
      }

      let responseupdateStatus = await axios.get(
        `${process.env.REACT_APP_API_URL}api/admin/journalstatus/${row.id}/${postStatus}`
      );

      setRows(responseupdateStatus.data.data);
    }
  };

  const ActionHandleClicks = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const ActionhandleClose = () => {
    setAnchorEl(null);
  };
  if (!currentAdmin) {
    return <Navigate to="/admin/login" />;
  }
  return (
    <>
      {successful ? (
        <ToastMessageComp message={alert.message} type={alert.type} />
      ) : (
        ""
      )}
      {pageLoading ? <PageLoaderComp /> : ""}

      <div className=" w-full h-full bg-white p-8">
        <Typography
          variant="h4"
          component="h4"
          fontWeight={500}
          color={"#1CBE93"}
        >
          Journal habilité
        </Typography>
        <Stack spacing={2} mb={3}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
            />
            <TableContainer sx={{ marginTop: "15px" }}>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {visibleRows.map((row, indexData) => {
                    var row_data = row;
                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick(event, row.journal_empowered_name)
                        }
                        tabIndex={-1}
                        key={row.name}
                        sx={{ cursor: "pointer" }}
                      >
                        {/* src={onError?process.env.REACT_APP_WEB_URL+`/no_image.png`:process.env.REACT_APP_API_URL+`/images/journal/${row.image}`} */}
                        <TableCell
                          className="text-base font-normal text-primary "
                          align="left"
                        >
                          <img
                            src={`${row.image}`} 
                            onError={(e) => setOnError(true)}
                            alt={row.image}
                            style={{ maxWidth: "100px" }}
                          />
                        </TableCell>
                        <TableCell
                          className="text-base font-normal text-primary"
                          align="left"
                        >
                          {row.journal_empowered_name}
                        </TableCell>
                        <TableCell
                          className="text-base font-normal text-primary "
                          align="left"
                        >
                          {row.department ? row.department.department_num : ""}
                        </TableCell>
                        <TableCell
                          className="text-base font-normal text-primary "
                          align="left"
                        >
                          {row.department ? row.department.department_name : ""}
                        </TableCell>
                        <TableCell
                          className="text-base font-normal text-primary"
                          align="left"
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          className="text-base font-normal text-primary "
                          align="left"
                        >
                          {row.telephone}
                        </TableCell>
                        <TableCell align="center">
                          {/* <button data_id={row_data.id} key={"Afficher"} selected={"Afficher" === 'Pyxis'} onClick={(e)=>{ActionOnclickOpen(e,row_data,"Afficher");ActionhandleClose(e);}} >
                                    Afficher
                                </button> */}
                          <Stack direction="row" spacing={2}>
                            <EditIcon
                              color="primary"
                              onClick={(e) => {
                                ActionOnclickOpen(e, row_data, "Afficher");
                                ActionhandleClose(e);
                              }}
                            />
                            <DeleteIcon
                              color="error"
                              onClick={(e) => {
                                ActionOnclickOpen(e, row_data, "Supprimer");
                                ActionhandleClose(e);
                              }}
                            />

                            <Button
                              variant="contained"
                              size="small"
                              color={
                                row_data.status === "1" || row_data.status === 1
                                  ? "error"
                                  : "success"
                              }
                              onClick={(e) => {
                                ActionOnclickOpen(e, row_data, "Active");
                                ActionhandleClose(e);
                              }}
                            >
                              {row_data.status === "1" || row_data.status === 1
                                ? "Deactive"
                                : "Active"}
                            </Button>
                          </Stack>
                          <div>
                            {/*  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={ActionHandleClicks}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                      'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={ActionhandleClose}                                  
                                  >
                                    {console.log("row_data",row_data)   }

                                    {options.map((option) => {   
                                                                      
                                        return (<MenuItem data_id={row_data.id} key={option} selected={option === 'Pyxis'} onClick={(e)=>{ActionOnclickOpen(e,row_data,option);ActionhandleClose(e);}} >
                                        {option === "Active"?(row_data.status==="1"|| row_data.status===1 ? "Deactive":"Active") :option}
                                      </MenuItem>)
                                      }
                                    )}
                                  </Menu> */}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>

      <ConfirmationDialogRaw
        id="ringtone-menu"
        keepMounted
        open={ConfirmOpen}
        onClose={handleClose}
        value={ConfirmValue}
        setRows={setRows}
        deleteId={deleteId}
        setSuccessful={setSuccessful}
      />
    </>
  );
}

function ConfirmationDialogRaw(props) {
  const dispatch = useDispatch();
  const {
    onClose,
    value: valueProp,
    open,
    setRows,
    deleteId,
    setSuccessful,
    ...other
  } = props;
  const [value, setValue] = React.useState(valueProp);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
    setValue("no");
  };

  const handleOk = async () => {
    onClose(value);
    setValue("yes");

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/admin/journal/${deleteId}`
      );

      if (response.data.sucess === true) {
        dispatch(alertActions.success(response.data.msg));
        setRows(response.data.data);
      } else {
        dispatch(alertActions.error(response.data.msg));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      // TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Are you sure ?</DialogTitle>
      <DialogContent dividers>
        <p>Are you sure want to delete journal?</p>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          No
        </Button>
        <Button onClick={handleOk}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
}
