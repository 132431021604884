import React from "react";
import { Navigate } from "react-router-dom";

function Protected({ children }) {
  const checkAuth = true;

  return checkAuth === true ? children : <Navigate to="/login" replace />;
}

export default Protected;
