import React, { useEffect } from 'react'
import Breadcrumb from '../component/Breadcrumb'
import Footer from '../component/Footer'
import { Link } from 'react-router-dom'
import { TbPointFilled } from 'react-icons/tb'
import VousImages from '../assets/VousImages.png'
import { Helmet } from 'react-helmet';
export default function ConditionsGeneralesVente() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
     <Helmet>
        <title>Conditions générales de BusyPlace</title>
        <meta name="description" content="Controlemaboite.fr est une plateforme de service en ligne, ayant pour but d’informer les utilisateurs quant aux données financières des entreprises, ainsi que de leurs effectifs." />
        {/* <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/conditions-generales-vente`} /> */}
      </Helmet>
      <div className='w-full h-full bg-mainBg '>
        <div className='md:max-w-[1477px] relative w-full h-full m-auto py-6 md:py-14 px-4 sm:px-14'>
          <div className='flex items-center justify-start gap-4 '>
            <Breadcrumb Accueil="Accueil" page="Mentions légales" />
          </div>
          <div className='max-w-[1277px] m-auto mt-8 md:mt-16 block'>
            <h1 className='text-xl sm:text-2xl md:text-3xl font-black text-primary'>Conditions générales d'utilisations</h1>
          </div>
        </div>
      </div>
      <div className='md:max-w-[1477px] relative w-full h-full m-auto px-4 sm:px-14'>
        <div className='max-w-[1277px] m-auto block bg-white py-6 md:py-12'>
          {/* <p className='text-base font-normal text-gray '><Link to="/" className="hoevr:text-secondary font-bold">annonces@busyplace.fr</Link> est une plateforme de services en ligne d’aide à la création, à la gestion, à la communication, la commercialisation et à la recherche de financement pour les entreprises.</p> */}

          {/* <p className='text-base font-bold text-gray mt-6'>Editée par la société LEGALS</p> */}
          {/* <p className='text-base font-bold text-gray'>SARL au capital de 1000 €</p> */}
          {/* <p className='text-base font-bold text-gray'>23 rue Jean Baptiste Delambre</p> */}
          {/* <p className='text-base font-bold text-gray'>77127 LIEUSAINT</p> */}
          {/* <p className='text-base font-bold text-gray'>RCS MELUN 838 903 755</p> */}

          <p className='text-base font-normal text-gray mt-6'>busyplace.fr est une plateforme de services en ligne de publication d'annonces légales et judiciaires, d’aide à la création, et de soutien aux formalités pour les entreprises.
            Editée par <span className='text-base font-bold text-primary'>NEXTGEN - BUSYPLACE 128 rue de la Boétie 75008 PARIS </span> RCS PARIS 922 622 832
            Les conditions générales de vente détaillent les clauses et les conditions qui administrent l’utilisation du service en ligne proposé par la société NEXTGEN à partir de son site internet busyplace.fr
          </p>


          <p className='text-base font-normal text-gray mt-6'>Toute souscription auprès de NEXTGEN par l’intermédiaire de son site internet busyplace.fr d’un abonnement ou d’un service proposé sur sa plateforme numérique implique pour le souscripteur ou pour son mandataire l’acceptation sans aucune réserve des conditions générales de vente suivantes.</p>

          <p className='text-base font-normal text-gray mt-6'>La société NEXTGEN se réserve le droit de modifier à tout moment et sans préavis les présentes conditions générales de vente.</p>

          <p className='text-base font-normal text-gray mt-6'>NEXTGEN - BUSYPLACE n’est ni un cabinet d’avocat ni un cabinet d’expertise comptable et ne fournit en aucun cas de conseil juridique. Conformément aux réglementations applicables, et particulièrement aux dispositions de la loi Loi n°71-1130 du 31 décembre 1971 modifiée, sa direction et ses salariés n’exercent pas d’activité de consultation en matière juridique, de rédaction d’acte sous seing privé ou de représentation juridique. Les documents générés par la plateforme internet busyplace.fr est défini par les réponses de l’utilisateur qui dispose grâce aux questionnaires présentés d’un soutien d’aide à la rédaction de ses actes. L’acceptation des conditions générales de vente des services en ligne de busyplace.fr sous tend la reconnaissance de l’utilisateur que ces services ne se substituent aucunement à la consultation juridique d’un professionnel du droit ; de même que, conformément à la réglementation en vigueur, en cas de mise en relation avec un avocat ou un expert-comptable via la plateforme busyplace.fr, la relation contractuelle établie entre lesdits partis sera totalement indépendante de la responsabilité de la société NEXTGEN.</p>

          <p className='text-base font-normal text-gray mt-6'>Les conditions générales de vente détaillent les clauses et les conditions qui administrent l’utilisation du service en ligne de publication d’annonces légales proposé par la société NEXTGEN à partir de son site internet busyplace.fr
            Processus de demande d'un avis de publication
          </p>
          <p className='text-base font-normal text-gray mt-6'>Les avis de publication sont rédigés en ligne sur le site busyplace.fr et doivent comporter clairement les coordonnées de l’annonceur ou de son mandataire (nom, prénom, téléphone et courriel) et son adresse de facturation.</p>
          <p className='text-base font-normal text-gray mt-6'>L'annonceur, après avoir rédigé son annonce et pris connaissance de son devis en ligne doit pour valider son avis de publication choisir entre :</p>
          {/* <h2 className='font-extrabold text-lg sm:text-xl text-primary mt-12'>Les services proposés par annonces@busyplace.fr sont les suivants :</h2> */}

          <ul className='mt-6'>
            <li className='flex gap-2 items-center'><TbPointFilled className='text-primary' />Le paiement sécurisé par carte bleue qui déclenchera une commande immédiate,</li>
            <li className='flex gap-2 items-center'><TbPointFilled className='text-primary' />Le paiement par virement bancaire qui nécessitera un délai de traitement plus long.</li>
            {/* <li className='flex gap-2 items-center'><TbPointFilled className='text-primary' />Aide à la définition de projet</li>
            <li className='flex gap-2 items-center'><TbPointFilled className='text-primary' />E-formalité</li>
            <li className='flex gap-2 items-center'><TbPointFilled className='text-primary' />Opération de télémarketing (en partenariat avec la société MIP)</li>
            <li className='flex gap-2 items-center'><TbPointFilled className='text-primary' />Prise de rendez-vous commerciaux (en partenariat avec la société MIP)</li>
            <li className='flex gap-2 items-center'><TbPointFilled className='text-primary' />Création d’identité visuelle (en partenariat avec la société MIP)</li>
            <li className='flex gap-2 items-center'><TbPointFilled className='text-primary' />Création de support de communication (en partenariat avec la société MIP)</li>
            <li className='flex gap-2 items-center'><TbPointFilled className='text-primary' />Création de site web/ Marchand (en partenariat avec la société MIP)</li>
            <li className='flex gap-2 items-center'><TbPointFilled className='text-primary' />Animation de réseaux sociaux (en partenariat avec la société MIP)</li>
            <li className='flex gap-2 items-start'><TbPointFilled className='text-primary w-8 h-8' />Mise en relation Cabinet d’avocats ; d’expertise comptable ; de sociétés de domiciliations (mise en relation, à la demande de l’utilisateur
              avec la société sélectionnée par l’utilisateur, conformément à la loi et aux règles déontologiques applicables au CGU d’avocats,
              experts comptables ou tous autres partenaires de la société legals)</li>
            <li className='flex gap-2 items-center'><TbPointFilled className='text-primary' />Module gestion (en partenariat avec la société 1expertcomptable.com)</li>
            <li className='flex gap-2 items-center'><TbPointFilled className='text-primary' />Module paie (en partenariat avec la société Ezbeez) </li> */}
          </ul>
          <p className='text-base font-normal text-gray mt-6'>La réception et l’encaissement du règlement par NEXTGEN entraînera la commande de l’avis de publication. Pour les mandataires payant sur relevé de factures en fin de mois, l'acceptation des présentes CGV valent pour « Bon pour accord » entraînant commande de l’avis de publication
            Après enregistrement du paiement busyplace.fr confirmera la commande par l’envoi sous forme de courrier électronique d’un accusé de prise en charge accompagné d’une facture.</p>

          <p className='text-base font-normal text-gray mt-6'>Pour toutes les annonces hors des départements français du 75, 77, 78, 91, 92, 93, 94 et 95 validées par l'annonceur, ce dernier emporte mandat confié à NEXTGEN d'entreprendre en son nom et pour son compte les démarches utiles à la parution de son annonce dans un journal confrère habilité et sélectionné par NEXTGEN. Pour tous les départements français excepté pour les départements du 75, 77, 78, 91, 92, 93, 94 et 95, busyplace.fr est un prestataire indépendant et intervient uniquement dans le cadre d’un mandat confié par l’annonceur ou son mandataire selon les présentes conditions générales de vente</p>
          <p className='text-base font-normal text-gray mt-6'>Suite à l’avis de publication, le journal habilité fera parvenir à l’annonceur, par mail ou par tout autre moyen une « attestation de parution » qui lui servira pour faire enregistrer sa demande auprès du Guichet Entreprise (https://www.guichet-entreprises.fr) ou du Guichet Unique (https://procedures.inpi.fr/?/).</p>
          <p className='text-base font-normal text-gray mt-6'>La prise en charge des demandes d'avis de publication décrits ci-dessus sont réalisés pendant les jours ouvrés.</p>
          <p className='text-base font-normal text-gray mt-6'>Responsabilité de l'annonceur et de son mandataire</p>
          <p className='text-base font-normal text-gray mt-6'>L’annonceur et utilisateur du service de publication d’annonces légales busyplace.fr est le seul responsable de la conformité dans la rédaction de son annonce qu'il veut publier. Il lui appartient avant de faire confirmation et de payer son avis de publication, de vérifier l'exactitude du texte et des différentes mentions obligatoires qui doivent y figurer. L’annonceur doit strictement se conformer aux lois en vigueur comme à l’adéquation à l’usage dont il destine son avis de publication.</p>
          <p className='text-base font-normal text-gray mt-6'>Toutes les demandes de publication d’une annonce impliquent au préalable leur relecture dans le but d’omettre toute erreur ou contestation (tout rectificatif ou additif est payant).</p>
          <p className='text-base font-normal text-gray mt-6'>Les annulations et modifications des Avis de Publication ne sont pas acceptées ou uniquement dans la mesure où elles sont faites par écrit et dans les délais impartis par les journaux habilités respectifs.</p>
          <p className='text-base font-normal text-gray mt-6'>L'utilisateur utilise le service busyplace.fr sous son unique responsabilité et en conséquence, la responsabilité de NEXTGEN ne pourra en aucune manière être engagée pour les dommages directs ou indirects qu'il pourrait subir du fait de la publication de son annonce.</p>
          <p className='text-base font-normal text-gray mt-6'>busyplace.fr et les journaux habilités à qui il fait appel se réservent le droit de refuser toute publication non conforme aux lois en vigueur.</p>
          <p className='text-base font-normal text-gray mt-6'>Ni busyplace.fr ni les journaux utilisés pour la publication ne pourront être tenus pour responsable de la non réception de l’attestation de publication ou de la facture suite à une défaillance technique ou d’omission sur les coordonnées communiquées par l’annonceur.</p>
          <p className='text-base font-normal text-gray mt-6'>Paiement et facturation</p>
          <p className='text-base font-normal text-gray mt-6'>L’Avis de publication est facturé à l’annonceur par NEXTGEN sur la base du tarif légal en vigueur et TTC. Le tarif est soumis à l’application de la TVA. Le tarif comprend le prix de la publication ainsi que les frais de gestion.</p>
          <p className='text-base font-normal text-gray mt-6'>La facture est émise au nom de l'annonceur ou de son mandataire le cas échéant et adressée à celui-ci aux coordonnées indiquées par ce dernier. L'élection de domicile est réputée faite lors de la rédaction en ligne de la commande.</p>
          <p className='text-base font-normal text-gray mt-6'>Le paiement en ligne par carte bancaire est assuré par STRIPE, Plateforme de traitement de paiements en ligne. Le numéro de la carte bancaire n'est à aucun moment connu de NEXTGEN. Tout incident et/ou retard de paiement d’une échéance entraînera de plein droit l’application d’une indemnité. Les frais, honoraires et dépens engagés par NEXTGEN pour le recouvrement d’une créance seront supportés intégralement par l’annonceur.</p>
          <p className='text-base font-normal text-gray mt-6'>Juridiction compétente</p>
          <p className='text-base font-normal text-gray mt-6'>L'interprétation et l'exécution des présentes conditions générales de vente sont exclusivement régies par le droit français et les conventions internationales applicables en France. Pour toute rétractation de souscription aux formules et services présentés sur la plateforme busyplace.fr, conformément aux dispositions des articles L.221-18, L.221-19 et L.221-21 du Code de la consommation, l’utilisateur est invité à nous contacter par courriel à contact@busyplace.fr ou par courrier au siège social de la Société NEXTGEN dans les 15 jours à dater de la souscription. Pour toute information complémentaire, sur l’étendue, le contenu et les instructions quant à l’exercice du droit de rétractation de l’utilisateur, merci de contacter notre service client au :
            01 53 65 16 67
          </p>
          <p className='text-base font-normal text-gray mt-6'>Tout litige qui peut être le résultat de l’exécution ou de l’interprétation des présentes CGV sera soumis exclusivement au Tribunal de Commerce de Paris.</p>
          <p className='text-base font-normal text-gray mt-6'>NEXTGEN disposera néanmoins de la faculté de saisir toute autre juridiction qui se révèlera compétente et notamment celle du siège social de l’annonceur.</p>
          <p className='text-base font-normal text-gray mt-6'>Les présentes CGV ont été mises à jour le 01/01/2024 et peuvent être modifiées à tout moment.</p>
          {/* <h2 className='font-extrabold text-lg sm:text-xl text-primary mt-12'>Certains services sont disponibles sous formes d’abonnements :</h2> */}

          {/* <h2 className='font-extrabold text-lg sm:text-xl text-white mt-12 bg-thColor w-fit rounded-md px-2'>Découverte</h2> */}

          {/* <p className='text-base font-normal text-gray mt-6'><span className='text-thColor font-bold'> 1 mois </span>d’accès aux services d’attestation de dépôt des fonds, création de comptes bancaire en ligne, E-formalité, module de gestion. L’abonnement souscrit court sur le mois suivant l’enregistrement, le mois en cours est offert. La souscription se fait par virement de compte à compte non reconductible sauf accepté par l’utilisateur.</p> */}

          {/* <h2 className='font-extrabold text-lg sm:text-xl text-white mt-12 bg-thColor w-fit rounded-md px-2'>Test</h2> */}

          {/* <p className='text-base font-normal text-gray mt-6'><span className='text-thColor font-bold'> 3 mois </span>d’accès aux services d’attestation de dépôt des fonds, création de comptes bancaire en ligne, E-formalité, module de gestion, module paie. L’abonnement souscrit court sur les 3 mois suivant l’enregistrement, le mois en cours est offert. La souscription se fait par virement de compte à compte non reconductible sauf accepté par l’utilisateur. */}
          {/* </p> */}
          {/* <h2 className='font-extrabold text-lg sm:text-xl text-white mt-12 bg-thColor w-fit rounded-md px-2'>Partenaire</h2> */}

          {/* <p className='text-base font-normal text-gray mt-6'><span className='text-thColor font-bold'> 1 an </span> */}
          {/* d’accès aux services d’attestation de dépôt des fonds, création de comptes bancaire en ligne, E-formalité, module de gestion, module paie. L’abonnement souscrit court sur les 12 mois suivant l’enregistrement, le mois en cours est offert. La souscription se fait par virement de compte à compte reconductible sauf dénoncée par l’utilisateur dans les 30 jours avant la date anniversaire de ladite souscription. */}
          {/* </p> */}
          {/* <p className='text-base font-normal text-gray mt-6'>Pour toute rétractation de souscription aux formules d’abonnements présentés sur la plateforme annonces@busyplace.fr, conformément aux dispositions des articles L.221-18, L.221-19 et L.221-21 du Code de la consommation, l’utilisateur est invité à nous contacter par courriel à  <Link  className='hover:text-secondary' to="mailto:contact@busyplace.fr"> contact@busyplace.fr</Link> ou par courrier au siège social de la Société Legals dans les 15 jours à daté de la souscription. Pour toute information complémentaire, sur l’étendue, le contenu et les instructions quant à l’exercice du droit de rétractation de l’utilisateur, merci de contacter notre service client au : <Link className='hover:text-secondary' to="tel: 01 53 65 16 66"> 01 53 65 16 66</Link></p> */}

          {/* <h2 className='font-extrabold text-lg sm:text-xl text-primary mt-12'><Link  className='hover:text-secondary' to="/">  annonces@busyplace.fr </Link> gère toutes vos formalités juridiques</h2> */}

          {/* <p className='text-base font-normal text-gray mt-6'><span className='font-bold'>CRÉER</span> ma société</p> */}
          {/* <p className='text-base font-normal text-gray '><span className='font-bold'>MODIFIER/GéRER</span> ma société</p> */}
          {/* <p className='text-base font-normal text-gray '><span className='font-bold'>FERMER</span> ma société</p> */}
          {/* <p className='text-base font-normal text-gray '><span className='font-bold'>OUVRIR</span> un compte bancaire Expert</p> */}
          {/* <p className='text-base font-normal text-gray '><span className='font-bold'>PUBLIER</span> une annonce légale instantanément</p> */}
          {/* <p className='text-base font-normal text-gray '><span className='font-bold'>ATTESTATION</span> de dépôt des fonds Sous 72h</p> */}

        </div >
        {/* <div className='mb-4 sm:mb-12 mt-3 sm:mt-24 relative'> */}
        {/* <div className='max-w-[988px] m-auto sm:p-6 relative' style={{background: 'rgba(96, 45, 180, 0.1)', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)'}}>
                <div className='md:flex justify-end  px-4 sm:px-5'>
                   <img src={VousImages} alt="VousImages" className='vousImages ' /> 
                  <div className=' py-2 sm:py-12 max-w-[580px] mr-0 text-left flex justify-start flex-col w-full' >
                    <p className='text-sm sm:text-base text-gray'><span className='font-bold text-xl text-thColor'>Vous êtes</span> Créateur, Nouvel Entrepreneur, Jeune Entreprise, Formaliste, Expert-Comptable, Juriste d’entreprise, Avocat ?</p>
                    <p className='mt-2 sm:mt-7 text-sm sm:text-lg font-semibold text-gray max-w-[580px]'>Facilitez vos démarches et confiez-nous toutes vos formalités juridiques, administratives, bancaires, gestion sociale ... </p>
                  </div>
                </div>
          </div> */}
        {/* </div> */}
      </div>
      <Footer />
    </>
  )
}
