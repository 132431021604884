import React, { useEffect } from 'react'
import Breadcrumb from '../component/Breadcrumb'
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';
export default function MentionsLegales() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
     <Helmet>
        <title>BusyPlace : Gestion des Formalités Juridiques en Ligne</title>
        <meta name="description" content="Optez pour BusyPlace pour une expérience fluide de vos formalités juridiques, entièrement accessible en ligne. Simplifiez vos démarches légales grâce à une plateforme efficace et sécurisée." />
        {/* <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/mentions-legales`} /> */}
      </Helmet>
      <div className='w-full h-full bg-mainBg '>
        <div className='md:max-w-[1477px] relative w-full h-full m-auto py-6 md:py-14 px-4 sm:px-14'>
          <div className='flex items-center justify-start gap-4 w-full m-auto'>
            <Breadcrumb Accueil="Accueil" page="Mentions légales" />
          </div>
          <div className='max-w-[1277px] m-auto mt-8 md:mt-16 block'>
            <h1 className='text-xl sm:text-2xl md:text-3xl font-black text-primary'>Mentions légales</h1>
          </div>
        </div>
      </div>
      <div className='md:max-w-[1477px] relative w-full h-full m-auto px-4 sm:px-14'>
        <div className='max-w-[1277px] m-auto block bg-white py-6 md:py-12'>
          <p className='text-base font-normal text-primary '>Politique de confidentialité et droit d’accès à vos données personnelles : Conformément à la loi du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, busyplace.fr a fait l'objet d'une déclaration auprès de la CNIL sous le numéro 4424395. Vous disposez d'un droit d'accès, de modification, de rectification et de suppression des données qui vous concernent. Vous pouvez exercer ce droit en nous contactant </p>

          <p className='text-base font-normal text-gray mt-6'>par courriel à <span className='font-bold'> contact@busyplace.fr</span></p>
          <p className='text-base font-normal text-gray'> ou par courrier postal envoyé à l'adresse suivante :</p>
          <p className='text-base font-bold text-gray'>NEXTGEN - <span className='text-base font-bold text-gray'>BUSYPLACE</span></p>
          <p className='text-base font-bold text-gray'>128 rue de la Boétie</p>
          <p className='text-base font-bold text-gray'> 75008 PARIS</p>

          <p className='text-base font-normal text-gray mt-6'>Le site internet busyplace.fr a recours à l'utilisation de cookies afin de reconnaître l'utilisateur lors de sa connexion sur son site. L'utilisateur peut à tout moment désactiver l'utilisation de ces cookies en sélectionnant les paramètres appropriés dans son navigateur. </p>


          <h2 className='font-extrabold text-lg sm:text-xl text-primary mt-12'>Edition du site</h2>

          <p className='text-base font-normal text-gray mt-6'> Editeur du site busyplace.fr</p>
          <p className='text-base font-bold text-gray'>NEXTGEN - <span className='text-base font-bold text-gray'>BUSYPLACE</span></p>
          <p className='text-base font-bold text-gray'>128 rue de la Boétie</p>
          <p className='text-base font-bold text-gray'> 75008 PARIS</p>
          <p className='text-base font-normal text-gray'>  RCS PARIS 922 622 832</p>
          <p className='text-base font-normal text-gray'>  Directeur de la publication : Renaud Morelli</p>


          <h2 className='font-extrabold text-lg sm:text-xl text-primary mt-12'>Propriété Intellectuelle</h2>

          <p className='text-base font-normal text-gray mt-6'>L'ensemble de ce site relève de la législation française et internationale sur le droit d'auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris les représentations iconographiques et photographiques. La reproduction, adaptation et/ou traduction de tout ou partie de ce site sur un support quel qu'il soit, est formellement interdite sauf autorisation expresse du Directeur de la publication. </p>


          <h2 className='font-extrabold text-lg sm:text-xl text-primary mt-12'>Modification du site</h2>

          <p className='text-base font-normal text-gray mt-6'>L'équipe éditoriale se réserve le droit de modifier ou de corriger le contenu de ce site et de ces mentions légales à tout moment et ceci sans préavis.</p>


          <h2 className='font-extrabold text-lg sm:text-xl text-primary mt-12'>Développement et Hébergement </h2>

          <p className='text-base font-normal text-gray mt-6'>Le site busyplace.fr est hébergé par la société NEXTGEN - 128 rue de la Boétie 75008 PARIS. </p>
        </div >
      </div>
      <Footer />
    </>
  )
}
