import React,{useEffect,useState, forwardRef, useRef, useImperativeHandle} from 'react'
import { Link,useNavigate,useLocation,useHistory ,useParams } from 'react-router-dom'
import logoMastercard from '../pages/Form/FormComponent/CardImages/logo-mastercard.png'
import logoCb from '../pages/Form/FormComponent/CardImages/logo-cb 1.png'
import logovisa from '../pages/Form/FormComponent/CardImages/logo-visa.png'
import maestro from '../pages/Form/FormComponent/CardImages/maestro.png'
import visaElectron from '../pages/Form/FormComponent/CardImages/visa-electron.png'
import { InputAdornment, TextField } from '@mui/material'
import { BsCreditCard, BsCalendar4 } from "react-icons/bs"
import { BiLockAlt } from "react-icons/bi"
import { useDispatch, useSelector } from 'react-redux';
import ToastMessageComp from '../component/ToastMessageComp'
import { alertActions } from '../_actions'
import {
  PaymentElement,
  LinkAuthenticationElement,
  
} from "@stripe/react-stripe-js";

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, {
  locale: 'fr' // Set the locale to French
});


const PaymentSecondAdmin = forwardRef((props, ref)=> {
  const location = useLocation(); 
  const stripe = useStripe();
  const elements = useElements();
  
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const dispatch = useDispatch(); 
  const alert = useSelector(state => state.alert);
  const currentUserGet = useSelector(state => state.authentication.user);
  const [alert_msg, setAlert_msg] = useState(false);
  const [email, setEmail] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0)   
    // // console.log("location.pathname",location.pathname)
    setCurrentPath(location.pathname);
    // console.log("currentUserGet",currentUserGet)
    setEmail(props.currentUserDetails?props.currentUserDetails.data.email:"")
  }, [location])

  /* useEffect(() => {
    // Update the LinkAuthenticationElement with the default email on mount
    const linkAuthElement = elements?.getElement(LinkAuthenticationElement);
    
    if (linkAuthElement) {
      console.log("email",email)
      linkAuthElement.update({ value: { email } });
    }
  }, [elements, email]); */

  const handleEmailChange = (event) => {
    // Update the email state when the email in LinkAuthenticationElement changes
    setEmail(event.value.email);
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
    
  }, [stripe]);

  useImperativeHandle(ref, () => ({
    
    async handleSubmitCheckout(){ 
      setAlert_msg(false);     
      // // console.log("react app url",`${process.env.REACT_APP_WEB_URL}${currentPath}?activstep=5`);
      if (!stripe || !elements) {
        
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);
      props.setLoading(true);

      const {error}  = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url:`${process.env.REACT_APP_WEB_URL}${currentPath}?activstep=5`,
        },
      });
      
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setAlert_msg(true);
        setMessage(error.message);
        dispatch(alertActions.error(error.message)); 
      } else {
        setAlert_msg(true);
        dispatch(alertActions.error("An unexpected error occurred.")); 
        setMessage("An unexpected error occurred.");
      }
     
      setIsLoading(false);
      props.setLoading(false);
    }

  }));
  
  const paymentElementOptions = {
    // layout: "tabs"
    paymentMethodOrder: ['card'],
    defaultValues: {
      billingDetails: {
        email: email, // Set default email value
      },
    },
  }
  return (
    <>
      {alert_msg?
      <ToastMessageComp  message={alert.message} type={alert.type} />
      :""}
      <div >
        <div className='py-6 px-3 md:px-6 flex  flex-col md:flex-row justify-between items-center' >
          <div className='order-last md:order-first flex  items-center gap-1 sm:gap-2'>
            <img src={logovisa} alt="logovisa" className='w-[60px] h-[auto] xl:w-[70px] xl:h-[29px]' />
            <img src={logoMastercard} alt="logo" className='w-[60px] h-[auto] xl:w-[81px] xl:h-[60px]' />
            <img src={maestro} alt="maestro" className='w-[60px] h-[auto] xl:w-[68px] xl:h-[68px]' />
            <img src={logoCb} alt="logoCb" className='w-[60px] h-[auto] xl:w-[60px] xl:h-[33px]' />
            <img src={visaElectron} alt="visaElectron" className='w-[60px] h-[auto] xl:w-[60px] xl:h-[60px]' />
          </div>
          <div className='order-first md:order-last'>
            <h3 className='text-xl font-medium text-primary'>PAIEMENT SECURISE</h3>
            <p className='text-sm font-normal text-black-light'>par carte de crédit</p>
          </div>
        </div>
        
          <div className=' m-auto sm:py-12 mx-5 '>          
            <LinkAuthenticationElement
              id="link-authentication-element"
              onChange={handleEmailChange}
              locale="fr"
            />
            <PaymentElement id="payment-element" options={paymentElementOptions} locale="fr" />
            {/* {message && <div id="payment-message">{message}</div>} */}
          </div>
       
       {/*  <div className='flex flex-col gap-4 sm:gap-8 max-w-[450px] m-auto sm:py-12 mx-2 amanish'>
          <TextField
            // disableUnderline
            sx={{
              "& .MuiInputLabel-root": { color: '#797979', },
              "& fieldset": { border: '1px solid #eee' },
            }}
            InputProps={{
              style: {
                borderRadius: '8px',
                color: '#797979',
                background: 'rgb(39 41 91 / 6%)',
              }
            }
            }
            className='w-full'
            label="Nom sur la carte" variant="outlined" />

          <TextField
            // disableUnderline
            sx={{
              "& .MuiInputLabel-root": { color: '#797979', },
              "& fieldset": { border: '1px solid #eee' },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment >
                  <BsCreditCard className='w-7 h-7 pr-2  ' />
                </InputAdornment>
              ),
              style: {
                borderRadius: '8px',
                color: '#797979',
                background: 'rgb(39 41 91 / 6%)',

              }
            }
            }
            className='w-full'
            label="Numéro de la carte" variant="outlined" />

          <div className='grid grid-cols-2 gap-12'>
            <TextField
              // disableUnderline
              sx={{
                "& .MuiInputLabel-root": { color: '#797979', },
                "& fieldset": { border: '1px solid #eee' },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BsCalendar4 className='w-5 h-5' />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: '8px',
                  color: '#797979',
                  background: 'rgb(39 41 91 / 6%)',
                }
              }
              }
              className='w-full'
              label="MM/AA" variant="outlined" />
            <div>
              <TextField
                // disableUnderline
                sx={{
                  "& .MuiInputLabel-root": { color: '#797979', },
                  "& fieldset": { border: '1px solid #eee' },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BiLockAlt className='w-6 h-6' />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: '8px',
                    color: '#797979',
                    background: 'rgb(39 41 91 / 6%)',
                  }
                }
                }
                className='w-full'
                label="CVV" variant="outlined" />
              <p className='text-[10px] font-extralight text-[#414141] mt-1'>Chiffres inscrits au dos de votre carte.</p>
            </div>



          </div>
        </div> */}
      </div>
    </>
  )
})
export default  PaymentSecondAdmin;