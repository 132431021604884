import React, { useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Breadcrumb from "../component/Breadcrumb";
import Express from "../pages/Form/FormComponent/Express";
import Procedures from "../pages/Form/FormComponent/Procedures";
import PaymentSideBlock from "../pages/Form/FormComponent/PaymentSideBlock";
import { Modellibre } from "../pages/Form/adsFormComp";
import FormSummary from "../pages/Form/FormSummary";
import FormSummaryAdmin from "./FormSummaryAdmin";
import CompleteAddress from "../pages/Form/CompleteAddress";
import CompleteAddressAdmin from "./CompleteAddressAdmin";
import PaymentFormAdmin from "./PaymentFormAdmin";
import Footer from "../component/Footer";
import FormSuccess from "../pages/Form/FormSuccess";
import PaymentSecondAdmin from "./PaymentSecondAdmin";
import axios from "axios";
import { announceActions, alertActions } from "../_actions";
import ToastMessageComp from "../component/ToastMessageComp";
import PageLoaderComp from "../component/pageLoaderComp";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { isValidEmail, isNumericValue, authHeader } from "../_helpers";
import { Ads_fullname } from "../_helpers/ads_fullname";
import { getDetailsBySiren } from "../_helpers";
import { announceConstants } from "../_constants";
import moment from "moment";
import Sidebar from "./component/Sidebar";
import { Button } from "@mui/material";
import FormSuccessAdmin from "./FormSuccessAdmin";

const stripePromise  = loadStripe(process.env.REACT_APP_STRIPE_KEY, {
  locale: 'fr' // Set the locale to French
});

export function ModellibreForm() {
  const appearance = {
    theme: 'stripe',
  };
  const [loading, setLoading] = useState(false);
  const [loadingFullpage, setLoadingFullpage] = useState(false);
  const [isCheckedSerenity, setIscheckedSerenity] = useState(false);
  const [is_general_conditions_sale,setis_general_conditions_sale]=useState(false)
  const [general_conditions_sale_msg,setgeneral_conditions_sale_msg]=useState("")
  const [clientSecret, setClientSecret] = useState("");
  const [options, setOptions] = useState({
    clientSecret,
    appearance
  });
  const [queryParameters] = useSearchParams();
  const childRef = useRef();
  const announceIdRef = useRef("");
  const steps = [
    "Remplir le formulaire",
    "Visualiser le récapitulatif",
    "Adresse de facturation",
    "Procéder au paiement",
    "succès",
  ];
  const alert = useSelector((state) => state.alert);
  const announceData = useSelector(state => state.announce.announce);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const ads = "modele libre";
  const adsname = "modellibre";
  const { user_id, id,annonce_id } = useParams();
  const [currentUserDetails, setCurrentUserDetails] = useState({});
  const [successful, setSuccessful] = useState(false);
  const [announceDetails, setAnnounceDetails] = useState("");
  const [AdsFullName, setAdsFullName] = useState("");
  const [formData, setFormData] = useState({
    user_id: user_id,
    announce_id: annonce_id ? annonce_id : "",
    title: ads.split("-").join(" "),
    add_type_full: AdsFullName,
    add_type: adsname ? adsname : "",
    form_type: adsname ? adsname : "",
    department_name: "",
    referenceclient: "",
    denomination: "",
    zonelibre: "",
  });
  const [userSelectedAddress, setUserSelectedAddress] = useState({
    "announce_id":annonce_id ? annonce_id : "",
    "user_id":currentUserDetails && currentUserDetails.data?currentUserDetails.data.id:"",
    "delivery_address":currentUserDetails && currentUserDetails.user_address?currentUserDetails.user_address[0]:"",
    "shipping_address":currentUserDetails && currentUserDetails.user_address?currentUserDetails.user_address[0]:"",
    "email":currentUserDetails && currentUserDetails.data?currentUserDetails.data.email:"",
    "telephone":currentUserDetails && currentUserDetails.data?currentUserDetails.data.telephone:"",
    "billing_address_type":"account_address",
    "billing_address_denomination":currentUserDetails && currentUserDetails.data?currentUserDetails.data.raisosociale:"",
    "billing_address_civility":currentUserDetails && currentUserDetails.data?currentUserDetails.data.civil:"",
    "billing_address_nom":currentUserDetails && currentUserDetails.data?currentUserDetails.data.firstname:"",
    "billing_address_prenom":currentUserDetails && currentUserDetails.data?currentUserDetails.data.lastname:"",
    "billing_address_address":currentUserDetails && currentUserDetails.data?currentUserDetails.data.adresse:"",
    "billing_address_code_postal":currentUserDetails && currentUserDetails.data?currentUserDetails.data.postalcode:"",
    "billing_address_ville":currentUserDetails && currentUserDetails.data?currentUserDetails.data.ville:"",
    "billing_address_ville_id":currentUserDetails && currentUserDetails.data?currentUserDetails.data.ville_id:"",
    "billing_address_villeArr":currentUserDetails && currentUserDetails.data&& currentUserDetails.data.villeArr?JSON.parse(currentUserDetails.data.villeArr):[],
  });
  const [isSubmitted, setSubmitted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [payamentPrice, sePayamentPrice] = useState(0);
  const [paymentData, setPaymentData] = useState("");

  useEffect(() => {
    getUserById();
    setAdsFullName(Ads_fullname(adsname));
    console.log('annonce_id',annonce_id)
    if (annonce_id) {
      const announce = {
        data: [],
        announce_id: annonce_id,
      };
      dispatch({ type: announceConstants.CREATE_SUCCESS, announce });
    }
    if (!queryParameters.get("activstep") && annonce_id) {
      getAnnounceDetails(annonce_id);
     
    }
  }, []);

  /* useEffect(() => {
    if (announceDetails) {
      const announceDetailsGet = announceDetails.data;
      announceDetailsGet.votre_annonce_json.announce_id = annonce_id ? annonce_id : "";
      // announceDetailsGet.votre_annonce_json.title = ads ? "" : "";
      console.log("announceDetailsGet",announceDetailsGet)
      setFormData(announceDetailsGet.votre_annonce_json);
    }
  }, [announceDetails]); */
  
  useEffect(() => {  
    if(activeStep ===3 && payamentPrice >0){
      fetch(process.env.REACT_APP_API_URL+`api/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: [],announce_id: annonce_id,price:payamentPrice/* ,paymentData:paymentData */}),
      })
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.clientSecret);
          let clientSecret = data.clientSecret
          setOptions({clientSecret,appearance})
        });
    }    
    
  }, [activeStep,payamentPrice])

  useEffect(() => {
   //console.log('sfsdfsdfdsf dsf sdf dsf');
    if (paymentData && paymentData.data) {
      sePayamentPrice(
        parseFloat(
          parseFloat(paymentData.data.total) + (isCheckedSerenity ? 118.8 : 0)
        ).toFixed(2)
      );
    }
  }, [isCheckedSerenity, paymentData]);

  useEffect(() => {
    
    if (
      queryParameters.get("activstep") &&
      annonce_id
    ) {
      
      const getDetails = async () => {
        const details_response = await getAnnounceDetails(annonce_id);
        
        if (details_response.success) {
          if (parseInt(details_response.data.data.status) === 1 || parseInt(details_response.data.data.status) === 2) {
            const data = {
              announce_id: annonce_id,
              payment_order_id:
                moment().format("DDMMY") + "_" + annonce_id.padStart(8, "0"),
              invoice_number:
                moment().format("DDMMY") + "_" + annonce_id.padStart(8, "0"),
              invoice_date: moment().format("DD-MM-Y"),
              date_payment: moment().format("DD-MM-Y"),
              payment_intent: queryParameters.get("payment_intent"),
              payment_price: details_response.data.data.total,
              payment_intent_client_secret: queryParameters.get(
                "payment_intent_client_secret"
              ),
            };
            console.log('sfsdfsdfdsf dsf sdf dsf data',data);
            console.log('sfsdfsdfdsf dsf sdf dsf',details_response);
            const update_response = await updateAnnouncePayment(data);
            
          //  await getAnnounceDetails(annonce_id);
            
          }
        }
      };
      getDetails();
    }
  }, [
    queryParameters.get("activstep"),
    // queryParameters.get("payment_intent"),
    // queryParameters.get("payment_intent_client_secret"),
  ]);

  async function handleChange(e) {
    const { name, value } = e.target;
    setFormData((formData) => ({ ...formData, [name]: value }));
  }

  function handleChangeDate(e, name) {
    let newDate = moment(e.$d).format("YYYY/MM/DD");
    setFormData((formData) => ({ ...formData, [name]: newDate }));
  }

  async function getUserById() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `api/user/${user_id}`,
        authHeader()
      );
      if (response.data.sucess === true) {
        setCurrentUserDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function submitPaymentDetails() { 
    let valid  = false;
    // setSubmitted(true);
    setLoading(true);
    delete paymentData['data']['billing_address_json'];
    delete paymentData['data']['currentUserDetails'];
    delete paymentData['data']['select_cartdetailJ'];
    delete paymentData['data']['select_cartdetailN'];
    delete paymentData['data']['select_department'];
    delete paymentData['data']['votre_annonce_json'];
    delete paymentData['data']['votre_annonce'];
    delete paymentData['data']['billing_address_json_parse'];
    delete paymentData['data']['user_detail'];
    delete paymentData['data']['votre_html'];
    
    let getResponse =  await dispatch(announceActions.updatePayment({announce_id:annonce_id,user_id:user_id,serenite:isCheckedSerenity,paymentData:paymentData})).then((responseData) => {         
      // // // console.log("responseData",responseData)        
      return Promise.resolve(responseData);   
      
    })
    .catch((err) => {         
      return Promise.reject({
          "sucess": false,
          "msg": "Une erreur s'est produite. Veuillez réessayer!",
          "data": "",
          "insertID": ""
      });      
    });
    // setSuccessful(true);
    // // // console.log("getResponse",getResponse);
    setLoading(false);
    if(getResponse.sucess === true){   

      valid = true
    }else{
      valid = false
    }
    return valid;
  }

  async function submitUserDetails() { 
    
    let valid  = false;
    // setSubmitted(true);
    if(userSelectedAddress.email && isValidEmail(userSelectedAddress.email) && userSelectedAddress.telephone && isNumericValue(userSelectedAddress.telephone)  && ((userSelectedAddress.billing_address_type === "account_address" && userSelectedAddress.billing_address_nom  && userSelectedAddress.billing_address_prenom) || (userSelectedAddress.billing_address_type === "another_address"  && userSelectedAddress.billing_address_nom  && userSelectedAddress.billing_address_prenom   && userSelectedAddress.billing_address_code_postal  && userSelectedAddress.billing_address_ville) || ( userSelectedAddress.billing_address_type === "customer_address" && (userSelectedAddress.billing_address_denomination  ||  userSelectedAddress.billing_address_address  || userSelectedAddress.billing_address_code_postal  || userSelectedAddress.billing_address_ville)) )  ){
      setLoading(true);
      userSelectedAddress.user_id = user_id;
      userSelectedAddress.announce_id = annonce_id;
      console.log("userSelectedAddress",userSelectedAddress)
      let getResponse =  await dispatch(announceActions.update_user(userSelectedAddress)).then((responseData) => { 
        return Promise.resolve(responseData); 
      })
      .catch((err) => { 
        setLoading(false);        
        return Promise.reject({
            "sucess": false,
            "msg": "Une erreur s'est produite. Veuillez réessayer!",
            "data": "",
            "insertID": ""
        });      
      });
      // setSuccessful(true);
      // // // console.log("getResponse",getResponse);
      setLoading(false); 
      if(getResponse.sucess === true){   
           
        valid = true
      }else{
        valid = false
      }
    }
    
    return valid;
  }
  async function handleSubmit(e) {
    e.preventDefault();
    let valid = false;
    setSuccessful(false);
    setSubmitted(true);
    if (
      formData.department_name &&
      formData.form_type /* && formData.referenceclient */ &&
      formData.denomination
    ) {
      valid = true;
    }
    if (valid === true) {
      /* setLoading(true);
      const getResponse = await dispatch(
        announceActions.admin_create(formData)
      );
      setLoading(false);
      if (getResponse.sucess === true) {
        valid = true;
        // navigate(`/admin/clientdetails/${user_id}`);
        // navigate(`/nos-formulaires/modele-libre/modellibre/${getResponse.data.id}`);
        // if (location.pathname.split('/').length === 4) {
        //   navigate(location.pathname);
        // }
      } else {
        valid = false;
      } */
      try {
        setLoading(true);
        formData.announce_id = annonce_id;
       
        const getResponse = await dispatch(
          announceActions.admin_create(formData)
        );
        
        setLoading(false);
        
        if (getResponse.sucess) {
          valid = true;
          // Navigate to a specific route or refresh the page based on conditions
          // Example:
          navigate(`/admin/modelelibre/${user_id}/${getResponse.data.id}`);
          // navigate(`/nos-formulaires/modele-libre/modellibre/${getResponse.data.id}`);
          // if (location.pathname.split('/').length === 4) {
          //   navigate(location.pathname);
          // }
        } else {
          valid = false;
          console.error("Failed to create announcement:", getResponse.message);
        }
      } catch (error) {
        setLoading(false);
        valid = false;
        console.error("Error during form submission:", error);
      }
    }
    return valid;
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  async function handleNext(e,activeStepGet) {
    let submitResult = false;
    if (activeStepGet === 0) {
      submitResult = await handleSubmit(e);
    }
    if (activeStepGet === 1) {
      submitResult = true;
    }
    if (activeStepGet === 2) {
      submitResult = await submitUserDetails();
    }
    if(activeStepGet === 3){
      setgeneral_conditions_sale_msg("");
      if(!is_general_conditions_sale){
        setgeneral_conditions_sale_msg("Veuillez accepter les conditions générales de vente")
        return false;
      }
      submitResult = await submitPaymentDetails();
      // // // console.log("submitResult",submitResult)

    }
    if (submitResult) {
      setLoading(false);
      
      if(currentUserDetails && currentUserDetails.UserProDetails && currentUserDetails.UserProDetails.details && currentUserDetails.UserProDetails.details.payment_plan && currentUserDetails.UserProDetails.details.payment_plan.includes("monthly") && activeStepGet === 3){
        // setActiveStep(5);
        navigate(location.pathname+"?activstep=5");

      }else{
        setActiveStep(parseInt(activeStepGet)+1);
      }
      /* if (location.pathname.split('/').length === 4) {
        navigate(location.pathname);
      } */
    }
  }

  async function updateAnnouncePayment(bodyData) {
    console.log('bodyData',bodyData)
    try {
      const getResponse = await dispatch(
        announceActions.updateSuccessPayment(bodyData)
      );
      console.log('getResponse',getResponse);
      if (getResponse.success === true) {
        setAnnounceDetails(getResponse);
        const announce = {
          data: getResponse.data,
          announce_id: bodyData.announce_id,
        };
        dispatch({ type: announceConstants.CREATE_SUCCESS, announce });
      }
      return getResponse;
    } catch (error) {
      return { success: false, data: "" };
    }
  }

  async function getAnnounceDetails(announce_id) {
    setLoadingFullpage(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/announce/announce/${announce_id}`
      );
      setLoadingFullpage(false);
      if (response.data.sucess === true) {
        /* if (
          parseInt(response.data.data.status) === 3 ||
          response.data.data.length <= 0
        ) {
          navigate("/");
        } */
        setAnnounceDetails(response.data);
        const announce = {
          data: response.data.data,
          announce_id: response.data.data.id,
        };
        dispatch({ type: announceConstants.CREATE_SUCCESS, announce });

        let announceDetailsGet = response.data.data;
        announceDetailsGet.votre_annonce_json.announce_id =response.data.data.id;
        // announceDetailsGet.votre_annonce_json.title = ads ? "" : "";
        setFormData(announceDetailsGet.votre_annonce_json);
      }
      return { success: true, data: response.data };
    } catch (error) {
      setLoadingFullpage(false);
      return { success: false, data: "" };
    }
  }
  function setPaymentDataFunc(data){
    setPaymentData(data);
  }
  return (
    <>
      {loadingFullpage ? <PageLoaderComp /> : ""}
      {successful ? (
        <ToastMessageComp message={alert.message} type={alert.type} />
      ) : (
        ""
      )}

      <div className="md:max-w-[1477px] relative w-full h-full m-auto py-7 px-4 sm:px-14 myForm">
        <Breadcrumb Accueil="Accueil" />
        <div className="relative bg-mainBg my-4 sm:my-12 ">
          <div className="grid grid-cols-6 gap-5">
            <div className="mb-8 col-span-12 2lg:col-span-8">
              <div className="hidden sm:inline-flex bg-white mb-6 items-center justify-between w-full max-w-[974px] h-[81px] rounded-md xl:px-12">
                <Stepper
                  activeStep={
                    queryParameters.get("activstep")
                      ? queryParameters.get("activstep")
                      : activeStep
                  }
                  className="w-full"
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>
                        <div className="inline-flex items-center justify-between w-full">
                          <div className="max-w-[118px] text-black-light font-normal">
                            {label}
                          </div>
                        </div>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
              <div
                className="bg-white max-w-[974px] rounded-xl"
                style={{ border: "0.5px solid #DEE3E2" }}
              >
                {/* {queryParameters.get("activstep") && parseInt(queryParameters.get("activstep")) === steps.length ? (
                          <Typography sx={{ mt: 2, mb: 1 }}>
                            <FormSuccess announceDetails={announceDetails} loadingFullpage={loadingFullpage} />
                          </Typography>
                        ) : ( */}

                {
                   queryParameters.get("activstep")  && (parseInt(queryParameters.get("activstep")) ===  5)?
                   <Typography sx={{ mt: 2, mb: 1 }}>
                   <FormSuccessAdmin user_id={user_id} annonce_id={annonce_id} announceDetails={announceData} loadingFullpage={loadingFullpage} />
                   </Typography>
                     
                 : 
                 <>
                  <Typography>
                    
                    {
                      activeStep === 0?
                      <Modellibre
                        formData={formData}
                        handleChange={handleChange}
                        handleChangeDate={handleChangeDate}
                        isSubmitted={isSubmitted}
                        
                      />
                      :""
                    }
                    {
                      activeStep === 1?
                      // "FormSummaryAdmin"
                      <FormSummaryAdmin handleBack={handleBack} user_id={user_id} annonce_id={annonce_id}  />
                      :""
                    }
                    {
                      activeStep === 2?
                      <CompleteAddressAdmin handleBack={handleBack} user_id={user_id} annonce_id={annonce_id} currentUserDetails={currentUserDetails} setUserSelectedAddress={setUserSelectedAddress} userSelectedAddress={userSelectedAddress}  />
                      
                      :""
                    }
                    {
                      activeStep === 3?
                      <PaymentFormAdmin handleBack={handleBack} user_id={user_id} annonce_id={annonce_id} currentUserDetails={currentUserDetails} setPaymentDataFunc={setPaymentDataFunc} isCheckedSerenity={isCheckedSerenity} setIscheckedSerenity={setIscheckedSerenity} setis_general_conditions_sale={setis_general_conditions_sale} is_general_conditions_sale={is_general_conditions_sale} general_conditions_sale_msg={general_conditions_sale_msg} />
                      :""
                    }
                    {
                      activeStep === 4?
                      <Elements options={options} stripe={stripePromise} > 
                        <PaymentSecondAdmin handleBack={handleBack} user_id={user_id} annonce_id={annonce_id} currentUserDetails={currentUserDetails}  ref={childRef} payamentPrice={payamentPrice} setLoading={setLoading} /> 
                      </Elements>
                      :
                      ""
                    
                    }
                    
                      {/* <PaymentSecondAdmin handleBack={handleBack} user_id={user_id} annonce_id={annonce_id} currentUserDetails={currentUserDetails}  ref={childRef} payamentPrice={payamentPrice} setLoading={setLoading}  /> */}
                      
                    
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    {
                      activeStep !== 0?
                      <Button
                        className="w-48 h-12 m-4 rounded-md bg-secondary font-extrabold text-base sm:text-lg 3xl:text-xl text-white transition-all duration-300 hover:bg-thColor hover:btnShadow capitalize"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        {"Précédent"}
                      </Button>
                      :""
                    }                  
                    <Box sx={{ flex: "1 1 auto" }} />
                    <button
                      disabled={loading}
                      style={{ opacity: loading ? "0.7" : "" }}
                      onClick={(e) => activeStep === steps.length - 1 ? childRef.current.handleSubmitCheckout():handleNext(e,activeStep)}
                      /* onClick={(e) => {
                        handleSubmit(e);
                      }} */
                      className="w-48 h-12 m-4 rounded-md bg-thColor font-extrabold text-base sm:text-lg 3xl:text-xl text-white transition-all duration-300 hover:bg-secondary hover:btnShadow capitalize"
                    >
                      {/* {
                        activeStep === 0?
                        "Suivant"
                        :"Obtenir le tarif"
                      }  */}
                      {"Obtenir le tarif"}
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      ) : (
                        ""
                      )}
                    </button>
                  </Box>
                  </>      
                }
              </div>
            </div>

            {/* <div className=' col-span-6 2lg:col-span-4'>
                <Procedures />
                <Express />
                <PaymentSideBlock />
              </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
