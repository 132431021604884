import React from 'react'
import { Link } from 'react-router-dom'

export default function Actualites() {
  return (
    <>
      <div className='md:max-w-[1477px] relative w-full h-full m-auto pb-24 px-5 lg:px-28 '>
        <div className='grid grid-cols-1 md:grid-cols-2 items-start justify-between gap-y-12 gap-x-6 lg:gap-12 sm:mt-24'>
          <div className='border-[3px] border-thColor rounded-[30px] pt-12 max-w-[663px] h-full md:h-[500px] overflow-hidden'>
            <div className='px-6'>
              <h2 className='text-2xl xl:text-3xl font-black text-primary'>Actualités</h2>
              <span className='w-[134px] h-[7px] bg-thColor rounded-[20px] block mt-3'></span>
            </div>
            <div className='mt-6 ms:mt-12 overflow-y-scroll relative block h-[330px] actualites px-2 mx-4'>

              <h5 className='text-lg sm:text-xl font-semibold text-primary mb-5'>Publication dans un journal d'annonces légales (JAL) : comment faire ?</h5>
              <p className='text-base font-light text-gray'>Vérifié le 28 août 2019 - Direction de l'information légale et administrative (Premier ministre), Ministère chargé de la justice La publication de démarches administratives doit <Link to="" className='text-thColor font-bold'>Lire la suite</Link></p>
              <h5 className='text-xl font-semibold text-primary mt-12  mb-5'>Déclaration au journal officiel</h5>
              <p className='text-base font-light text-gray'>La vie des entreprises doit obligatoirement être renseignée par la transmission des informations au BODACC. Qu'il s'agisse de création de sociétés de type SAS, SARL, SCI, EURL, SNC, SA <Link to="" className='text-thColor font-bold'>Lire la suite</Link> </p>
              <h5 className='text-xl font-semibold text-primary mt-12  mb-5'>Déclaration au journal officiel</h5>
              <p className='text-base font-light text-gray'>La vie des entreprises doit obligatoirement être renseignée par la transmission des informations au BODACC. Qu'il s'agisse de création de sociétés de type SAS, SARL, SCI, EURL, SNC, SA <Link to="" className='text-thColor font-bold'>Lire la suite</Link> </p>
              <h5 className='text-xl font-semibold text-primary mt-12  mb-5'>Déclaration au journal officiel</h5>
              <p className='text-base font-light text-gray'>La vie des entreprises doit obligatoirement être renseignée par la transmission des informations au BODACC. Qu'il s'agisse de création de sociétés de type SAS, SARL, SCI, EURL, SNC, SA <Link to="" className='text-thColor font-bold'>Lire la suite</Link> </p>
            </div>
          </div>
          <div className='border-[3px] border-secondary rounded-[30px] pt-12 max-w-[663px] h-full md:h-[500px]'>
            <div className='px-6'>
              <h2 className='text-2xl xl:text-3xl font-black text-secondary'>Guide</h2>
              <span className='w-[134px] h-[7px] bg-secondary rounded-[20px] block mt-3'></span>
            </div>
            <div className='mt-12 overflow-y-scroll relative block h-[330px] actualites px-2 mx-4'>
              <h5 className='text-xl font-semibold text-primary  mb-5'>La SAS : à quoi correspond ce statut ?</h5>
              <p className='text-base font-light text-gray'>La société par actions simplifiée ou SAS est un statut juridique d’une société commerciale. En une vingtaine d’années d’existence, la SAS est devenue la deuxième forme de <Link to="" className='text-secondary font-bold'>Lire la suite</Link></p>
              <h5 className='text-xl font-semibold text-primary mt-12 mb-5'>Différences entre la location-gérance et les autres contrats</h5>
              <p className='text-base font-light text-gray'>Mis à jour le 9 novembre 2019 <Link to="" className='text-secondary font-bold'>Lire la suite</Link> </p>
              <h5 className='text-xl font-semibold text-primary mt-12 mb-5'>Différences entre la location-gérance et les autres contrats</h5>
              <p className='text-base font-light text-gray'>Mis à jour le 9 novembre 2019 <Link to="" className='text-secondary font-bold'>Lire la suite</Link> </p>
              <h5 className='text-xl font-semibold text-primary mt-12 mb-5'>Différences entre la location-gérance et les autres contrats</h5>
              <p className='text-base font-light text-gray'>Mis à jour le 9 novembre 2019 <Link to="" className='text-secondary font-bold'>Lire la suite</Link> </p>
              <h5 className='text-xl font-semibold text-primary mt-12 mb-5'>Différences entre la location-gérance et les autres contrats</h5>
              <p className='text-base font-light text-gray'>Mis à jour le 9 novembre 2019 <Link to="" className='text-secondary font-bold'>Lire la suite</Link> </p>
              <h5 className='text-xl font-semibold text-primary mt-12 mb-5'>Différences entre la location-gérance et les autres contrats</h5>
              <p className='text-base font-light text-gray'>Mis à jour le 9 novembre 2019 <Link to="" className='text-secondary font-bold'>Lire la suite</Link> </p>
              <h5 className='text-xl font-semibold text-primary mt-12 mb-5'>Différences entre la location-gérance et les autres contrats</h5>
              <p className='text-base font-light text-gray'>Mis à jour le 9 novembre 2019 <Link to="" className='text-secondary font-bold'>Lire la suite</Link> </p>
            </div>

          </div>

        </div>
      </div>
    </>
  )
}
