import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import "./adminStyle.css";
import LogoWhite from "../../assets/BusyWhite.svg";
import LogoWhiteWebp from "../../assets/BusyWhite.webp";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, alertActions } from "../../_actions";
import ToastMessageComp from "../../component/ToastMessageComp";

export default function Topbar({ hendlemenuOpen }) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const alert = useSelector((state) => state.alert);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [successful, setSuccessful] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const logoutHandler = () => {
    setSuccessful(true);
    dispatch(alertActions.success("Vous avez été déconnecté avec succès !"));
    dispatch(adminActions.logout());
    setTimeout(() => {
      setSuccessful(false);
      navigate("/admin/login");
    }, 1000);
  };
  const currentAdmin = useSelector((state) => state.administrator.admin);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      sx={{ mt: "45px" }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem component={Link} to="/admin/profile" onClick={handleMenuClose}>
        Profile
      </MenuItem>

      <MenuItem
        onClick={(e) => {
          handleMenuClose();
          logoutHandler();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      sx={{ mt: "45px" }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem component={Link} to="/admin/profile"   onClick={handleMobileMenuClose} >
        
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={(e) => {
          handleMobileMenuClose();
          logoutHandler();
        }}>
       
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
    {successful?
      <ToastMessageComp  message={alert.message} type={alert.type} />
      :""}

    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="AppBg">
        <Toolbar sx={{ minHeight: "95px", py: "15px" }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { sm: "block" } }}
          >
            <Link to={"/admin/home"}>
              {/* <img src={LogoWhite} alt="logo" className='w-32'  /> */}
              <picture>
                <source type="image/webp" srcSet={LogoWhiteWebp} />
                <img
                  src={LogoWhite}
                  alt="busyplace_logo"
                  className="w-32"
                  style={{ maxWidth: "100%", height: "fit-content" }}
                />
              </picture>
            </Link>
          </Typography>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="open drawer"
            sx={{ ml: 2 }}
            onClick={() => hendlemenuOpen()}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
              &nbsp;{" "}
              <Typography>
                {currentAdmin && currentAdmin.data
                  ? currentAdmin.data.username
                  : ""}
              </Typography>
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
    </>
  );
}
