import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./component/Sidebar";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

export default function Dashboard() {
  const [menuOpen] = useOutletContext();
  let navigate = useNavigate();
  const [columns, setColumns] = useState([
    { id: "firstname", label: "First Name", minWidth: 170 },
    { id: "lastname", label: "Last Name", minWidth: 100 },
    { id: "email", label: "Email" },
    // { id: 'action', label: 'Action' },
  ]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#fff",
      color: "#797979",
      fontSize: "16px",
      fontWeight: "400",
    },
  }));

  /* 
  function createData(fname, lname, email, action) {
    return { fname, lname, email, action };
  }

  const rows = [
    createData('Manash', 'Patidar', 'manash@kifwat.com', 'Active'),
    createData('Manash', 'Patidar', 'manash@kifwat.com', 'Active'),
    createData('Manash', 'Patidar', 'manash@kifwat.com', 'Active'),
    createData('Manash', 'Patidar', 'manash@kifwat.com', 'Active'),

  ]; */

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  async function getAllUsers() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/user`
      );

      setRows(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      legals.fr
    </Link>,

    <Typography key="3">Tableau de bord</Typography>,
  ];
  const currentAdmin = useSelector((state) => state.administrator.admin);

  if (!currentAdmin) {
    return <Navigate to="/admin/login" />;
  }
  return (
    <div className="w-full h-full bg-white p-8 rounded-[20px]">
      <Typography
        variant="h4"
        component="h4"
        fontWeight={500}
        color={"#1CBE93"}
      >
        Tableau de bord
      </Typography>
      <Stack
        spacing={2}
        mb={3}
        pb={3}
        sx={{ borderBottom: "0.5px solid rgb(222, 227, 226)" }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Typography
        variant="h6"
        component="h6"
        fontWeight={400}
        mb={1}
        color={"#27295B"}
      >
        All User
      </Typography>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            className="text-base font-normal text-primary whitespace-nowrap"
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
