import React from 'react';
import ReactDOM from 'react-dom/client';
import { init as initApm } from '@elastic/apm-rum';
import './index.css';
import './App.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Provider } from 'react-redux';
import { store } from './_helpers';
import { HelmetProvider } from 'react-helmet-async';

// Initialize APM
try {
  const apm = initApm({
    serviceName: process.env.REACT_APP_APM_SERVICE_NAME,
    environment: process.env.REACT_APP_APM_ENVIRONMENT,
    serverUrl: process.env.REACT_APP_APM_SERVER_URL,
    serviceVersion: '1.0.0',
    logLevel: 'info',
    breakdownMetrics: true,
    transactionSampleRate: 1.0,
  });
  apm.startTransaction("App Load", "app-load");
} catch (error) {
  console.error("Failed to initialize APM:", error);
}

AOS.init();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);

