import React,{useState,useEffect} from 'react'
import { useNavigate,useLocation } from 'react-router-dom'

import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'

import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField} from '@mui/material'

import ToastMessageComp from '../component/ToastMessageComp';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions,adminActions} from '../_actions';

import logo from '../BusyLogo.svg'
import logoWebP from '../BusyLogo.webp'

export default function Login() {
  let navigate = useNavigate();
  const location = useLocation();
  const alert = useSelector(state => state.alert);
  const dispatch = useDispatch(); 
  
  let remember_email = localStorage.getItem('remember_email');
  let remember_password = localStorage.getItem('remember_password');
  let is_remember = localStorage.getItem('is_remember');

  const [user, setUser] = useState({
    username: "",
    password: "",
    
  });
  const [submitted, setSubmitted] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  useEffect(()=>{
  },[])
  
  function handleChange(e) {
    const { name, value } = e.target;
    setUser(user => ({ ...user, [name]: value }));
  }
  async function handleSubmit(e) {
    e.preventDefault();
    setSuccessful(false);
    setSubmitted(true);
    // const { from } = location.state || { from: { pathname: "/" } };
    if (user.username && user.password) {
      dispatch(adminActions.login(user)).then((response) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        setSuccessful(true);   
        if(response.sucess === true){
          if(response.data.twoFactorEnabled)
          {
            navigate(`/otp-vérifier/${response.data.twoFactorEnabled}`)          
          }else{
            dispatch(alertActions.success(response.msg));
            localStorage.setItem('admin', JSON.stringify(response));
            setTimeout(() => {           
              navigate("/admin")          
            }, 1000);  
          }
        }else
        {
            dispatch(alertActions.error(response.msg));
            // navigate(-1)       
        }       
      
        
      })
      .catch((err) => {
        
        setSuccessful(true);
        // dispatch(alertActions.error("Somthing went wrong!"));
      });
    }
}
 
 
 
  
  const currentAdmin = useSelector(state => state.administrator.admin);
  /*
  if (currentAdmin && !(successful)) {
    return <Navigate to="/admin" />;
  }  */
   
  return (
    <>
      {successful?
      <ToastMessageComp  message={alert.message} type={alert.type} />
      :""}
     
      <form className='' onSubmit={handleSubmit}>
        <div className='bg-[#F5F5F5] w-full h-screen py-7 3xl:py-12'>
          <div className='md:max-w-[1477px] relative w-full h-full max-h-[684px] flex items-center justify-center m-auto  '>

            {/* <div className='max-w-[640px] 3xl:max-w-[720px]  w-full h-full bg-white px-5 sm:px-12 py-8 sm:py-5 3xl:p-12 ' style={{ boxShadow: "0px 4px 10px 1px rgba(39, 41, 91, 0.35)", borderRadius: "33px" }}> */}
            <div className='max-w-[640px] sm:max-w-[720px] w-full h-full bg-white px-5 sm:px-12 py-8 sm:py-5 xl:p-12 shadow-md rounded-[33px]'>
              <div className='flex items-center px-2 justify-around gap-14 py-8 3xl:py-6'>
                {/* <img src={logo} alt="logo" width='300px'/> */}
                <picture>
    <source type="image/webp" srcSet={logoWebP} />
    <img src={logo} alt="busyplace_logo" style={{ maxWidth: '100%', width:"170px", maxHeight: 'auto', height:'fit-content' }} />
  </picture>
                </div>   
              <div className='flex items-center px-2 justify-around gap-14 mt-8 py-2 3xl:py-4'>
               
                <h3 className='text-2xl sm:text-3xl 3xl:text-4xl font-semibold border-b-[6px] border-primary py-4 text-primary '>Log in to continue to admin</h3>

              </div>
              
                <div className='my-8 block'>
                  <TextField
                    // disableUnderline
                    sx={{
                      "& .MuiInputLabel-root": { color: '#797979', },
                      "& fieldset": { border: '1px solid #eee' },
                    }}
                    InputProps={{
                      style: {
                        borderRadius: '8px',
                        color: '#797979',
                        background: 'rgb(39 41 91 / 6%)',
                      }
                    }
                    }
                    className='w-full'
                    label="username" variant="outlined" type="username" error={submitted && !user.username ? true : false} name="username" value={user.username} onChange={handleChange}  />
                </div>

                <div className='my-4 block'>
                  <div className='my-4 block'>
                    <FormControl className='w-full' variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password" sx={{ color: submitted && !user.password ? '#d32f2f' :  '#797979', }} >Mot de passe</InputLabel>
                      <OutlinedInput
                        error={submitted && !user.password ? true : false} name="password" value={user.password} onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        sx={{
                          "& .MuiInputLabel-root": { color:  submitted && !user.password ? '#d32f2f' :  '#797979', },
                          "& fieldset": { border: '1px solid #eee' },
                          background: 'rgb(39 41 91 / 6%)',
                          color:  submitted && !user.password ? '#d32f2f' :  '#797979',
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <AiFillEye fill='#CFCFCF' /> : <AiFillEyeInvisible fill='#CFCFCF' />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Mot de passe"
                      />
                    </FormControl>
                  </div>
                </div>
                <button type='submit' className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white  mt-4 sm:mt-4
                3xl:mt-10 px-6 w-full py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white'>Se connecter</button>

               
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
