import React,{useState,useEffect} from 'react';
import { Link,useNavigate,useLocation,useHistory ,useParams,useSearchParams,Navigate } from 'react-router-dom'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Box, Stack, Typography } from '@mui/material'
import no_image from '../assets/no_image.png'
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ToastMessageComp from './ToastMessageComp';
import PageLoaderComp from './pageLoaderComp';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import { alertActions } from '../_actions';

export default function MessageProFormComp(props) {
    // console.log("props",props)
    
    const currentUser = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();
    // const location = useLocation(); 
    // let navigate = useNavigate();
    // let { ads,adsname,id } = useParams();
    const [open, setOpen] = useState(false);
    const [logo, setLogo] = useState(no_image);
    const [isLoading, setIsLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        "user_id":currentUser && currentUser.data?currentUser.data.id:"",
        "directory_type":props.type,
        "directory_user_id":(props.singleList&& props.singleList._source && props.singleList._source.claim_json_parse?props.singleList._source.claim_json_parse.user_id:"") || props.singleList && props.singleList.claim_json_parse?props.singleList.claim_json_parse.user_id:"",
        "directory_id":props.singleList?props.singleList.id:"",
        "society":props.singleList?props.singleList.name:"",
        "prenom":currentUser && currentUser.data?currentUser.data.lastname:"",
        "nom":currentUser && currentUser.data?currentUser.data.firstname:"",
        "address":currentUser && currentUser.data&& currentUser.data.address && currentUser.data.address !== "undefined"?currentUser.data.address:"",
        "code_postal":currentUser && currentUser.data?currentUser.data.postalcode:"",
        "ville":currentUser && currentUser.data?currentUser.data.ville:"",
        "ville_id":currentUser && currentUser.data?currentUser.data.ville_id:"",
        "villeArr":currentUser && currentUser.data&& currentUser.data.villeArr?JSON.parse(currentUser.data.villeArr):[],
        "telephone":currentUser && currentUser.data && currentUser.data.telephone && currentUser.data.telephone !== "null"?currentUser.data.telephone:"",
        "email":currentUser && currentUser.data?currentUser.data.email:"",
        "message":"",
        "term_conditions":false
    });
    useEffect(() => {
        window.scrollTo(0, 0)        
        if(currentUser && currentUser.data){
            // setFormData(formData=>({...formData,["user_id"]:currentUser.data.id}));
            setFormData({
                "user_id":currentUser && currentUser.data?currentUser.data.id:"",
                "directory_type":props.type,
                "directory_id":props.singleList?props.singleList.id:"",
                "directory_user_id":(props.singleList&& props.singleList._source && props.singleList._source.claim_json_parse?props.singleList._source.claim_json_parse.user_id:"") || props.singleList && props.singleList.claim_json_parse?props.singleList.claim_json_parse.user_id:"",
                "society":props.singleList?props.singleList.name:"",
                "prenom":currentUser && currentUser.data?currentUser.data.lastname:"",
                "nom":currentUser && currentUser.data?currentUser.data.firstname:"",
                "address":currentUser && currentUser.data&& currentUser.data.address && currentUser.data.address !== "undefined"?currentUser.data.address:"",
                "code_postal":currentUser && currentUser.data?currentUser.data.postalcode:"",
                "ville":currentUser && currentUser.data?currentUser.data.ville:"",
                "ville_id":currentUser && currentUser.data?currentUser.data.ville_id:"",
                "villeArr":currentUser && currentUser.data&& currentUser.data.villeArr?JSON.parse(currentUser.data.villeArr):[],
                "telephone":currentUser && currentUser.data && currentUser.data.telephone && currentUser.data.telephone !== "null"?currentUser.data.telephone:"",
                "email":currentUser && currentUser.data?currentUser.data.email:"",
                "message":"",
                "term_conditions":false
            })
        }   
    }, [])
    useEffect(() => {/* 
        console.log(props.singleList)
        console.log("currentUser",currentUser) */
        setOpen(props.isOpen); 
        if(props.singleList){
            let cleanedStr = "";
            let array = [];
            let url =no_image;
            if(props.singleList.logo && props.singleList.logo){
              cleanedStr = props.singleList.logo.replace(/^\[('|")?|('|")?\]$/g, "");
              array = cleanedStr.split(",");
              url = array[0].trim();
            }
            setLogo(url);

            setFormData({
                "user_id":currentUser && currentUser.data?currentUser.data.id:"",
                "directory_type":props.type,
                "directory_id":props.singleList?props.singleList.id:"",
                "directory_user_id":(props.singleList&& props.singleList._source && props.singleList._source.claim_json_parse?props.singleList._source.claim_json_parse.user_id:"") || props.singleList && props.singleList.claim_json_parse?props.singleList.claim_json_parse.user_id:"",
                "society":props.singleList?props.singleList.name:"",
                "prenom":currentUser && currentUser.data?currentUser.data.lastname:"",
                "nom":currentUser && currentUser.data?currentUser.data.firstname:"",
                "address":currentUser && currentUser.data&& currentUser.data.address && currentUser.data.address !== "undefined"?currentUser.data.address:"",
                "code_postal":currentUser && currentUser.data&& currentUser.data.postalcode && currentUser.data.postalcode !== "null"?currentUser.data.postalcode:"",
                "ville":currentUser && currentUser.data?currentUser.data.ville:"",
                "ville_id":currentUser && currentUser.data?currentUser.data.ville_id:"",
                "villeArr":currentUser && currentUser.data&& currentUser.data.villeArr?JSON.parse(currentUser.data.villeArr):[],
                "telephone":currentUser && currentUser.data && currentUser.data.telephone && currentUser.data.telephone !== "null"?currentUser.data.telephone:"",
                "email":currentUser && currentUser.data?currentUser.data.email:"",
                "message":"",
                "term_conditions":false
            })
        }
    }, [props])
    const handleClickOpen = () => {
      setOpen(true);
      props.setIsOpen(true);
      props.setSuccessfull(false);
      
    };
    
    const handleClose = () => {
      setOpen(false);
      props.setIsOpen(false);
    //   props.setSuccessfull(false);
    };
    

    const handleChange =async (e) => {
        // console.log("e",e)
        let {name, value} = e.target;
        // setIsLoading(true)
        if(name === "term_conditions"){
            setFormData(formData=>({...formData,[name]:e.target.checked}));
        }else{
            setFormData(formData=>({...formData,[name]:value}));
        }
       
        if(name === "code_postal"){    
            await getCityFromPostalType(name,value); 
        } 
    };
    const handleSubmit = (e) => {
        
        // handleClose()
        setSubmitted(true);
        // console.log("formdata",formData)
        if(formData.prenom && formData.nom  && formData.code_postal  && formData.ville  && formData.email  && formData.address && formData.term_conditions){
            setIsLoading(true);            
            fetch(process.env.REACT_APP_API_URL+`api/announce/create_message`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
            })
            .then((res) => res.json())
            .then((response) => {
                console.log("response",response)
                props.setSuccessfull(true);
                if(response.sucess){                   
                    dispatch(alertActions.success("Message créé avec succès !")); 
                }else{
                    dispatch(alertActions.error("quelque chose s'est mal passé !"));  
                }
               
                setTimeout(()=>{
                    setIsLoading(false);
                    setSubmitted(false);
                    setOpen(false);
                    props.setIsOpen(false);
                    props.setSuccessfull(false);
                    
                },1000)
                
            });
        }
    };

    async function getCityFromPostalType(name,value){   
        const getCity = setTimeout(async() => {
            setFormData(formData => ({ ...formData, ["ville"]: "",["villeArr"]:[],["ville_id"]: ""  }));    
            if(value.length>0){
              const response = await axios.get(`${process.env.REACT_APP_API_URL}api/checkzipcode/${value}`);        
              if( response.data &&  response.data.data){
                setFormData(formData => ({ ...formData, ["ville"]: response.data.data.city,["villeArr"]:response.data.data.cityArr,["ville_id"]: response.data.data.id }));
              }
            }          
          
        }, 1000);
        return () => clearTimeout(getCity);
      }
      console.log("props",props);
  return (
    <div>
    <React.Fragment>
       {/*  {isLoading?
        <PageLoaderComp />
        :""} */}        
        
        {/* <Button variant="outlined" onClick={handleClickOpen}>
            Open form dialog
        </Button> */}
        <Dialog open={props.isOpen} onClose={handleClose}>
            <DialogTitle>Message</DialogTitle>
            <DialogContent>
            <div className='w-full mx-auto'>                
                <div className='flex flex-col sm:flex-row items-center gap-4 mb-4' style={{"display": "flex","alignItems": "center"}}>
                    <img src={logo} alt="logo" style={{"width":"100px","borderRadius":"10px"}} />
                    <div  style={{"padding":"0 10px"}} >
                       {/*  <Typography variant='h5' sx={{ fontWeight: '600', color: '#414141' }}>{props.singleList && (props.type==="accountant"? (props.singleList._source.name):(props.type==="avocats"?props.singleList._source.avNom+' '+props.singleList._source.avPrenom:(props.type==="notaires"?props.singleList._source.name:"")))}</Typography> */}

                        {/* <h4 style={{"fontWeight":"bold"}} className='font-bold text-lg 3xl:text-xl text-black'>{props.singleList && (props.type==="accountant"? (props.singleList.name):(props.type==="avocats"?props.singleList.avnom+' '+props.singleList.avprenom:(props.type==="notaires"?props.singleList.name:"")))}</h4> */}
                        {
                        props.singleList && props.singleList._source?   
                            <>
                            <Typography variant='h5' sx={{ fontWeight: '600', color: '#414141' }}>{props.singleList && (props.type==="accountant"? (props.singleList._source.name):(props.type==="avocats"?props.singleList._source.avnom+' '+props.singleList._source.avprenom:(props.type==="notaires"?props.singleList._source.name:"")))}</Typography>
                            <h6 className='text-base font-semibold text-black'>{props.singleList && (props.type==="accountant"? (props.singleList._source.addresslocality+" ("+props.singleList._source.postalcode+")") :(props.type==="avocats"?props.singleList._source.cbAdresse1+' '+props.singleList._source.cbAdresse2+' '+props.singleList._source.cbVille+', '+props.singleList._source.cbCp:(props.type==="notaires"?props.singleList._source.name:"")))}</h6>
                            </>                       
                           
                        :
                            <>
                             <Typography variant='h5' sx={{ fontWeight: '600', color: '#414141' }}>{props.singleList && (props.type==="accountant"? (props.singleList.name):(props.type==="avocats"?props.singleList.avnom+' '+props.singleList.avprenom:(props.type==="notaires"?props.singleList.name:"")))}</Typography>
                            <h6 className='text-base font-semibold text-black'>{props.singleList && (props.type==="accountant"? (props.singleList.addresslocality+" ("+props.singleList.postalcode+")") :(props.type==="avocats"?props.singleList.cbAdresse1+' '+props.singleList.cbAdresse2+' '+props.singleList.cbVille+', '+props.singleList.cbCp:(props.type==="notaires"?props.singleList.name:"")))}</h6></>
                        }


                        {/* <p className='text-base font-light text-black'>Expert-comptable -  Commissaire aux comptes</p> */}
                        {/* <div className='flex items-center gap-4'>
                            <p className='text-[12px] font-normal text-black'>N° Ordre des Experts-Comptables </p>
                            <p className='text-[12px] font-normal text-black'>14-01305000-01</p>
                        </div>
                        <div className='flex items-center gap-4'>
                            <p className='text-[12px] font-normal text-black'>N° Compagnie des Commissaires aux Comptes </p>
                            <p className='text-[12px] font-normal text-black'>66005840</p>
                        </div> */}
                    </div>
                </div>

                <Typography variant='h6' sx={{ fontWeight: '600', color: '#414141' }}> Informations perso nnelles</Typography>
                <Typography variant='body1' sx={{ fontWeight: '300', color: '#414141' }}>Pour des raisons de transparence, vos données seront envoyées au professionnel. Vos données ne seront pas utilisées sans votre consentement.</Typography>
                <Box
                    sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& > :not(style)': { m: 1 },
                    }}
                >
                    <TextField
                        helperText=""
                        id="demo-helper-text-aligned"
                        label="Société"
                        name='society'
                        style={{width:"100%"}}
                        onChange={handleChange}
                        value={formData.society}
                    />
                        
                </Box>
                <Box
                    sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& > :not(style)': { m: 1 },
                    }}
                >
                    <TextField
                        helperText=""
                        value={formData.prenom}
                        error={(submitted && (!formData.prenom)) ?true:false}
                        id="demo-helper-text-aligned"
                        label="Prénom*"
                        name='prenom'
                        style={{width:"100%"}}
                        onChange={handleChange}
                    />
                        
                </Box>
                <Box
                    sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& > :not(style)': { m: 1 },
                    }}
                >
                    <TextField
                        helperText=""
                        error={(submitted && !formData.nom) ?true:false}
                        id="demo-helper-text-aligned"
                        label="Nom*"
                        name='nom'
                        style={{width:"100%"}}
                        onChange={handleChange}
                        value={formData.nom}
                    />
                        
                </Box>
                <Box
                    sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& > :not(style)': { m: 1 },
                    }}
                >
                    <TextField
                        helperText=""
                        error={(submitted && (!formData.address)) ?true:false}
                        id="demo-helper-text-aligned"
                        label="Address*"
                        name='address'
                        style={{width:"100%"}}
                        onChange={handleChange}
                        value={formData.address}
                    />
                        
                </Box>
                <Box
                    sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& > :not(style)': { m: 1 },
                    }}
                >
                    <TextField
                        helperText=""
                        error={(submitted && !formData.code_postal) ?true:false}
                        id="demo-helper-text-aligned"
                        label="Code postal*"
                        name='code_postal'
                        style={{width:"100%"}}
                        onChange={handleChange}
                        value={formData.code_postal}
                    />
                        
                </Box>
                <Box
                    sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& > :not(style)': { m: 1 },
                    }}
                >
                    {/* <TextField
                        helperText=""
                        // error={true}
                        id="demo-helper-text-aligned"
                        label="Ville*"
                        style={{width:"100%"}}
                    /> */}
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Ville*</InputLabel>
                        <Select
                            helperText=""
                            error={(submitted && !formData.ville_id) ?true:false}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.ville_id}
                            label="Ville"
                            name='ville_id'
                            onChange={handleChange}
                            >
                            {
                                formData.villeArr.map((cityVal,cityIndex)=>{
                                return(<MenuItem key={cityIndex} value={cityVal.id}>{cityVal.city}</MenuItem>)
                                })
                            }
                        </Select>
                    </FormControl>
                        
                </Box>
                <Box
                    sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& > :not(style)': { m: 1 },
                    }}
                >
                    <TextField
                        helperText=""
                        id="demo-helper-text-aligned"
                        label="Téléphone"
                        name='telephone'
                        // error={(submitted && !formData.telephone) ?true:false}
                        style={{width:"100%"}}
                        onChange={handleChange}
                        value={formData.telephone}
                    />
                        
                </Box>
                <Box
                    sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& > :not(style)': { m: 1 },
                    }}
                >
                    <TextField
                        helperText=""
                        // error={true}
                        id="demo-helper-text-aligned"
                        label="E-mail*"
                        name='email'
                        error={(submitted && !formData.email) ?true:false}
                        style={{width:"100%"}}
                        onChange={handleChange}
                        value={formData.email}
                    />
                        
                </Box>
                <Box
                    sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& > :not(style)': { m: 1 },
                    }}
                >
                    <TextField
                        helperText=""
                        id="demo-helper-text-aligned"
                        label="Votre message"
                        name='message'
                        style={{width:"100%"}}
                        onChange={handleChange}
                        value={formData.message}
                    />
                        
                </Box>
                <Box component="form" className='mt-5 sm:mt-8 3xl:mt-12'
                sx={{
                    "& .MuiInputLabel-root": { color: '#797979', },
                    "& fieldset": {
                    border: '1px solid #eee', borderRadius: '5px',
                    color: '#797979',
                    background: 'rgb(39 41 91 / 6%)',
                    },
                }}
                >
               
                <div className='flex items-center  mb-5 mt-8 sm:mt-8'>
                    <div className='flex items-center'>
                    <label className='flex gap-4'>
                        {/* <input className=" w-5 h-5 rounded-4xl block border-black-light" type="checkbox" checked={formData.term_conditions}  name='term_conditions'  onChange={handleChange}  /> */}
                        <Checkbox error={true} checked={formData.term_conditions} onChange={handleChange} name="term_conditions" />
                        <span className="text-base  font-normal text-primary">
                        J’accèpte les <Link to="/conditions-generales-vente" className='text-primary font-semibold underline'>conditions générales d’utilisation.</Link>
                        </span>
                        <FormHelperText style={{"padding":"5px 10px","color":"#d32f2f"}} hidden={formData.term_conditions === false && submitted?false:true}>veuillez accepter les termes et conditions !</FormHelperText>
                    </label>
                    </div>
                </div>
                <FormHelperText style={{"padding":"15px 10px","color":"#d32f2f"}}> * Champs obligatoires</FormHelperText>
                {/* <p><small> * Champs obligatoires</small></p> */}
                </Box>
            </div>
            
            </DialogContent>
            <DialogActions classes={"12"}>
                <LoadingButton
                    size="small"
                    onClick={handleClose}
                    // endIcon={<SendIcon />}
                    // loading={isLoading}
                    // loadingPosition="end"
                    variant="outlined"                    >
                    <span>Cancel</span>
                </LoadingButton>
               
                <LoadingButton
                    size="small"
                    onClick={handleSubmit}
                    endIcon={<SendIcon />}
                    loading={isLoading}
                    loadingPosition="end"
                    variant="contained"                    >
                    <span>Envoyer</span>
                </LoadingButton>
                 {/* <Button onClick={handleClose}>Cancel</Button> */}
                {/* <Button onClick={handleSubmit} loading>Envoyer</Button> */}
            </DialogActions>
        </Dialog>
    </React.Fragment>
    </div>
  );
}