 import React from 'react'
import Navbar from './Navbar'
import { Outlet } from 'react-router-dom'
import { useState } from 'react';
 
 export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const hendlemenuOpen = () => {
    setMenuOpen(!menuOpen);
  };
   return (
     <div>
      <Navbar  hendlemenuOpen={hendlemenuOpen} setMenuOpen={setMenuOpen} menuOpen={menuOpen}/>
      <Outlet context={[menuOpen, setMenuOpen, hendlemenuOpen]}/>
     </div>
   )
 }
 
