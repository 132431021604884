// import React from 'react';

// function NotFound() {
//   return (
//     <div style={{ fontFamily: 'Arial, sans-serif', margin: 0, padding: 0, backgroundColor: '#f0f0f0' }}>
//       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//         <div style={{ textAlign: 'center' }}>
//           <h1 style={{ fontSize: '100px', color: '#333', margin: 0 }}>404</h1>
//           <h2 style={{ fontSize: '36px', color: '#555', margin: '10px 0' }}>Page Not Found</h2>
//           <p style={{ fontSize: '18px', color: '#777', margin: '10px 0' }}>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
//           <a style={{ display: 'inline-block', fontSize: '20px', color: '#fff', backgroundColor: '#007bff', padding: '10px 20px', textDecoration: 'none', borderRadius: '5px', marginTop: '20px' }} href="/">Go back to home page</a>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default NotFound;


import React from 'react';

function NotFound() {
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', margin: 0, padding: 0, backgroundColor: '#f0f0f0' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div style={{ textAlign: 'center' }}>
          <h1 style={{ fontSize: '100px', color: '#333', margin: 0 }}>404</h1>
          <h2 style={{ fontSize: '36px', color: '#555', margin: '10px 0' }}>Page Not Found</h2>
          <p style={{ fontSize: '18px', color: '#777', margin: '10px 0' }}>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
          <a style={{ display: 'inline-block', fontSize: '20px', color: '#fff', backgroundColor: '#007bff', padding: '10px 20px', textDecoration: 'none', borderRadius: '5px', marginTop: '20px' }} href="/">Go back to home page</a>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
