/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect,useCallback } from 'react'
import { Link , useNavigate,Navigate  } from 'react-router-dom'
import Facebook01 from '../assets/Social/Facebook01.svg'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import Footer from '../component/Footer'
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField,Checkbox,FormControlLabel,FormHelperText,Select,MenuItem  } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';
import { alertActions } from '../_actions';
import ToastMessageComp from '../component/ToastMessageComp';
import SocialLoginComp from '../component/SocialLoginComp'
import { history } from '../_helpers';
import axios from 'axios';
import { isValidEmail,isAlphabetic,isNumericValue } from '../_helpers';

export default function Registration() {
  let navigate = useNavigate();
  const [pinCode, setPinCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    adresse: '',
    postalcode: '',
    ville: '',
    ville_id: '',
    villeArr: [],
    signup_type: 'normal',
    term_conditions:false,
  });
  const [submitted, setSubmitted] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  // const registering = useSelector(state => state.registration.registering);
  const alert = useSelector(state => state.alert);
  const dispatch = useDispatch(); 


  useEffect(() => {
      history.listen((location, action) => {
          // clear alert on location change
          dispatch(alertActions.clear());
      });
  }, []);

  useEffect(() => {
    const getData = setTimeout(async() => {
      setUser(user => ({ ...user, ["ville"]: "", ["ville_id"]: "", ["villeArr"]:[] }));      
      if(pinCode.length>0){
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/checkzipcode/${user.postalcode}`);
        
        if( response.data &&  response.data.data){
          setUser(user => ({ ...user, ["ville"]: response.data.data.city, ["ville_id"]: response.data.data.id, ["villeArr"]:response.data.data.cityArr }));
        }
      }      
    }, 1000)
    return () => clearTimeout(getData);
  }, [pinCode])

  function handleChange(e) {
    const { name, value } = e.target;

    if(e.target.name === "term_conditions"){
      setUser(user => ({ ...user, [name]: e.target.checked }));
    }else{
      setUser(user => ({ ...user, [name]: value }));
    }    
    if(name == "ville_id"){
      let filterCity = user.villeArr.filter((cityVal)=>cityVal.id===value);
      if(filterCity.length>0){
        setUser(user => ({ ...user, ["ville"]: filterCity[0].city, ["ville_id"]: filterCity[0].id }));
      }
    }
  }
  

  async function handleSubmit(e) {
      
      e.preventDefault();     
      setSuccessful(false);
      setSubmitted(true);
      if (user.firstname /* && isAlphabetic(user.firstname) */ && user.lastname /* && isAlphabetic(user.lastname) */ && user.email  && isValidEmail(user.email) && user.password && user.adresse && user.postalcode && user.ville && user.term_conditions === true) {
        setisLoading(true);
         dispatch(userActions.register(user)).then(() => {
          setSuccessful(true);          
          setisLoading(false);

          setTimeout(() => {
            navigate("/login");            
          }, 2000);
        })
        .catch(() => {
          setSuccessful(true);         
          setisLoading(false);
        });
      }else{
        //
      }
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const currentUser = useSelector(state => state.authentication.user);
  if (currentUser) {
    return <Navigate to="/" />;    
  }
  return (
    <>
      {successful?
      <ToastMessageComp  message={alert.message} type={alert.type} />
      :""}
      <div className='bg-[#F5F5F5] w-full py-7 3xl:py-12'>
        <div className='md:max-w-[1477px] relative w-full h-full   flex items-center justify-center m-auto  '>

          <div className='max-w-[640px] 3xl:max-w-[720px]  w-full h-full bg-white px-5 sm:px-12 py-2 sm:py-5 3xl:p-12 ' style={{ boxShadow: "0px 4px 10px 1px rgba(39, 41, 91, 0.35)", borderRadius: "33px" }}>
            <div className='flex items-center px-2 justify-around gap-14 py-2 3xl:py-4'>

             
              <Link to="/login" className='text-2xl sm:text-3xl 3xl:text-4xl border-b-[6px] border-white text-[#797979]  font-light hover:text-secondary hover:border-b-[6px] hover:border-secondary py-4'>Connexion </Link>
              <h3 className='text-2xl sm:text-3xl 3xl:text-4xl font-semibold border-b-[6px] border-primary py-4 text-primary '>Inscription</h3>


            </div>
            <form className='mt-5 sm:mt-8 3xl:mt-12' onSubmit={handleSubmit}>
              <div className='my-4 block'>
                <TextField
                  error={submitted && (!user.firstname /* || (!isAlphabetic(user.firstname)) */) ? true : false}
                  // disableUnderline
                  sx={{
                    "& .MuiInputLabel-root": { color: '#797979', },
                    "& fieldset": { border: '1px solid #eee' },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '8px',
                      color: '#797979',
                      background: 'rgb(39 41 91 / 6%)',
                    }
                  }
                  }
                  className='w-full'
                  label="Nom" variant="outlined" name="firstname" value={user.firstname} onChange={handleChange} />
              </div>
              <div className='my-4 block'>
              <TextField
                error={submitted && (!user.lastname/*  || (!isAlphabetic(user.lastname)) */)? true : false}
                  // disableUnderline
                  sx={{
                    "& .MuiInputLabel-root": { color: '#797979', },
                    "& fieldset": { border: '1px solid #eee' },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '8px',
                      color: '#797979',
                      background: 'rgb(39 41 91 / 6%)',
                    }
                  }
                  }
                  className='w-full' 
                  label="Prénom" variant="outlined"  name="lastname" value={user.lastname} onChange={handleChange} />

 
              </div>
              <div className='my-4 block'>
              <TextField
                  // disableUnderline
                  sx={{
                    "& .MuiInputLabel-root": { color: '#797979', },
                    "& fieldset": { border: '1px solid #eee' },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '8px',
                      color: '#797979',
                      background: 'rgb(39 41 91 / 6%)',
                    }
                  }
                  }
                  className='w-full'
                  label="Adresse" variant="outlined" error={submitted && !user.adresse ? true : false} name="adresse" value={user.adresse} onChange={handleChange}  /> 
              </div>
              <div className='my-4 block'>
              <TextField
                  // disableUnderline
                  sx={{
                    "& .MuiInputLabel-root": { color: '#797979', },
                    "& fieldset": { border: '1px solid #eee' },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '8px',
                      color: '#797979',
                      background: 'rgb(39 41 91 / 6%)',
                    }
                  }
                  }
                  className='w-full'
                  label="Code postal" variant="outlined" error={submitted && (!user.postalcode || (!isNumericValue(user.postalcode))) ? true : false} name="postalcode" value={user.postalcode} onChange={(e)=>{handleChange(e);setPinCode(e.target.value)}}  /> 
              </div>
              <div className='my-4 block'>
              {/* <TextField
                  // disableUnderline
                  sx={{
                    "& .MuiInputLabel-root": { color: '#797979', },
                    "& fieldset": { border: '1px solid #eee' },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '8px',
                      color: '#797979',
                      background: 'rgb(39 41 91 / 6%)',
                    }
                  }
                  }
                  className='w-full'
                  label="Ville" variant="outlined" error={submitted && !user.ville ? true : false} name="ville" value={user.ville} onChange={handleChange} />  */}

                <Select
                  sx={{
                    "& .MuiInputLabel-root": { color: '#797979', },
                    "& fieldset": { border: '1px solid #eee' },
                  }}
                  style= {
                   { borderRadius: '8px',
                    color: '#797979',
                    background: 'rgb(39 41 91 / 6%)',}
                  }
                  className='w-full'
                  label="Ville" variant="outlined"
                  id='ville_id'
                  error={submitted && !user.ville ? true : false}
                  name="ville_id" 
                  value={user.ville_id}
                  onChange={handleChange}                   
                >
                  {
                    user.villeArr.map((cityVal,cityIndex)=>{
                      return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                    })
                  }

                </Select>
               
              </div>

              <div className='my-4 block'>
              <TextField
                  // disableUnderline
                  sx={{
                    "& .MuiInputLabel-root": { color: '#797979', },
                    "& fieldset": { border: '1px solid #eee' },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '8px',
                      color: '#797979',
                      background: 'rgb(39 41 91 / 6%)',
                    }
                  }
                  }
                  className='w-full'
                  label="Email" variant="outlined" type="email" 
                  error={submitted && !user.email  && (!isValidEmail(user.email)) ? true : false} name="email" value={user.email} onChange={handleChange}  />

               
              </div>

              <div className='my-4 block'>
                <FormControl className='w-full' variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password" sx={{  color: submitted && !user.password ? '#d32f2f' :  '#797979',}} >Mot de passe</InputLabel>
                  <OutlinedInput
                    error={submitted && !user.password ? true : false} name="password" value={user.password} onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                    sx={{
                      "& .MuiInputLabel-root": { color:submitted && !user.password ? '#d32f2f' :  '#797979', },
                      "& fieldset": { border: '1px solid #eee' },
                      background: 'rgb(39 41 91 / 6%)',
                      color:submitted && !user.password ? '#d32f2f' :  '#797979', //
                    }} 
                    
                    endAdornment={
                      <InputAdornment position="end"   >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <AiFillEye fill='#CFCFCF'  /> : <AiFillEyeInvisible  fill='#CFCFCF' />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Mot de passe"
                  />
                </FormControl>

                
              </div>
              
              <div className='flex items-center  mb-5 mt-8 sm:mt-8'>  
                <FormControlLabel control={<Checkbox onChange={handleChange} name="term_conditions" value={user.term_conditions}  />} label={<span className="text-base  font-normal text-primary">
                      En cliquant sur “S’inscrire” vous acceptez nos <Link to="/conditions-generales-vente" className='text-primary font-semibold underline'>conditions générales</Link> et notre <Link to="" className='text-primary font-semibold underline'>politique de confidentialité</Link>
                    </span>} />   
                         
                {/* <div className='flex items-center'>
                  <label className='flex gap-4'>
                    <input class=" w-8 h-8 rounded-4xl block border-black-light" type="checkbox"  />
                    <span class="text-base  font-normal text-primary">
                      En cliquant sur “S’inscrire” vous acceptez nos <Link to="/conditions-generales-vente" className='text-primary font-semibold underline'>conditions générales</Link> et notre <Link to="" className='text-primary font-semibold underline'>politique de confidentialité</Link>
                    </span>
                  </label>
                </div>  */}
               
              </div>
              {
                submitted && !user.term_conditions?              
              <div>
               
                <FormHelperText  style={{"color":"#d32f2f"}}>Please accept term & conditions!</FormHelperText>
                </div>:""
                }
              <button type='submit' disabled={isLoading?true:false} className={isLoading?'bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white  mt-4 sm:mt-4 3xl:mt-10 px-6 w-full py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white opacity-60 ':'bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white  mt-4 sm:mt-4 3xl:mt-10 px-6 w-full py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white  '} >S’inscrire</button>

            </form>
            <div className='mt-8 3xl:mt-8'>
              <div className='grid grid-cols-6 sm:grid-cols-3  gap-0 items-center m-auto w-full text-center'>
                <div className='h-[0.5px] bg-black-light'> </div>
                <p className='col-span-4 sm:col-auto font-light text-base text-[#797979]'>S’inscrire avec</p>
                <div className='h-[0.5px] bg-black-light'> </div>
              </div>
              <div className='flex gap-x-5 justify-center items-center mt-3'>
                <SocialLoginComp />
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
