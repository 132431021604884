import React, { useState, useEffect, useMemo } from "react";
import { authHeader } from "../_helpers";
import axios from "axios";
import moment from "moment";
import Sidebar from "./component/Sidebar";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { alertActions } from "../_actions";
import ToastMessageComp from "../component/ToastMessageComp";
import PageLoaderComp from "../component/pageLoaderComp";

export default function Managerates() {
  const currentAdmin = useSelector((state) => state.administrator.admin);
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [department, setDepartment] = useState([]);
  useEffect(() => {
    getAllDepartments();
  }, []);
  async function getAllDepartments() {
    setPageLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/admin/managerates`
      );

      setDepartment(response.data.data);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.error(error);
    }
  }
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Tableau de bord
    </Link>,

    <Typography key="3">Gérer les tarifs</Typography>,
  ];
  const handleUpdate = async () => {
    setSuccessful(false);

    let formData = {
      data: department,
    };
    setPageLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}api/admin/managerates/${department[0].id}`,
        formData,
        authHeader()
      );

      setSuccessful(true);
      if (response.data.sucess === true) {
        dispatch(alertActions.success(response.data.msg));
        setDepartment(response.data.data);
      } else {
        dispatch(alertActions.error(response.data.msg));
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.error(error);
    }
  };

  const handleChange = (event, departmentVal) => {
    const index = department.findIndex((x) => x.id === departmentVal.id);
    if (index > -1) {
      const newState = [...department];
      newState[index][event.target.value] = event.target.value;
      setDepartment(newState);
    }
  };
  return (
    <>
      {successful ? (
        <ToastMessageComp message={alert.message} type={alert.type} />
      ) : (
        ""
      )}
      {pageLoading ? <PageLoaderComp /> : ""}

      <div className=" w-full h-full bg-white p-8">
        <Typography
          variant="h4"
          component="h4"
          fontWeight={500}
          color={"#1CBE93"}
        >
          Gérer les tarifs
        </Typography>
        <Stack
          spacing={2}
          mb={3}
          pb={3}
          sx={{ borderBottom: "0.5px solid rgb(222, 227, 226)" }}
        >
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>N° du Dpt</TableCell>
                <TableCell>Nom du département (Dpt)</TableCell>
                <TableCell>Prix au caractère HT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {department.map((val, index) => {
                return (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <TextField
                        variant="outlined"
                        size="small"
                        placeholder={val.department_num}
                        disabled
                        name="department_num"
                        value={val.department_num}
                      />
                    </TableCell>
                    <TableCell>
                      {" "}
                      <TextField
                        variant="outlined"
                        size="small"
                        placeholder={val.department_name}
                        disabled
                        name="department_name"
                        value={val.department_name}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          size="small"
                          onChange={(e) => handleChange(e, val)}
                          name="appendix"
                          value={val.appendix}
                        >
                          <MenuItem value={"1"}>A I</MenuItem>
                          <MenuItem value={"2"}>A II</MenuItem>
                          <MenuItem value={"3"}>A III</MenuItem>
                          <MenuItem value={"4"}>A IV</MenuItem>
                          <MenuItem value={"5"}>A V</MenuItem>
                          <MenuItem value={"6"}>A VI</MenuItem>
                          <MenuItem value={"7"}>A VII</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mx: 2, textAlign: "center" }}>
          <Button
            variant="contained"
            className="bg-secondary btnShadow w-1/2 mx-auto  my-4 font-bold text-sm text-white text-center py-3 px-2 rounded-lg hover:bg-primary transition-all duration-300"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </Box>
      </div>
    </>
  );
}
