import React,{useState} from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

export default function ToastMessageComp(props) {    
    const [open, setOpen] = useState(true);
    // const handleClick = () => {
    //   setOpen(true);
    // };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpen(false);
    };
  
    const action = (
      <React.Fragment>
        {/* <Button color="secondary" size="small" onClick={handleClose}>
          UNDO
        </Button> */}
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

  return (
    <>
    <div>
        {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            // message={props.message}
            action={action}
        >
        <Alert onClose={handleClose} severity={props.type} sx={{ width: '100%' }}>
            {props.message}
        </Alert>
        </Snackbar>
    </div>
    </>
  )
}
