import React from 'react'
import { HiChevronDown } from 'react-icons/hi'
import { Disclosure } from '@headlessui/react'
 
import { ReactComponent as NosEllipse } from "../../assets/Home/NosEllipse.svg"
// import Nosellipse from "../../assets/Home/NosEllipse.svg"
export default function ToutSavoirData() {

  return (
    <>
      <div>
        <div className='max-w-[1147px] bg-white toutSavo my-6'>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center justify-between h-[65px]  md:h-[100px]  w-full px-6 my-">
                  <h3 className='text-sm sm:text-base md:text-lg text-left font-semibold text-primary'>Qu’est-ce qu’une annonce légale ?</h3>
                  <HiChevronDown
                    className={`${open ? 'rotate-180 transform' : ''
                      } text-secondary text-3xl hichevCss`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-6 pt-4 pb-2 text-sm text-gray-500">
                  <p className='text-base font-normal leading-7 text-gray'>Une annonce légale est une annonce mentionnant les informations juridiques d'une entreprise, reprises dans un journal d'annonces légales. Ainsi, les dirigeants d'entreprise sont soumis à la publicité légale, c'est à dire à la diffusion des informations relatives à leur entreprise dans des journaux officiels (JAL). </p>
                  <p className='text-base font-normal leading-7 text-gray'>Si vous devez publier une annonce légale, vous pouvez le faire sur busyplace.fr c'est simple, rapide, et pas cher.</p>
                  <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'>A QUOI SERT L'ANNONCE LÉGALE ?</h4>
                  <p className='text-base font-normal leading-7 text-gray'>C'est à partir du XVI siècle sous le règne de Henri. II qu'une législation de publication d'annonces légales régissant la vie des entreprises et sociétés a été mis en vigueur.Depuis le décret numéro 55-22 du 4 janvier 1955 portant réforme de la publicité foncière, la publicité légale est obligatoire. Selon l'article 1 « Dans chaque département, et sauf pour les annonces devant paraître au Journal officiel de la République française ou à ses annexes, les annonces exigées par les lois et décrets sont insérées dans l'un des journaux concernant le choix des parties, remplissant les conditions prévues à l'article 2 » : « Article 2 : Tous les journaux d'information générale, judiciaire ou technique, inscrits à la commission paritaire des papiers de presse, et ne consacrant pas en conséquence à la publicité plus des deux tiers de leur surface et justifiant une vente effective par abonnement, dépositaires ou vendeurs, sont inscrits de droit sur la liste prévue ci-dessous sous les conditions suivantes :</p>
                  <ul className='flex gap-3 flex-col my-6 '>
                    <li className='flex gap-2 items-baseline'><NosEllipse /> Paraître depuis plus de six mois au moins une fois par semaine :</li>
                    <li className='flex gap-2 items-baseline'><NosEllipse /> Être publiés dans le département ou comporter pour le département une édition au moins hebdomadaire :</li>
                    <li className='flex gap-2 items-baseline'><div><NosEllipse /></div> Justifier d'une diffusion atteignant le minimum fixé par décret après avis de la commission prévue ci-dessous, en fonction de l'importance de la population du département ou de ses arrondissements.</li>
                  </ul>
                  <p className='text-base font-normal leading-7 text-gray mb-5'>La liste est préparée chaque année au mois de décembre en vue de l'année suivante, par une commission consultative présidée par le préfet et composée du président de la chambre départementale des notaires ou son représentant et, s'ils existent en nombre suffisant, de trois directeurs de journaux, désignés par le préfet, dont au moins deux directeurs de journaux ou publications périodiques, susceptibles de recevoir des annonces légales. Le Ministère de la Culture et de la Communication fixe dans un arrêté ministériel le prix de la ligne annuellement. La Loi de simplification du droit du 22 mars 2012 (Loi 55-4 du 4-1955 art. 1er, al.2 issu de la loi du 22-3-2012 rend obligatoire depuis le 1er janvier 2013 la mise en ligne de toutes les annonces publiées concernant une société ou un fonds de commerce sur une base de données numériques consultables en ligne. C'est le journal habilité à publier qui est mandaté pour effectuer cette démarche.</p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>


        </div>
        <div className='max-w-[1147px] bg-white toutSavo my-6'>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center justify-between h-[65px] md:h-[100px]  w-full px-6 my-">
                  <h3 className='text-sm sm:text-base md:text-lg text-left font-semibold text-primary'>Publier une annonce légale </h3>
                  <HiChevronDown
                    className={`${open ? 'rotate-180 transform' : ''
                      } text-secondary text-3xl hichevCss`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-6 pt-4 pb-2 text-sm text-gray-500">
                  <h3 className='text-base font-semibold text-primary'>QUELLES SONT LES OBLIGATIONS LÉGALES ?</h3>

                  <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'></h4>
                  <p className='text-base font-normal leading-7 text-gray'>La constitution d'une société ne peut pas se faire sans la publication d'une annonce légale c'est pour cela que cette formalité est nécessaire pour les créateurs d'entreprise. L'annonce légale doit permettre à la société créée de rendre visible les informations juridiques et permettent de faciliter les procédures de traçabilité et de transparence de l'entreprise. Cette obligation est valable pour toute la vie d'une entreprise.</p>
                  <p className='text-base font-normal leading-7 text-gray'>La publication d'une annonce légale est par exemple obligatoire pour : la constitution d'une société, les modifications statutaires, dissolutions, liquidations, transmissions des fonds de commerce. Les sociétés commerciales et civiles doivent publier des annonces légales. Il faut distinguer deux situations pour les délais avant publication :</p>

                  <ul className='flex gap-3 flex-col my-6 '>
                    <li className='flex gap-2 items-baseline'> <NosEllipse /> L'immatriculation : la publication de l'annonce légale doit se faire au plus tôt mais aucun délai précis n'est mentionné par le Décret de 1984.</li>
                    <li className='flex gap-2 items-baseline'> <div><NosEllipse /></div> L'obligation est effective durant la totalité de l'existence d'une société : à l'immatriculation puis à chaque fois qu'elle opère des changements l'affectant (prorogation, transfert de siège social ou encore cession de parts).</li>
                  </ul>
                  <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'>Annonces légales de modifications de société</h4>
                  <p className='text-base font-normal leading-7 text-gray'>Les modifications : l'annonce légale doit être publiée dans le mois de la décision (de l'assemblée générale ordinaire ou extraordinaire). La publication d'annonce légale sert à obtenir une attestation de parution, nécessaire pour l'enregistrement de société au Registre du Commerce et des sociétés. </p>
                  <p className='text-base font-normal leading-7 text-gray'>En effet, le journal remet avant la publication de l'annonce, une attestation légale de parution à présenter au greffe.</p>
                  <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'>Attestation de parution</h4>
                  <p className='text-base font-normal leading-7 text-gray'>L'attestation de parution est une déclaration sur l'honneur, de la part du journal émettant votre annonce légale, certifiant que l'annonce paraîtra bien à la date indiquée.</p>
                  <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'>LOIS ET DÉCRETS</h4>
                  <p className='text-base font-normal leading-7 text-gray'>Décret numéro 55-22 du 4 Janvier 1955 portant réforme de la publicité foncière.</p>
                  <p className='text-base font-normal leading-7 text-gray mb-5'>Arrêté relatif au tarif annuel et aux modalités de publication des annonces judiciaires et légales.</p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        <div className='max-w-[1147px] bg-white toutSavo my-6'>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center justify-between h-[65px] md:h-[100px]  w-full px-6 my-">
                  <h3 className='text-sm sm:text-base md:text-lg text-left font-semibold text-primary'>Qu’est-ce qu’un journal d’annonces légales ?</h3>
                  <HiChevronDown
                    className={`${open ? 'rotate-180 transform' : ''
                      } text-secondary text-3xl hichevCss`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-6 pt-4 pb-2 text-sm text-gray-500">


                  <p className='text-base font-normal leading-7 text-gray'>Un journal d’annonces légales ou JAL est un journal habilité à publier des annonces légales. Les sociétés civiles et commerciales doivent passer par ce type de périodique pour informer d'un changement de statut lors :
                  </p>

                  <ul className='flex gap-3 flex-col my-6 '>
                    <li className='flex gap-2 items-baseline'> <NosEllipse />constitution de société</li>
                    <li className='flex gap-2 items-baseline'> <NosEllipse />modification de société</li>
                    <li className='flex gap-2 items-baseline'> <NosEllipse />cessation d'activité</li>
                    <li className='flex gap-2 items-baseline'> <NosEllipse />transmission ou cession de fonds de commerce.</li>
                  </ul>
                  <p className='text-base font-normal leading-7 text-gray'>Les journaux d’annonces légales, aussi désignés par l'acronyme JAL, sont également habilités à recevoir les annonces légales pour un changement de patronyme ou un changement de régime matrimonial.</p>
                  <p className='text-base font-normal leading-7 text-gray mt-4'>Dans la liste des Journaux d’Annonces Légales, on distingue les journaux officiels que sont le Bodacc (Bulletin officiel des annonces civiles et commerciales) et le Balo (Bulletin des Annonces Légales Obligatoires).
                  </p>
                  <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'>Comment les Journaux d’annonces légales sont-ils habilités ?</h4>
                  <p className='text-base font-normal leading-7 text-gray'>Un représentant de l'État met à jour et diffuse annuellement la liste officielle des journaux d’annonces légales. Pour que ces derniers soient habilités, ils doivent répondre à plusieurs conditions à savoir :
                  </p>
                  <ul className='flex gap-3 flex-col my-6 '>
                    <li className='flex gap-2 items-baseline'> <NosEllipse />Figurer sur les registres de commission paritaire des publications et agences de presse (CPPAP) ;</li>
                    <li className='flex gap-2 items-baseline'> <NosEllipse />Être publiés au sein des départements ou être édités au moins une fois par semaine dans les départements ;</li>
                    <li className='flex gap-2 items-baseline'> <NosEllipse />Paraître depuis plus de six mois au moins une fois par semaine</li>
                    <li className='flex gap-2 items-baseline'> <NosEllipse />Respecter des seuils de diffusion payante, ainsi que les minimas de diffusion comme le prévoit le décret n°55-1650 du 17 décembre 1955.</li>
                  </ul>
                  <p className='text-base font-normal leading-7 text-gray'>Si tous ces critères sont remplis, un périodique peut prétendre à devenir un Journal d'Annonces Légales. Cette habilitation est valable du 1er janvier au 31 décembre et dépend d'un arrêté préfectoral du département.
                  </p>
                  <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'>L'attestation de parution dans un Journal d’annonces légales
                  </h4>
                  <p className='text-base font-normal leading-7 text-gray'>L'attestation de parution dans un Journal d’annonces légales constitue la preuve pour les autorités et les représentants de l'État de la publicité d'une annonce légale en bonne et due forme.
                  </p>
                  <p className='text-base font-normal leading-7 text-gray mt-4 mb-5'>Ce document officiel est fourni par le Journal d’annonces légale. Notre site busyplace.fr fournit une attestation de parution dans un Journal d’Annonces Légales immédiatement après la validation d'une annonce légale par notre service.</p>

                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        <div className='max-w-[1147px] bg-white toutSavo my-6'>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center justify-between h-[65px] md:h-[100px]  w-full sm:px-6 px-2 ">
                  <h3 className='text-sm sm:text-base md:text-lg text-left font-semibold text-primary'>Aujourd’hui le prix de certaines annonces légales n’est plus calculé au nombre de caractères mais au forfait :</h3>
                  <HiChevronDown
                    className={`${open ? 'rotate-180 transform' : ''
                      } text-secondary text-3xl hichevCss`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-6 pt-4 pb-2 text-sm text-gray-500">

                  <p className='text-base font-normal leading-7 text-gray'>Pour les constitutions d’EURL, de SARL, de SASU, de SAS, de SA, de SCI, de SNC le prix de l’annonce légale est forfaitaire ; elle dépend de la forme de votre société et non du journal ou du département de publication ; pour les annonces légales de modifications, de transfert de siège, de changement de gérant ou de président, de nomination, de modification de capital social ou d’objet ou de dénomination, de cession de parts sociales, de dissolution liquidation et de clôture de radiation, le principe reste le même.</p>
                  <p className='text-base font-normal leading-7 text-gray my-5'>Le prix étant forfaitaire, ne cherchez pas plus loin vous paierez la même chose.</p>
                  <p className='text-base font-normal leading-5 text-gray my-2'>Concernant les annonces de constitution :</p>
                  <h3 className='text-sm sm:text-base md:text-lg text-left font-semibold text-primary'>Cf Tableau Tarif Annonce constitution </h3>
                  <p className='text-base font-normal leading-5 text-gray my-2'>Concernant les annonces de Modifications :</p>
                  <h3 className='text-sm sm:text-base md:text-lg text-left font-semibold text-primary'>Cf Tableau Tarif Annonce Modification </h3>
                  <p className='text-base font-normal leading-5 text-gray my-2'>Concernant les annonces de cessation de société :</p>
                  <h3 className='text-sm sm:text-base md:text-lg text-left font-semibold text-primary'>Cf Tableau Tarif Annonce cessation </h3>
                  <p className='text-base font-normal leading-7 text-gray mb-5'>Pour toutes les autres annonces légales de modification de société, et autres, la tarification s'effectue au prix au caractère appliqué dans votre département de publication qui est fixé par le Ministère de la Culture et de la Communication.
Grille des prix par département des annonces légales qui ne figurent pas dans la tarification au forfait.
</p>
<h3 className='text-sm sm:text-base md:text-lg text-left font-semibold text-primary'>Cf Tableau Tarif Annonce au caractère</h3>

                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        <div className='max-w-[1147px] bg-white toutSavo my-6'>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center justify-between h-[65px] md:h-[100px]  w-full px-6 my-">
                  <h3 className='text-sm sm:text-base md:text-lg text-left font-semibold text-primary'>Publier une annonce légale - constitution d’une société</h3>
                  <HiChevronDown
                    className={`${open ? 'rotate-180 transform' : ''
                      } text-secondary text-3xl hichevCss`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-6 pt-4 pb-2 text-sm text-gray-500">

                  <p className='text-base font-normal leading-7 text-gray'>Une parution d'annonce légale est nécessaire pour la création d'entreprise. Que ce soit pour la constitution d'une, SARL, EURL, SAS, SASU ou SCI, il est nécessaire de passer par le biais d'un journal d'annonces légales pour présenter son activité. La publication d'une annonce légale est la dernière formalité à entreprendre avant l'immatriculation d'une société.
                  </p>

                  <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'>Publication d'annonce légale pour une création de société
                  </h4>
                  <p className='text-base font-normal leading-7 text-gray'>L'annonce légale pour la création d'entreprise fait partie des formalités légales à entreprendre lors de la constitution d'une société. Les mentions obligatoires à indiquer sur un journal d'annonces légales varient selon la forme juridique d'une entreprise.
                  </p>
                  <p className='text-base font-normal leading-7 text-gray my-5'>Le plus rapide et le plus simple pour publier une annonce légale de création de société est de passer par le site busyplace.fr. Nous vous proposons de remplir au préalable votre annonce via un modèle d'annonce légale présenté sous forme de formulaire interactif.</p>
                  <p className='text-base font-normal leading-7 text-gray mb-5'>Il est également nécessaire de publier une annonce légale pour la modification du statut d'une société, pour une cession d'activité. Notre formulaire en ligne permet aussi de publier une annonce rectificative.</p>

                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        <div className='max-w-[1147px] bg-white toutSavo my-6'>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center justify-between h-[65px] md:h-[100px]  w-full px-6 my-">
                  <h3 className='text-sm sm:text-base md:text-lg text-left font-semibold text-primary'>Publier une annonce légale - modification de société</h3>
                  <HiChevronDown
                    className={`${open ? 'rotate-180 transform' : ''
                      } text-secondary text-3xl hichevCss`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-6 pt-4 pb-2 text-sm text-gray-500">



                  <p className='text-base font-normal leading-7 text-gray'>Lors d’une modification de société, il est nécessaire de publier une annonce légale dans un JAL. Cette annonce permet de notifier au greffe tout changement concernant le statut d’une entreprise. </p>
                  <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'>Publier une annonce légale pour une modification de société </h4>
                  <p className='text-base font-normal leading-7 text-gray'>Quel que soit le changement de statut d’une entreprise, celui-ci doit faire l’objet d’une publication d’annonce légale. Cela concerne notamment un changement de dénomination, un transfert de siège social, une modification de capital ou encore un changement de dirigeant ou d’objet, une transformation de SARL en SAS.</p>
                  <p className='text-base font-normal leading-7 text-gray my-5'>Pour ce faire, busyplace.fr vous permet de publier votre annonce légale en ligne. Un service rapide et fiable. Une fois votre annonce saisie et validée par nos équipes, nous la transmettrons à un journal habilité par le greffe compétent de votre département.
                  </p>
                  <p className='text-base font-normal leading-7 text-gray'>La parution d’une annonce légale dans un JAL est également nécessaire pour la création d’une société, la location gérance ou la cession de fonds de commerce, la cessation d’activité. Notre plateforme vous permet également d’effectuer une rectification d’annonce légale.
                  </p>
                  {/* <p className='text-base font-normal leading-7 text-gray my-5'>Dénomination sociale, location gerance, sarl unipersonnelle.</p> */}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        <div className='max-w-[1147px] bg-white toutSavo my-6'>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center justify-between h-[65px] md:h-[100px]  w-full px-6 my-">
                  <h3 className='text-sm sm:text-base md:text-lg text-left font-semibold text-primary'>Publier une annonce légale - cessation d’activité</h3>
                  <HiChevronDown
                    className={`${open ? 'rotate-180 transform' : ''
                      } text-secondary text-3xl hichevCss`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-6 pt-4 pb-2 text-sm text-gray-500">
                  <p className='text-base font-normal leading-7 text-gray'>La publication d’une annonce légale est nécessaire lors d’une cessation d’activité. Elle permet ainsi de renseigner le greffe du tribunal de commerce de la situation de votre société. Cette annonce doit obligatoirement être publiée au sein d’un journal habilité que l’on appelle plus communément Journal d’Annonces Légales.
                  </p>
                  <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'>Passer une annonce légale lors de la cessation d’activité d’une entreprise
                  </h4>
                  <p className='text-base font-normal leading-7 text-gray'>Afin de publier facilement et rapidement votre annonce légale, vous pouvez passer par notre service de publication en ligne. Un service fiable qui permet de publier votre annonce légale dans un JAL pour une dissolution anticipée, un avis de clôture de liquidation, un avis de dissolution ou encore une dissolution et clôture.
                  </p><p className='text-base font-normal leading-7 text-gray'>Annonces@busyplace.fr vous permet également de passer votre annonce légale pour une création de société, une modification d’entreprise ainsi qu’une annonce rectificative.

                  </p>
                  <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'>Liquidation et dissolution d'une société
                  </h4>
                  <p className='text-base font-normal leading-7 text-gray'>Que ce soit pour la dissolution ou la liquidation de votre société, vous êtes tenus de publier une annonce légale dans un JAL habilité. Certains Greffes acceptent la publication d'un avis de dissolution et de la clôture dans la même annonce légale.
                  </p>
                  <p className='text-base font-normal leading-7 text-gray'>Le cas échéant, il vous suffit de remplir le formulaire ci-dessus pour rédiger et publier une annonce légale de dissolution et liquidation. Sinon, vous devez publier dans un premier temps une annonce légale pour la dissolution de votre entreprise ; puis dans un second temps une annonce légale pour la liquidation de votre société.
                  </p>

                  <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'>Pouvez-vous publier une seule annonce de dissolution et liquidation ?
                  </h4>
                  <p className='text-base font-normal leading-7 text-gray'>De nombreux greffes acceptent que vous publiiez une annonce unique pour la dissolution et la liquidation de votre société. Si le siège social de votre société se trouve en Île-de-France par exemple, vous pouvez publier une seule annonce légale pour ces deux événements.
                  </p>
                  <p className='text-base font-normal leading-7 text-gray'>Hors Île-de-France, certains greffes n'acceptent pas la publication d'une seule annonce légale pour la dissolution et la liquidation de votre société, comme par exemple :
                  </p>
                  <p className='text-base font-normal leading-7 text-gray'>Bourg en Bresse, Caen, Grenoble, Lyon, Montpellier, ou les greffes du Bas-Rhin. Nous vous conseillons fortement de contacter d’abord le greffe dont vous dépendez avant de sélectionner notre modèle unique.
                  </p>
                  <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'>Publier une annonce légale de dissolution et liquidation avec busyplace.fr
                  </h4>
                  <p className='text-base font-normal leading-7 text-gray'>Busyplace.fr propose un service unique, pratique et rapide pour rédiger et publier votre annonce légale de dissolution et liquidation. 
                  </p>
                  <p className='text-base font-normal leading-7 text-gray mt-5'><span className='text-primary text-base sm:text-lg font-extrabold'>Pratique</span>, car il vous permet de rédiger simplement et rapidement une annonce légale conforme aux exigences du Greffe, sans connaissance en rédaction d'annonce légale.
                  </p>
                  <p className='text-base font-normal leading-7 text-gray my-5'><span className='text-primary text-base sm:text-lg font-extrabold'>Rapide</span>, car dès réception de votre paiement, vous recevez immédiatement votre attestation de publication, que vous allez devoir joindre à votre dossier de formalités.
                  </p>
                  <p className='text-base font-normal leading-7 text-gray'>Si vous choisissez le paiement par CB, vous recevrez en quelques minutes l'attestation de publication de votre annonce légale de dissolution et liquidation. Busyplace.fr se charge ensuite de la publication de votre annonce légale dans un JAL habilité de votre département !

                  </p>
                  {/* <p className='text-base font-normal leading-7 text-gray my-5'>Déposer une annonce, rsi auto entrepreneur, journal comptable.</p> */}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        <div className='max-w-[1147px] bg-white toutSavo my-6'>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center text-left justify-between  h-[65px] md:h-[100px]  w-full px-6 my-">
                  <h3 className='text-sm sm:text-base md:text-lg text-left font-semibold text-primary'>Publication d'annonce légale relative à un fonds de commerce</h3>
                  <HiChevronDown
                    className={`${open ? 'rotate-180 transform' : ''
                      } text-secondary text-3xl hichevCss`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-6 pt-4 pb-2 text-sm text-gray-500">
                  <p className='text-base font-normal leading-7 text-gray'>Busyplace met à votre disposition un <span className='font-semibold text-primary'>formulaire simplifié qui vous assure de remplir tous les champs nécessaires pour la publication d’une annonce légale d’apport de fonds de commerce, de cession de droit au bail, de fin de location gérance ou de vente de fonds de commerce.</span>   <span className='font-semibold text-primary'></span> <span className='font-semibold text-primary'></span>  <span className='font-semibold text-primary'></span></p>
                  {/* <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'>Passer une annonce légale rectificative</h4>
                  <p className='text-base font-normal leading-7 text-gray'>busyplace.fr met à votre disposition un formulaire simplifié qui vous assure de remplir tous les champs nécessaires pour la publication d’une annonce légale d’apport de fonds de commerce, de cession de droit au bail, de fin de location gérance ou de vente de fonds de commerce. </p> */}
                  <h4 className='mt-10 text-primary text-base sm:text-lg font-extrabold'>Passer une annonce légale rectificative </h4>
                  <p className='text-base font-normal leading-7 text-gray'>Il peut arriver que vous souhaitiez rectifier une annonce légale déjà publiée ou bien ajouter des informations supplémentaires à votre parution. Pour ce faire, notre formulaire d’annonce légale vous permet de renseigner tous les champs nécessaires à cette formalité.</p>
                  <p className='text-base font-normal leading-7 text-gray my-5'>Notre plateforme de publication d’annonce légale vous permet aussi de passer votre annonce dans un JAL pour une création d’entreprise, une modification de société ou une cessation d’activité.</p>

                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>


      </div>
    </>
  )
}
