import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { userActions } from "../_actions";
import { alertActions } from "../_actions";

export const useLogoutHandler = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const logoutHandler = () => {
      dispatch(userActions.logout());
      dispatch(alertActions.success("Vous avez été déconnecté avec succès !"));
  
      setTimeout(() => {
          if(props.logoutfrom === "admin"){
            navigate("/admin/login");
          }else{
            navigate("/login");
          }
        
      }, 1000);
    };
  
    return logoutHandler;
};
