import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
 
import { IoIosArrowDown } from 'react-icons/io'
import Footer from '../component/Footer';
import { Helmet } from 'react-helmet';
export default function Faq() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Helmet>
        <title>Foire aux questions de BusyPlace</title>
        <meta name="description" content="FAQ de BusyPlace" />
        {/* <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/faq`} /> */}
      </Helmet>
      <div className='w-full h-full FaqBg' >
        <div className='md:max-w-[1477px] relative w-full h-full m-auto py-6 md:py-14 px-4 sm:px-14'>
          <div className='flex items-center justify-start gap-4 w-full m-auto'>
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1">
                <li className="inline-flex items-baseline text-white">
                  <Link to="/" className="inline-flex gap-2 text-sm font-medium text-white hover:text-secondary ">
                    <svg width="19" height="16" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.9101 6.8853L8.52849 0.153321C8.47534 0.104718 8.41221 0.0661594 8.34271 0.0398506C8.27322 0.0135419 8.19872 0 8.12348 0C8.04824 0 7.97374 0.0135419 7.90425 0.0398506C7.83475 0.0661594 7.77162 0.104718 7.71847 0.153321L0.33691 6.8853C0.121861 7.08157 0 7.34816 0 7.62621C0 8.20356 0.514326 8.67297 1.14693 8.67297H1.92469V13.4766C1.92469 13.7661 2.18096 14 2.49815 14H6.97655V10.3363H8.98368V14H13.7488C14.066 14 14.3223 13.7661 14.3223 13.4766V8.67297H15.1C15.4047 8.67297 15.6968 8.56339 15.9118 8.36548C16.3581 7.95659 16.3581 7.29419 15.9101 6.8853Z" fill="#ffffff" />
                    </svg>
                    Accueil
                  </Link>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg aria-hidden="true" className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                   <h1> <span className="ml-1 text-sm   text-white font-semibold md:ml-2 ">FAQ</span></h1>
                  </div>
                </li>
              </ol>
            </nav>
          </div>
          <div className='max-w-[1277px] m-auto mt-8 md:mt-16 flex justify-center items-center flex-col gap-4'>
            <h1 className='text-xl sm:text-2xl 3xl:text-[35px] font-normal text-white'>Comment pouvons-nous <span className='font-black'>vous aider ?</span> </h1>
            <form class="bg-white border max-w-[500px] px-4 py-3 input-box-shadow rounded-md flex justify-between w-full "  id="searchForm" >
              <input type="text" class=" w-full outline-none text-[#797979] text-sm sm:text-base font-light" placeholder="Saisissez des mots clés pour trouver des réponses" />
              <button>
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M33 33L25.6807 25.6677M29.7368 15.8684C29.7368 19.5466 28.2757 23.074 25.6749 25.6749C23.074 28.2757 19.5466 29.7368 15.8684 29.7368C12.1903 29.7368 8.6628 28.2757 6.06197 25.6749C3.46113 23.074 2 19.5466 2 15.8684C2 12.1903 3.46113 8.6628 6.06197 6.06197C8.6628 3.46113 12.1903 2 15.8684 2C19.5466 2 23.074 3.46113 25.6749 6.06197C28.2757 8.6628 29.7368 12.1903 29.7368 15.8684V15.8684Z" stroke="#414141" stroke-width="3" stroke-linecap="round" />
                </svg>

              </button>
            </form>
          </div>
        </div>
      </div >
      <div className='md:max-w-[860px] relative w-full h-full m-auto py-6 md:py-14 px-4 sm:px-14'>
        <h4 className='text-sm sm:text-base font-semibold text-primary'>Vous pouvez aussi consulter les thèmes ci-dessous</h4>

        <div className='mt-4'>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ borderBottom: "1px solid #DEE3E2", boxShadow: "none", padding: '12px 0px', borderRadius: '0' }}>
            <AccordionSummary
              sx={{ paddingX: '0' }}
              expandIcon={<IoIosArrowDown style={{ color: '#602DB4', background: "rgb(96 45 180 / 9%)", width: "30px", height: '30px', borderRadius: '100px', padding: '3px 0' }} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <p className='text-sm sm:text-base font-medium text-gray'>
                Pourquoi avez-vous des tarifs moins chers que les Journaux officiels ?
              </p>
            </AccordionSummary>

            <AccordionDetails sx={{ paddingLeft: '0', paddingRight: '30px' }}>
              <p className='text-gray text-sm sm:text-base font-normal'>Le <span className='font-semibold'> prix d’une annonce légale</span> est le résultat d’un coût à la ligne. Le tarif de la ligne est déterminé par le Ministère de la Culture et de la Communication pour chaque département. Cela signifie que le tarif de la ligne est le même pour tous les journaux du département concerné.
              </p>
              <p className='text-gray text-sm sm:text-base font-normal mt-4'>Toutefois des différences entre les journaux existent. En effet certains d’entre eux refusent certaines abréviations, comptent des interlignes ou rajoutent des informations non obligatoires. Cela augmente parfois de façon significative le coût de l’annonce. Nos modèles de saisie ont été pensé pour répondre en moins de caractères possibles aux exigences légales de tous les Greffes de France ce qui explique la différence de prix.</p>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ borderBottom: "1px solid #DEE3E2", boxShadow: "none", padding: '12px 0px', borderRadius: '0' }}>
            <AccordionSummary
              sx={{ paddingX: '0' }}
              expandIcon={<IoIosArrowDown style={{ color: '#602DB4', background: "rgb(96 45 180 / 9%)", width: "30px", height: '30px', borderRadius: '100px', padding: '3px 0' }} />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <p className='text-sm sm:text-base font-medium text-gray'>
                Comment publier partout en France ?
              </p>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingLeft: '0', paddingRight: '30px' }}>
              <p className='text-gray text-sm sm:text-base font-normal mt-4'>Nous avons sélectionné pour vous les journaux les plus réactifs et les moins chers de leur département. En partenariat avec eux nous avons reçu l’autorisation d’émettre en leurs noms des attestations de publications acceptées par les Greffes.
              </p>

            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ borderBottom: "1px solid #DEE3E2", boxShadow: "none", padding: '12px 0px', borderRadius: '0' }}>
            <AccordionSummary
              sx={{ paddingX: '0' }}
              expandIcon={<IoIosArrowDown style={{ color: '#602DB4', background: "rgb(96 45 180 / 9%)", width: "30px", height: '30px', borderRadius: '100px', padding: '3px 0' }} />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <p className='text-sm sm:text-base font-medium text-gray'>
                Est-ce que je recevrais un exemplaire du journal dans lequel mon annonce est publiée ?
              </p>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingLeft: '0', paddingRight: '30px' }}>
              <p className='text-gray text-sm sm:text-base font-normal mt-4'>Oui par courrier dans les jours qui suivent la publication de votre annonce.
              </p>

            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel31'} onChange={handleChange('panel31')} sx={{ borderBottom: "1px solid #DEE3E2", boxShadow: "none", padding: '12px 0px', borderRadius: '0' }}>
            <AccordionSummary
              sx={{ paddingX: '0' }}
              expandIcon={<IoIosArrowDown style={{ color: '#602DB4', background: "rgb(96 45 180 / 9%)", width: "30px", height: '30px', borderRadius: '100px', padding: '3px 0' }} />}
              aria-controls="panel31bh-content"
              id="panel31bh-header"
            >
              <p className='text-sm sm:text-base font-medium text-gray'>
                Ma demande est urgente, dans quels délais recevrais-je mon attestation de parution ?
              </p>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingLeft: '0', paddingRight: '30px' }}>
              <p className='text-gray text-sm sm:text-base font-normal mt-4'>Dans la micro seconde après le paiement (plus ou moins 5 minutes, tout dépend de votre débit internet).</p>

            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{ borderBottom: "1px solid #DEE3E2", boxShadow: "none", padding: '12px 0px', borderRadius: '0' }}>
            <AccordionSummary
              sx={{ paddingX: '0' }}
              expandIcon={<IoIosArrowDown style={{ color: '#602DB4', background: "rgb(96 45 180 / 9%)", width: "30px", height: '30px', borderRadius: '100px', padding: '3px 0' }} />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <p className='text-sm sm:text-base font-medium text-gray'>
                Je passe mon annonce légale aujourd’hui, quelle sera la date de parution prévue ?
              </p>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingLeft: '0', paddingRight: '30px' }}>
              <p className='text-gray text-sm sm:text-base font-normal mt-4'>Tout dépend du département, votre annonce sera publiée à la prochaine date de publication disponible dans le journal habilité de votre département.</p>

            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} sx={{ borderBottom: "1px solid #DEE3E2", boxShadow: "none", padding: '12px 0px', borderRadius: '0' }}>
            <AccordionSummary
              sx={{ paddingX: '0' }}
              expandIcon={<IoIosArrowDown style={{ color: '#602DB4', background: "rgb(96 45 180 / 9%)", width: "30px", height: '30px', borderRadius: '100px', padding: '3px 0' }} />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <p className='text-sm sm:text-base font-medium text-gray'>
                Comment le prix de mon annonce est-il calculé ?
              </p>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingLeft: '0', paddingRight: '30px' }}>
              <p className='text-gray text-sm sm:text-base font-normal mt-4'>Le prix d’une annonce légale est le résultat d’un coût à la ligne en fonction du nombre de caractère de votre annonce légale qui doit respecter une disposition réglementé. Le tarif de la ligne est déterminé par le Ministère de la Culture et de la Communication pour chaque département. Cela signifie que le tarif de la ligne est le même pour tous les journaux du département concerné. Seul le nombre de ligne calculé diffère en fonction des journaux.</p>

            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} sx={{ borderBottom: "1px solid #DEE3E2", boxShadow: "none", padding: '12px 0px', borderRadius: '0' }}>
            <AccordionSummary
              sx={{ paddingX: '0' }}
              expandIcon={<IoIosArrowDown style={{ color: '#602DB4', background: "rgb(96 45 180 / 9%)", width: "30px", height: '30px', borderRadius: '100px', padding: '3px 0' }} />}
              aria-controls="panel6bh-content"
              id="panel6bh-header"
            >
              <p className='text-sm sm:text-base font-medium text-gray'>
                D’autres sites, disent qu’ils sont moins chers, êtes-vous encore moins chers qu’eux ?
              </p>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingLeft: '0', paddingRight: '30px' }}>
              <p className='text-gray text-sm sm:text-base font-normal mt-4'>Nos analyses de prix sont corrigées quotidiennement ce qui vous garantis le prix le plus juste du marché.</p>

            </AccordionDetails>
          </Accordion>

        </div>
      </div>
      <div className=' max-w-[860px] mx-0 sm:mx-8 md:mx-auto md:mb-14 mb-14'>
        <h2 className='text-lg sm:text-2xl 3xl:text-[25px] text-gray text-center py-12 font-extrabold'>Besoin d’aide dans vos démarches ?</h2>
        <div className='flex flex-col sm:flex-row gap-6 sm:gap-12 justify-center items-center mx-4'>

          <div className='bg-white w-full max-w-[388px] h-full rounded-[41px] flex justify-center items-center p-6 md:p-12 ' style={{ boxShadow: '0px 4px 14px 2px rgba(145, 143, 143, 0.25)' }}>

            <div className='text-center w-full h-full flex flex-col items-center justify-center'>
              <svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M49.1663 11.8027V31.4792C49.1665 33.4854 48.3989 35.4155 47.0211 36.8737C45.6433 38.332 43.7598 39.2077 41.7568 39.3213L41.3122 39.3334H8.68717C6.68101 39.3335 4.75084 38.5659 3.29262 37.1881C1.83439 35.8104 0.958671 33.9268 0.845091 31.9239L0.833008 31.4792V11.8027L24.1587 24.0214C24.4182 24.1573 24.7067 24.2283 24.9997 24.2283C25.2926 24.2283 25.5812 24.1573 25.8407 24.0214L49.1663 11.8027ZM8.68717 0.666687H41.3122C43.259 0.666453 45.1365 1.38926 46.5806 2.69492C48.0247 4.00057 48.9324 5.79602 49.1277 7.73302L24.9997 20.3722L0.871675 7.73302C1.05902 5.87294 1.90371 4.14069 3.2539 2.84764C4.6041 1.55459 6.37124 0.785556 8.23768 0.678771L8.68717 0.666687H41.3122H8.68717Z" fill="#602DB4" />
              </svg>
              <h4 className='font-semibold text-lg  text-gray text-center mt-6'>Envoyez-nous un Email</h4>
              <p className='font-light text-sm text-gray mb-4  3xl:mt-4 w-full sm:max-w-[233px]'>Nos team s’efforceront de vous répondre au plus vite. </p>
              <Link to="mailto:contact@busyplace.fr" className='btnShadow text-white flex items-center justify-center rounded-[33px] text-sm font-bold  h-[33px] w-[153px] transition-all duration-300 mt-4 bg-primary hover:bg-secondary'>Contactez-nous</Link>

            </div>
          </div>

          <div className='bg-white w-full max-w-[388px] h-full   rounded-[41px] flex justify-center items-center p-6 md:p-12' style={{ boxShadow: '0px 4px 14px 2px rgba(145, 143, 143, 0.25)' }}>

            <div className='text-center w-full h-full flex flex-col items-center justify-center'>
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 0C10.752 0 0 10.752 0 24C0 37.248 10.752 48 24 48C37.248 48 48 37.248 48 24C48 10.752 37.248 0 24 0ZM24 9.6C28.632 9.6 32.4 13.368 32.4 18C32.4 22.632 28.632 26.4 24 26.4C19.368 26.4 15.6 22.632 15.6 18C15.6 13.368 19.368 9.6 24 9.6ZM24 43.2C19.128 43.2 13.368 41.232 9.264 36.288C13.4679 32.9897 18.6567 31.1971 24 31.1971C29.3433 31.1971 34.5322 32.9897 38.736 36.288C34.632 41.232 28.872 43.2 24 43.2Z" fill="#602DB4" />
              </svg>
              <h4 className='font-semibold text-lg  text-gray text-center mt-6'>Contactez un conseiller</h4>
              <p className='font-light text-sm text-gray  3xl:mt-4 sm:max-w-[233px] mb-4'>Du lundi au vendredi de 10h à 12h
                et de 14h à 18h</p>
              <Link to="tel:01 53 65 16 66" className='btnShadow text-white rounded-[33px] text-sm font-bold flex items-center justify-center h-[33px] w-[153px] transition-all duration-300 mt-4 bg-primary hover:bg-secondary'>Appelez-nous</Link>

            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
