import React from "react"; 
import SideBar from "./SideBar";

export default function ScreenWrapper({children}) {
  return (
    <div className="bg-mainBg w-full  pt-4 xl:pt-0  ">
      <div className="xl:max-w-[1477px] relative w-full h-full m-auto xl:pt-7 pb-16 px-4 2xl:px-14">
        <div className="grid grid-cols-12 gap-4">
          <div className="xl:mb-8  xl:col-span-3 col-span-12 xl:mx-0 mx-4">
            <SideBar />
          </div>
          <div className="mb-8 col-span-12 xl:col-span-9">
          {children}
          </div>
        </div>
      </div>
    </div>
  );
}

 
