import React, { useState } from "react";
import Topbar from "./Topbar";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(true);
  const hendlemenuOpen = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <>
      <div>
        <Topbar hendlemenuOpen={hendlemenuOpen} />
        <div className="bg-mainBg w-full ">
          <div
            className={`relative w-full h-full m-auto md:pt-1 px-0   ${
              menuOpen ? "xl:pr-5" : "xl:px-5"
            }`}
          >
            <div className="grid grid-cols-12 gap-x-4 xl:mt-4">
              <div
                className={` h-screen ${
                  menuOpen
                    ? "hidden xl:block col-span-3 menuMobileopen01"
                    : " xl:hidden xl-col-span-3 col-span-12 menuMobileopen02"
                }`}
              >
                <Sidebar hendlemenuOpen={hendlemenuOpen} setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
              </div>
              <div
                className={`col-span-12   ${
                  menuOpen ? "xl:col-span-9" : "xl:col-span-12"
                }`}
              >
                <Outlet context={[menuOpen]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
