import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Breadcrumb from './Directory/Breadcrumb';
import Footer from '../component/Footer';
import { Container, Typography, Paper, Button, Grid } from '@mui/material';
import moment from 'moment';
import PageLoaderComp from '../component/pageLoaderComp';
const RechercheDesAnnocesLegalesDetails = () => {

  const { id } = useParams();
  const [announceData, setAnnounceData] = useState({});
  const [departmentData, setDepartmentData] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const [breadcrumbsData, setBreadcrumbsData] = useState({
    "breadCrumbs": [],
    "main": { "pagename": "Annonce légale publiée", "path": "/recherche-des-annoces-legales" }
  });

  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true)
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/announce/recherche-des-annoces-legales/${id}`);
        setPageLoading(false)
        // Assuming the response structure is an object containing data
        setAnnounceData(response?.data?.annonces);
        setDepartmentData(response?.data?.department)
        // console.log(response?.data)
      } catch (error) {
        setPageLoading(false)
        console.error('Error while fetching data:', error);
      }
    };

    fetchData();
  }, [id]); // Include id in the dependency array to trigger the effect when id changes

  return (
    <>
      {pageLoading ?
      <PageLoaderComp />
      : ""}
      <div className='bg-mainBg'>
          <div className='md:max-w-[1477px] relative w-full h-full m-auto py-7 px-4 sm:px-14 myForm'>
              <Breadcrumb page="Annonce légale publiée" breadcrumbsData={breadcrumbsData} />
              <div className='relative  bg-mainBg my-4 sm:my-12 '>
              <Typography variant="h4" color="#009640" mb={2}>
                          Annonce légale publiée
                      </Typography>

                      <Typography variant="body1">
                          Annonce Légale -  {announceData?.form_type_text}
                      </Typography>
                      <Typography variant="h6" color="textSecondary" mb={2}>
                      {/* {announceData?.socitename} */}
                      {announceData?.votre_annonce_parse?.denomination?announceData?.votre_annonce_parse?.denomination:announceData?.votre_annonce_parse?.denomination_societe?announceData?.votre_annonce_parse?.denomination_societe:announceData.socitename}

                      </Typography>
                      <Typography variant="body1" color="textSecondary" mb={2}>
                          Département: {departmentData?.department_name} {departmentData?.department_num}
                      </Typography>
                      <Typography variant="body1" color="textSecondary" mb={2}>
                          Annonce légale: <span style={{ color: '#009640' }}>{announceData?.payment_order_id}</span>
                      </Typography>
                      <Typography variant="body1" color="textSecondary" mb={2} style={{ color: 'gray', fontFamily: 'arial', fontWeight: 'bold', lineHeight: '2rem' }}>
                          Parue dans le journal Liti.Fr<br /> du {moment(announceData?.date_parution).format("DD-MM-Y")}
                      </Typography>

                      <Grid container spacing={2}>
                          <Grid item md={6}>
                          <div className='form-data-images' style={{ padding: '20px', fontSize: '18px', width: '100%' }}>
                              <div className=' rounded-[14px] p-5 sm:mx-0 mx-2' style={{ 'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)' }} dangerouslySetInnerHTML={{__html:announceData?.votre_html}}>
                                
                              </div>                
                            
                          </div>
                          </Grid>
                      </Grid>
                      <Grid container spacing={2} mt={2}>
                          <Grid item md={3}>
                          <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              component={Link}
                              to="/recherche-des-annoces-legales"
                              sx={{
                              backgroundColor: '#138496',
                              borderColor: '#117a8b',
                              boxShadow: '0 0 0 0.2rem rgba(58, 176, 195, .5)',
                              '&:hover': {
                                  backgroundColor: '#117a8b',
                              },
                              }}
                          >
                              Retour
                          </Button>
                          </Grid>
                          <Grid item md={3}>
                        
                          <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={() => window.open(`${process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE}assets/uploads/pdfgenerate/attestation/${announceData?.attestation}`, '_blank')}
                              sx={{
                              backgroundColor: '#138496',
                              borderColor: '#117a8b',
                              boxShadow: '0 0 0 0.2rem rgba(58, 176, 195, .5)',
                              '&:hover': {
                                  backgroundColor: '#117a8b',
                              },
                              }}
                              rel="noopener noreferrer"
                          >
                              Attestation originale
                          </Button>
                          </Grid>
                      </Grid>
              </div>
          </div>
      </div>
      
      <Footer />
    </>
  );
}

export default RechercheDesAnnocesLegalesDetails;


