const InvoiceTemplate = (props) => {
	
	return (<>
		<table style={{"width":"100%"}}>
			<tr>
				<td style={{"width":"33%"}}>
					<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
						width="300px" height="188.000000pt" viewBox="0 0 388.000000 188.000000"
						preserveAspectRatio="xMidYMid meet">

						<g transform="translate(0.000000,188.000000) scale(0.100000,-0.100000)"
						stroke="none" fill="#e7060f">
						<path fill="#28a745" d="M3024 1836 c-40 -17 -100 -80 -126 -130 -50 -99 -82 -260 -98 -496
						-12 -179 -12 -180 -100 -180 -46 0 -61 -4 -78 -23 -29 -31 -28 -56 6 -96 24
						-29 36 -34 87 -40 l60 -6 0 -140 c0 -77 -3 -197 -5 -267 -6 -165 -4 -168 120
						-168 64 0 91 4 99 14 9 10 13 93 15 278 1 145 6 270 10 279 5 12 26 18 79 22
						40 2 90 11 112 18 36 13 40 18 43 52 3 33 -2 43 -33 73 -34 31 -41 34 -99 34
						-39 0 -68 5 -74 13 -13 16 1 236 22 338 25 121 85 229 127 229 23 0 56 -47 64
						-93 4 -21 16 -42 26 -47 51 -28 129 8 129 58 0 31 -32 125 -51 150 -68 90
						-160 142 -249 142 -30 -1 -69 -7 -86 -14z"/>
						<path fill="#28a745" d="M241 1453 c-36 -37 -46 -102 -68 -433 -19 -307 -20 -338 -9 -445 11
						-121 22 -152 69 -199 46 -46 99 -66 172 -66 84 0 125 18 192 88 l63 64 11 -29
						c14 -39 56 -81 103 -104 54 -26 184 -26 241 0 58 26 145 118 145 152 0 38 -46
						62 -81 43 -13 -7 -59 -29 -103 -49 -92 -42 -102 -38 -108 38 -6 66 9 357 20
						419 5 25 6 51 3 56 -21 31 -116 43 -166 20 -15 -7 -33 -25 -41 -41 -17 -32
						-39 -244 -37 -344 2 -59 -7 -78 -22 -53 -4 7 -11 8 -18 1 -30 -24 -198 -103
						-210 -98 -15 6 -32 155 -23 199 3 12 10 122 16 243 6 121 18 292 25 379 17
						188 18 185 -83 186 -56 0 -67 -3 -91 -27z"/>
						<path fill="#28a745" d="M1244 1433 c-35 -30 -44 -58 -44 -141 0 -58 -4 -83 -15 -92 -12 -10
						-113 -28 -162 -30 -19 0 -44 -45 -37 -64 9 -23 75 -55 129 -63 25 -3 49 -11
						53 -17 4 -6 7 -126 7 -266 0 -304 5 -328 75 -394 84 -79 194 -87 311 -23 103
						55 138 81 139 100 0 11 8 17 23 17 17 0 28 -10 41 -40 43 -97 150 -143 280
						-121 75 12 105 30 175 103 50 53 53 81 10 104 -31 16 -33 15 -135 -31 -86 -39
						-106 -45 -119 -35 -25 21 -20 272 9 497 7 49 -13 63 -90 63 -117 0 -131 -21
						-145 -228 -6 -81 -8 -168 -4 -195 5 -42 4 -47 -14 -47 -11 0 -23 5 -27 11 -9
						14 -40 5 -137 -40 -116 -54 -122 -55 -145 -31 -16 17 -21 42 -27 141 -8 136 3
						398 17 416 5 6 44 15 86 18 97 9 132 26 132 63 0 42 -56 92 -102 92 -19 0 -50
						3 -67 6 l-31 7 0 68 c0 37 5 71 10 74 18 11 11 60 -11 73 -12 8 -54 16 -93 18
						-58 4 -75 2 -92 -13z"/>
						<path fill="#28a745" d="M787 1416 c-57 -28 -105 -71 -119 -106 -13 -35 -1 -100 26 -133 57
						-71 221 -43 257 44 18 44 -14 161 -53 194 -36 31 -50 31 -111 1z"/>
						<path fill="#28a745" d="M1871 1396 c-63 -33 -109 -80 -116 -119 -8 -43 19 -111 55 -137 79
						-56 240 12 240 102 0 43 -30 129 -53 156 -27 29 -70 29 -126 -2z"/>
						<path fill="#28a745" d="M3333 1128 c-37 -59 -37 -56 -41 -433 -4 -427 -10 -402 94 -416 92
						-12 100 -5 108 93 17 202 44 317 99 418 23 45 24 45 76 45 45 0 56 4 77 28 36
						43 28 96 -22 142 -23 21 -37 25 -89 25 -55 0 -64 -3 -90 -30 -15 -16 -32 -27
						-37 -24 -4 3 -8 32 -8 65 0 91 -7 99 -90 99 -44 0 -72 -4 -77 -12z"/>
						<path fill="#28a745" d="M2455 553 c-40 -20 -80 -85 -95 -154 -16 -70 -5 -93 55 -119 35 -15
						50 -17 77 -8 109 34 146 82 128 164 -27 125 -79 161 -165 117z"/>
						<path fill="#e7060f" d="M1420 210 c-8 -16 -8 -20 3 -20 8 0 22 9 32 20 18 19 17 20 -3 20
						-12 0 -26 -9 -32 -20z"/>
						<path fill="#e7060f" d="M190 202 c-29 -24 -20 -57 20 -71 17 -5 30 -14 30 -20 0 -18 -22 -23
						-46 -10 -27 14 -39 8 -31 -16 8 -18 77 -21 95 -3 22 22 14 55 -18 68 -31 13
						-39 33 -18 46 6 4 16 0 23 -9 13 -19 31 -22 40 -8 8 14 -24 41 -50 41 -12 0
						-33 -8 -45 -18z"/>
						<path fill="#e7060f" d="M315 209 c-4 -6 -4 -13 -1 -16 8 -8 36 5 36 17 0 13 -27 13 -35 -1z"/>
						<path fill="#e7060f" d="M700 200 c0 -14 -7 -20 -23 -20 -35 0 -59 -32 -55 -72 3 -31 6 -33
						42 -36 45 -4 52 3 61 67 3 25 8 54 11 64 4 12 0 17 -15 17 -14 0 -21 -6 -21
						-20z m-14 -85 c-4 -15 -12 -25 -19 -22 -17 5 -21 35 -6 53 17 21 33 2 25 -31z"/>
						<path fill="#e7060f" d="M932 148 c-6 -40 -9 -74 -7 -76 14 -14 33 13 39 55 4 26 10 58 13 71
						4 17 1 22 -14 22 -16 0 -21 -11 -31 -72z"/>
						<path d="M1000 211 c0 -5 -3 -16 -6 -25 -4 -10 -1 -16 9 -16 8 0 17 11 19 25
						4 17 1 25 -8 25 -8 0 -14 -4 -14 -9z"/>
						<path fill="#e7060f" d="M1042 156 c-15 -81 -15 -86 2 -86 16 0 22 14 31 75 4 22 9 48 12 58
						3 12 -1 17 -15 17 -15 0 -21 -12 -30 -64z"/>
						<path fill="#e7060f" d="M1197 214 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z"/>
						<path fill="#e7060f" d="M1967 198 c-3 -13 -9 -47 -13 -75 -5 -43 -4 -53 8 -53 14 0 38 82 38
						132 0 26 -27 22 -33 -4z"/>
						<path fill="#e7060f" d="M2770 200 c0 -14 -7 -20 -23 -20 -35 0 -59 -32 -55 -72 3 -31 6 -33
						42 -36 45 -4 52 3 61 67 3 25 8 54 11 64 4 12 0 17 -15 17 -14 0 -21 -6 -21
						-20z m-14 -85 c-4 -15 -12 -25 -19 -22 -17 5 -21 35 -6 53 17 21 33 2 25 -31z"/>
						<path fill="#e7060f" d="M3016 203 c-3 -10 -8 -36 -11 -58 -4 -22 -9 -48 -12 -57 -3 -13 1
						-18 15 -18 15 0 21 9 26 43 4 23 9 57 12 75 5 25 3 32 -9 32 -9 0 -18 -8 -21
						-17z"/>
						<path fill="#e7060f" d="M380 195 c0 -8 -3 -15 -7 -15 -5 0 -8 -24 -8 -52 0 -43 3 -53 19 -56
						10 -2 23 1 29 7 8 8 8 11 -1 11 -18 0 -14 46 5 66 13 13 14 19 5 22 -6 2 -12
						10 -12 18 0 8 -7 14 -15 14 -8 0 -15 -7 -15 -15z"/>
						<path fill="#e7060f" d="M1120 195 c0 -8 -3 -15 -7 -15 -5 0 -8 -24 -8 -52 0 -43 3 -53 19
						-56 10 -2 23 1 29 7 8 8 8 11 -1 11 -18 0 -14 46 5 66 13 13 14 19 5 22 -6 2
						-12 10 -12 18 0 8 -7 14 -15 14 -8 0 -15 -7 -15 -15z"/>
						<path fill="#e7060f" d="M1830 193 c-11 -13 -14 -34 -12 -70 4 -48 6 -53 28 -53 13 0 24 5 24
						10 0 6 -5 10 -11 10 -14 0 -4 61 11 70 12 8 1 50 -14 50 -6 0 -18 -8 -26 -17z"/>
						<path fill="#e7060f" d="M307 163 c-17 -59 -17 -93 -3 -93 20 0 23 6 31 63 6 39 5 47 -8 47
						-9 0 -18 -8 -20 -17z"/>
						<path fill="#e7060f" d="M459 163 c-43 -50 -28 -96 28 -91 20 2 38 9 41 16 3 9 -3 11 -21 7
						-14 -4 -28 -2 -31 4 -5 7 6 11 29 11 35 0 36 1 33 33 -3 28 -7 32 -34 35 -19
						2 -35 -3 -45 -15z m51 -24 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1
						24 -14z"/>
						<path fill="#e7060f" d="M766 165 c-23 -23 -31 -62 -15 -81 14 -17 88 -16 79 1 -3 6 -15 9
						-27 7 -13 -1 -23 2 -23 8 0 5 14 10 30 10 34 0 49 23 33 49 -14 22 -57 25 -77
						6z m49 -26 c-4 -5 -13 -9 -22 -9 -13 0 -13 3 -3 15 13 15 35 11 25 -6z"/>
						<path fill="#e7060f" d="M1185 143 c-4 -21 -9 -46 -12 -55 -3 -13 1 -18 15 -18 19 0 32 37 32
						93 0 34 -29 18 -35 -20z"/>
						<path fill="#e7060f" d="M1245 143 c-4 -21 -9 -46 -12 -55 -3 -13 1 -18 14 -18 14 0 22 11 29
						42 13 61 38 64 29 4 -6 -37 -4 -46 8 -46 19 0 27 21 27 71 l0 39 -45 0 c-43 0
						-44 -1 -50 -37z"/>
						<path fill="#e7060f" d="M1386 165 c-23 -23 -31 -62 -15 -81 14 -17 88 -16 79 1 -3 6 -15 9
						-27 7 -13 -1 -23 2 -23 8 0 5 14 10 30 10 34 0 49 23 33 49 -14 22 -57 25 -77
						6z m49 -26 c-4 -5 -13 -9 -22 -9 -13 0 -13 3 -3 15 13 15 35 11 25 -6z"/>
						<path fill="#e7060f" d="M1497 163 c-3 -10 -8 -35 -12 -55 -5 -34 -4 -38 15 -38 15 0 20 6 20
						24 0 34 10 56 25 56 7 0 15 7 19 15 4 12 -3 15 -29 15 -24 0 -35 -5 -38 -17z"/>
						<path fill="#e7060f" d="M1584 164 c-18 -15 -17 -15 14 -10 41 7 41 -5 -1 -24 -20 -10 -32
						-22 -32 -35 0 -17 8 -20 46 -23 l46 -3 6 42 c3 23 3 48 0 55 -7 19 -55 18 -79
						-2z m46 -53 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z"/>
						<path fill="#e7060f" d="M1697 163 c-3 -10 -8 -35 -12 -56 -5 -34 -4 -38 12 -35 12 2 19 14
						21 33 4 33 20 58 32 50 5 -3 6 -23 3 -45 -5 -32 -2 -40 10 -40 11 0 18 14 22
						44 9 57 3 66 -45 66 -28 0 -40 -5 -43 -17z"/>
						<path fill="#e7060f" d="M2036 165 c-23 -23 -31 -62 -15 -81 14 -17 88 -16 79 1 -3 6 -15 9
						-27 7 -13 -1 -23 2 -23 8 0 5 14 10 30 10 34 0 49 23 33 49 -14 22 -57 25 -77
						6z m49 -26 c-4 -5 -13 -9 -22 -9 -13 0 -13 3 -3 15 13 15 35 11 25 -6z"/>
						<path fill="#e7060f" d="M2207 172 c-3 -4 -8 -28 -11 -54 -6 -40 -5 -48 8 -48 9 0 18 12 22
						28 12 53 15 62 26 62 7 0 9 -17 6 -45 -5 -38 -3 -45 11 -45 12 0 20 13 27 42
						13 61 38 64 29 4 -6 -37 -4 -46 8 -46 16 0 27 29 27 76 l0 31 -75 1 c-41 1
						-76 -2 -78 -6z"/>
						<path fill="#e7060f" d="M2400 165 c-13 -15 -11 -17 10 -15 43 4 46 -1 10 -14 -26 -10 -36
						-19 -38 -39 -3 -26 -1 -27 42 -27 43 0 45 1 51 34 11 54 4 71 -31 74 -19 2
						-36 -3 -44 -13z m40 -54 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9
						-9z"/>
						<path fill="#e7060f" d="M2515 156 c-9 -14 -15 -37 -13 -53 2 -22 9 -29 33 -34 20 -4 25 -8
						15 -12 -8 -4 -22 -5 -30 -4 -8 1 -17 -2 -20 -8 -3 -6 11 -11 32 -13 46 -4 64
						20 74 96 l7 52 -41 0 c-33 0 -45 -5 -57 -24z m55 -25 c0 -33 -16 -49 -33 -32
						-7 7 -7 18 1 36 16 34 32 32 32 -4z"/>
						<path fill="#e7060f" d="M2836 165 c-23 -23 -31 -62 -15 -81 14 -17 88 -16 79 1 -3 6 -15 9
						-27 7 -13 -1 -23 2 -23 8 0 5 14 10 30 10 34 0 49 23 33 49 -14 22 -57 25 -77
						6z m49 -26 c-4 -5 -13 -9 -22 -9 -13 0 -13 3 -3 15 13 15 35 11 25 -6z"/>
						<path fill="#e7060f" d="M3083 173 c-23 -9 -13 -25 12 -21 35 7 31 -4 -8 -22 -20 -10 -32 -22
						-32 -35 0 -17 8 -20 46 -23 l47 -3 6 45 c4 25 4 50 1 56 -6 10 -50 12 -72 3z
						m37 -62 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z"/>
						<path fill="#e7060f" d="M3246 143 c-4 -21 -9 -46 -12 -55 -4 -13 0 -18 14 -18 15 0 21 9 26
						39 4 27 12 40 26 44 32 8 23 27 -14 27 -31 0 -34 -3 -40 -37z"/>
						<path fill="#e7060f" d="M3326 135 c-9 -57 -4 -65 44 -65 45 0 46 1 55 63 6 38 5 47 -8 47
						-17 0 -27 -21 -27 -55 0 -13 -4 -27 -10 -30 -20 -12 -27 5 -20 45 6 34 5 40
						-10 40 -13 0 -19 -11 -24 -45z"/>
						<path fill="#e7060f" d="M3469 163 c-43 -50 -28 -96 28 -91 20 2 38 9 41 16 3 9 -3 11 -21 7
						-14 -4 -28 -2 -31 4 -5 7 6 11 29 11 35 0 36 1 33 33 -3 28 -7 32 -34 35 -19
						2 -35 -3 -45 -15z m51 -24 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1
						24 -14z"/>
						</g>
					</svg>
				</td>
				<td style={{"width":"33%"}} ><h1 style={{"textAlign":"center","color": "#002060"}}><b>FACTURE</b></h1></td>
				<td style={{"width":"33%"}} >&nbsp;</td>
			</tr>
		</table>
		<table style={{"width":"100%"}}>
			<tr>
				<td style={{"textAlign":"left",  "width":"50%", "paddingBottom":"20px"}}>
					<p>
						<strong style={{"fontSize": "24px"}}>Liti.fr - Com'Sol</strong> <br />3 Rue de l'Atlas<br />75019 Paris France
					</p>
				</td>
				<td style={{"textAlign":"right",  "width":"50%", "paddingBottom":"20px"}} >
					<p>
						<strong  style={{"fontSize": "20px"}}> N° de facture :</strong> order ID  <br />
						<strong style={{"fontSize": "20px"}} >  Date : </strong>2021-04-44<br />
						<strong style={{"fontSize": "20px"}} >   Contact : </strong> email <br />
						<strong style={{"fontSize": "20px"}} >   Votre Réf. : </strong> payment_order_id 
					</p>
				</td>
			</tr>
		</table>
		<img src="https://busyplace.fr/images/line.png" alt="line"  style={{"width": "100%","height":" 10px", "marginLeft": "0%"}} />
		<table style={{"width":"100%"}}>
			<tr>
				<td style={{"width":"75%"}}>
					<br />
					<table  class="user_detail">
						<tr>
							<td style={{"width":"40%" ,"fontWeight": "bold","padding": "0px","paddingBottom":" 20px"}}>
								<p style={{"fontWeight": "normal","margin": "0px","padding":" 0px","lineHeight": "15px"}}><strong style={{"fontSize": "24px"}} >Facturé à :</strong></p>						
								<h3> {'societe'}</h3>
								<p style={{"fontWeight": "normal"}}>  A l'attention de {"nom_societe"} {"prenom_societe"},
									{"adresse"}<br />{"code_postal"} {"ville"} </p>							
									A l'attention de {"nom"} {"prenom"}
								<p style={{"fontWeight": "normal"}} >   {"adresse"},{"code_postal"} {"ville"}  </p>
							
							</td>
						</tr>
					</table>
				</td>
				<td style={{"width":"25%"}}></td>
			</tr>
		</table>
		<br /> <br />
		<table border="0" style={{"width": "100%", "marginLeft": "0%","borderCollapse":"collapse","textAlign": "right"}}>
			<tr>
				<td style={{"width":"50%"}}>&nbsp;</td>
				<td style={{"width":"25%","padding":"20px"}} ><strong>Reste dû</strong><br /></td>
				<td style={{"width":"25%" ,"padding":"20px","paddingRight":"5px","textAlign":"right","backgroundColor":"#047bf8", "color": "#ffffff"}}  ><strong>{"543654"} &euro;</strong></td>
			</tr>
		</table>
		<table className="order_detail" border="1" style={{"width": "100%", "marginLeft":"0%","borderCollapse":"collapse"}} >
			<tr>
				<th style={{"width":"50%", "backgroundColor": "#047bf8","color": "#fff","border": "1px solid grey"}}></th>
				<th style={{"width":"11.5%", "backgroundColor": "#002060","color": "#fff","border": "1px solid grey"}} ></th>
				<th style={{"width":"20%", "backgroundColor": "#047bf8","color": "#fff","border": "1px solid grey"}} >Total HT</th>
			</tr>
			<tr>
				<td style={{"textAlign":"center","border":"1px solid grey"}}>{'newspaper_name'}</td>
				<td style={{"textAlign":"center","paddingLeft":"20px","border":"1px solid grey"}} ><br/><b>{'quantity'}<br/><br/></b></td>
				<td style={{"textAlign":"center","backgroundColor": "#047bf8","border":"1px solid grey"}}  >{"news_price"} &euro;</td>
			</tr>
			
			<tr>
				<td style={{"textAlign":"center","border":"1px solid grey"}}>Option Sérénité</td>
				<td style={{"textAlign":"center","paddingLeft":"20px","border":"1px solid grey"}}><br/><b>1<br/><br/></b></td>
				<td style={{"textAlign":"center","backgroundColor": "#047bf8","border":"1px solid grey"}}>{"serenite"} &euro;</td>
			</tr>
		
		</table>
		<br />
		<table style={{"width": "100%", "marginLeft": "0%","borderCollapse":"collapse","textAlign": "right"}} >
				<tr>
					<td style={{"width":"50%"}}>&nbsp;</td>
					<td style={{"width":"25%","padding":"5px"}}  >Total HT<br /></td>
					<td style={{"width":"25%","padding":"5px","paddingRight":"5px","textAlign":"right","backgroundColor":"#047bf8","color": "#ffffff"}} >{"subtotal"} &euro;</td>
				</tr>
				<tr>
					<td style={{"width":"50%"}}>&nbsp;</td>
					<td style={{"width":"25%","padding":"5px"}}>TVA<br /></td>
					<td style={{"width":"25%","padding":"5px","paddingRight":"5px","textAlign":"right","backgroundColor":"#047bf8","color": "#ffffff"}}>{"tax"} &euro;</td>
				</tr>
				<tr>
					<td style={{"width":"50%"}}>&nbsp;</td>
					<td style={{"width":"25%","padding":"5px"}} ><strong>Total TTC</strong><br /></td>
					<td style={{"width":"25%","padding":"5px","paddingRight":"5px","textAlign":"right","backgroundColor":"#047bf8","color": "#ffffff"}} ><strong>{"total"} &euro;</strong></td>
				</tr>
		</table>
		<page_footer id="footerContent" style={{"textAlign": "center"}}>
			<p style={{"textAlign": "center"}}>
			<svg  style={{"margin": "0 auto"}} version="1.0" xmlns="http://www.w3.org/2000/svg"
			width="120px" height="75px" viewBox="0 0 388.000000 188.000000"
			preserveAspectRatio="xMidYMid meet">

			<g transform="translate(0.000000,188.000000) scale(0.100000,-0.100000)"
			stroke="none" fill="#e7060f">
			<path fill="#28a745" d="M3024 1836 c-40 -17 -100 -80 -126 -130 -50 -99 -82 -260 -98 -496
			-12 -179 -12 -180 -100 -180 -46 0 -61 -4 -78 -23 -29 -31 -28 -56 6 -96 24
			-29 36 -34 87 -40 l60 -6 0 -140 c0 -77 -3 -197 -5 -267 -6 -165 -4 -168 120
			-168 64 0 91 4 99 14 9 10 13 93 15 278 1 145 6 270 10 279 5 12 26 18 79 22
			40 2 90 11 112 18 36 13 40 18 43 52 3 33 -2 43 -33 73 -34 31 -41 34 -99 34
			-39 0 -68 5 -74 13 -13 16 1 236 22 338 25 121 85 229 127 229 23 0 56 -47 64
			-93 4 -21 16 -42 26 -47 51 -28 129 8 129 58 0 31 -32 125 -51 150 -68 90
			-160 142 -249 142 -30 -1 -69 -7 -86 -14z"/>
			<path fill="#28a745" d="M241 1453 c-36 -37 -46 -102 -68 -433 -19 -307 -20 -338 -9 -445 11
			-121 22 -152 69 -199 46 -46 99 -66 172 -66 84 0 125 18 192 88 l63 64 11 -29
			c14 -39 56 -81 103 -104 54 -26 184 -26 241 0 58 26 145 118 145 152 0 38 -46
			62 -81 43 -13 -7 -59 -29 -103 -49 -92 -42 -102 -38 -108 38 -6 66 9 357 20
			419 5 25 6 51 3 56 -21 31 -116 43 -166 20 -15 -7 -33 -25 -41 -41 -17 -32
			-39 -244 -37 -344 2 -59 -7 -78 -22 -53 -4 7 -11 8 -18 1 -30 -24 -198 -103
			-210 -98 -15 6 -32 155 -23 199 3 12 10 122 16 243 6 121 18 292 25 379 17
			188 18 185 -83 186 -56 0 -67 -3 -91 -27z"/>
			<path fill="#28a745" d="M1244 1433 c-35 -30 -44 -58 -44 -141 0 -58 -4 -83 -15 -92 -12 -10
			-113 -28 -162 -30 -19 0 -44 -45 -37 -64 9 -23 75 -55 129 -63 25 -3 49 -11
			53 -17 4 -6 7 -126 7 -266 0 -304 5 -328 75 -394 84 -79 194 -87 311 -23 103
			55 138 81 139 100 0 11 8 17 23 17 17 0 28 -10 41 -40 43 -97 150 -143 280
			-121 75 12 105 30 175 103 50 53 53 81 10 104 -31 16 -33 15 -135 -31 -86 -39
			-106 -45 -119 -35 -25 21 -20 272 9 497 7 49 -13 63 -90 63 -117 0 -131 -21
			-145 -228 -6 -81 -8 -168 -4 -195 5 -42 4 -47 -14 -47 -11 0 -23 5 -27 11 -9
			14 -40 5 -137 -40 -116 -54 -122 -55 -145 -31 -16 17 -21 42 -27 141 -8 136 3
			398 17 416 5 6 44 15 86 18 97 9 132 26 132 63 0 42 -56 92 -102 92 -19 0 -50
			3 -67 6 l-31 7 0 68 c0 37 5 71 10 74 18 11 11 60 -11 73 -12 8 -54 16 -93 18
			-58 4 -75 2 -92 -13z"/>
			<path fill="#28a745" d="M787 1416 c-57 -28 -105 -71 -119 -106 -13 -35 -1 -100 26 -133 57
			-71 221 -43 257 44 18 44 -14 161 -53 194 -36 31 -50 31 -111 1z"/>
			<path fill="#28a745" d="M1871 1396 c-63 -33 -109 -80 -116 -119 -8 -43 19 -111 55 -137 79
			-56 240 12 240 102 0 43 -30 129 -53 156 -27 29 -70 29 -126 -2z"/>
			<path fill="#28a745" d="M3333 1128 c-37 -59 -37 -56 -41 -433 -4 -427 -10 -402 94 -416 92
			-12 100 -5 108 93 17 202 44 317 99 418 23 45 24 45 76 45 45 0 56 4 77 28 36
			43 28 96 -22 142 -23 21 -37 25 -89 25 -55 0 -64 -3 -90 -30 -15 -16 -32 -27
			-37 -24 -4 3 -8 32 -8 65 0 91 -7 99 -90 99 -44 0 -72 -4 -77 -12z"/>
			<path fill="#28a745" d="M2455 553 c-40 -20 -80 -85 -95 -154 -16 -70 -5 -93 55 -119 35 -15
			50 -17 77 -8 109 34 146 82 128 164 -27 125 -79 161 -165 117z"/>
			<path fill="#e7060f" d="M1420 210 c-8 -16 -8 -20 3 -20 8 0 22 9 32 20 18 19 17 20 -3 20
			-12 0 -26 -9 -32 -20z"/>
			<path fill="#e7060f" d="M190 202 c-29 -24 -20 -57 20 -71 17 -5 30 -14 30 -20 0 -18 -22 -23
			-46 -10 -27 14 -39 8 -31 -16 8 -18 77 -21 95 -3 22 22 14 55 -18 68 -31 13
			-39 33 -18 46 6 4 16 0 23 -9 13 -19 31 -22 40 -8 8 14 -24 41 -50 41 -12 0
			-33 -8 -45 -18z"/>
			<path fill="#e7060f" d="M315 209 c-4 -6 -4 -13 -1 -16 8 -8 36 5 36 17 0 13 -27 13 -35 -1z"/>
			<path fill="#e7060f" d="M700 200 c0 -14 -7 -20 -23 -20 -35 0 -59 -32 -55 -72 3 -31 6 -33
			42 -36 45 -4 52 3 61 67 3 25 8 54 11 64 4 12 0 17 -15 17 -14 0 -21 -6 -21
			-20z m-14 -85 c-4 -15 -12 -25 -19 -22 -17 5 -21 35 -6 53 17 21 33 2 25 -31z"/>
			<path fill="#e7060f" d="M932 148 c-6 -40 -9 -74 -7 -76 14 -14 33 13 39 55 4 26 10 58 13 71
			4 17 1 22 -14 22 -16 0 -21 -11 -31 -72z"/>
			<path d="M1000 211 c0 -5 -3 -16 -6 -25 -4 -10 -1 -16 9 -16 8 0 17 11 19 25
			4 17 1 25 -8 25 -8 0 -14 -4 -14 -9z"/>
			<path fill="#e7060f" d="M1042 156 c-15 -81 -15 -86 2 -86 16 0 22 14 31 75 4 22 9 48 12 58
			3 12 -1 17 -15 17 -15 0 -21 -12 -30 -64z"/>
			<path fill="#e7060f" d="M1197 214 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z"/>
			<path fill="#e7060f" d="M1967 198 c-3 -13 -9 -47 -13 -75 -5 -43 -4 -53 8 -53 14 0 38 82 38
			132 0 26 -27 22 -33 -4z"/>
			<path fill="#e7060f" d="M2770 200 c0 -14 -7 -20 -23 -20 -35 0 -59 -32 -55 -72 3 -31 6 -33
			42 -36 45 -4 52 3 61 67 3 25 8 54 11 64 4 12 0 17 -15 17 -14 0 -21 -6 -21
			-20z m-14 -85 c-4 -15 -12 -25 -19 -22 -17 5 -21 35 -6 53 17 21 33 2 25 -31z"/>
			<path fill="#e7060f" d="M3016 203 c-3 -10 -8 -36 -11 -58 -4 -22 -9 -48 -12 -57 -3 -13 1
			-18 15 -18 15 0 21 9 26 43 4 23 9 57 12 75 5 25 3 32 -9 32 -9 0 -18 -8 -21
			-17z"/>
			<path fill="#e7060f" d="M380 195 c0 -8 -3 -15 -7 -15 -5 0 -8 -24 -8 -52 0 -43 3 -53 19 -56
			10 -2 23 1 29 7 8 8 8 11 -1 11 -18 0 -14 46 5 66 13 13 14 19 5 22 -6 2 -12
			10 -12 18 0 8 -7 14 -15 14 -8 0 -15 -7 -15 -15z"/>
			<path fill="#e7060f" d="M1120 195 c0 -8 -3 -15 -7 -15 -5 0 -8 -24 -8 -52 0 -43 3 -53 19
			-56 10 -2 23 1 29 7 8 8 8 11 -1 11 -18 0 -14 46 5 66 13 13 14 19 5 22 -6 2
			-12 10 -12 18 0 8 -7 14 -15 14 -8 0 -15 -7 -15 -15z"/>
			<path fill="#e7060f" d="M1830 193 c-11 -13 -14 -34 -12 -70 4 -48 6 -53 28 -53 13 0 24 5 24
			10 0 6 -5 10 -11 10 -14 0 -4 61 11 70 12 8 1 50 -14 50 -6 0 -18 -8 -26 -17z"/>
			<path fill="#e7060f" d="M307 163 c-17 -59 -17 -93 -3 -93 20 0 23 6 31 63 6 39 5 47 -8 47
			-9 0 -18 -8 -20 -17z"/>
			<path fill="#e7060f" d="M459 163 c-43 -50 -28 -96 28 -91 20 2 38 9 41 16 3 9 -3 11 -21 7
			-14 -4 -28 -2 -31 4 -5 7 6 11 29 11 35 0 36 1 33 33 -3 28 -7 32 -34 35 -19
			2 -35 -3 -45 -15z m51 -24 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1
			24 -14z"/>
			<path fill="#e7060f" d="M766 165 c-23 -23 -31 -62 -15 -81 14 -17 88 -16 79 1 -3 6 -15 9
			-27 7 -13 -1 -23 2 -23 8 0 5 14 10 30 10 34 0 49 23 33 49 -14 22 -57 25 -77
			6z m49 -26 c-4 -5 -13 -9 -22 -9 -13 0 -13 3 -3 15 13 15 35 11 25 -6z"/>
			<path fill="#e7060f" d="M1185 143 c-4 -21 -9 -46 -12 -55 -3 -13 1 -18 15 -18 19 0 32 37 32
			93 0 34 -29 18 -35 -20z"/>
			<path fill="#e7060f" d="M1245 143 c-4 -21 -9 -46 -12 -55 -3 -13 1 -18 14 -18 14 0 22 11 29
			42 13 61 38 64 29 4 -6 -37 -4 -46 8 -46 19 0 27 21 27 71 l0 39 -45 0 c-43 0
			-44 -1 -50 -37z"/>
			<path fill="#e7060f" d="M1386 165 c-23 -23 -31 -62 -15 -81 14 -17 88 -16 79 1 -3 6 -15 9
			-27 7 -13 -1 -23 2 -23 8 0 5 14 10 30 10 34 0 49 23 33 49 -14 22 -57 25 -77
			6z m49 -26 c-4 -5 -13 -9 -22 -9 -13 0 -13 3 -3 15 13 15 35 11 25 -6z"/>
			<path fill="#e7060f" d="M1497 163 c-3 -10 -8 -35 -12 -55 -5 -34 -4 -38 15 -38 15 0 20 6 20
			24 0 34 10 56 25 56 7 0 15 7 19 15 4 12 -3 15 -29 15 -24 0 -35 -5 -38 -17z"/>
			<path fill="#e7060f" d="M1584 164 c-18 -15 -17 -15 14 -10 41 7 41 -5 -1 -24 -20 -10 -32
			-22 -32 -35 0 -17 8 -20 46 -23 l46 -3 6 42 c3 23 3 48 0 55 -7 19 -55 18 -79
			-2z m46 -53 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z"/>
			<path fill="#e7060f" d="M1697 163 c-3 -10 -8 -35 -12 -56 -5 -34 -4 -38 12 -35 12 2 19 14
			21 33 4 33 20 58 32 50 5 -3 6 -23 3 -45 -5 -32 -2 -40 10 -40 11 0 18 14 22
			44 9 57 3 66 -45 66 -28 0 -40 -5 -43 -17z"/>
			<path fill="#e7060f" d="M2036 165 c-23 -23 -31 -62 -15 -81 14 -17 88 -16 79 1 -3 6 -15 9
			-27 7 -13 -1 -23 2 -23 8 0 5 14 10 30 10 34 0 49 23 33 49 -14 22 -57 25 -77
			6z m49 -26 c-4 -5 -13 -9 -22 -9 -13 0 -13 3 -3 15 13 15 35 11 25 -6z"/>
			<path fill="#e7060f" d="M2207 172 c-3 -4 -8 -28 -11 -54 -6 -40 -5 -48 8 -48 9 0 18 12 22
			28 12 53 15 62 26 62 7 0 9 -17 6 -45 -5 -38 -3 -45 11 -45 12 0 20 13 27 42
			13 61 38 64 29 4 -6 -37 -4 -46 8 -46 16 0 27 29 27 76 l0 31 -75 1 c-41 1
			-76 -2 -78 -6z"/>
			<path fill="#e7060f" d="M2400 165 c-13 -15 -11 -17 10 -15 43 4 46 -1 10 -14 -26 -10 -36
			-19 -38 -39 -3 -26 -1 -27 42 -27 43 0 45 1 51 34 11 54 4 71 -31 74 -19 2
			-36 -3 -44 -13z m40 -54 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9
			-9z"/>
			<path fill="#e7060f" d="M2515 156 c-9 -14 -15 -37 -13 -53 2 -22 9 -29 33 -34 20 -4 25 -8
			15 -12 -8 -4 -22 -5 -30 -4 -8 1 -17 -2 -20 -8 -3 -6 11 -11 32 -13 46 -4 64
			20 74 96 l7 52 -41 0 c-33 0 -45 -5 -57 -24z m55 -25 c0 -33 -16 -49 -33 -32
			-7 7 -7 18 1 36 16 34 32 32 32 -4z"/>
			<path fill="#e7060f" d="M2836 165 c-23 -23 -31 -62 -15 -81 14 -17 88 -16 79 1 -3 6 -15 9
			-27 7 -13 -1 -23 2 -23 8 0 5 14 10 30 10 34 0 49 23 33 49 -14 22 -57 25 -77
			6z m49 -26 c-4 -5 -13 -9 -22 -9 -13 0 -13 3 -3 15 13 15 35 11 25 -6z"/>
			<path fill="#e7060f" d="M3083 173 c-23 -9 -13 -25 12 -21 35 7 31 -4 -8 -22 -20 -10 -32 -22
			-32 -35 0 -17 8 -20 46 -23 l47 -3 6 45 c4 25 4 50 1 56 -6 10 -50 12 -72 3z
			m37 -62 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z"/>
			<path fill="#e7060f" d="M3246 143 c-4 -21 -9 -46 -12 -55 -4 -13 0 -18 14 -18 15 0 21 9 26
			39 4 27 12 40 26 44 32 8 23 27 -14 27 -31 0 -34 -3 -40 -37z"/>
			<path fill="#e7060f" d="M3326 135 c-9 -57 -4 -65 44 -65 45 0 46 1 55 63 6 38 5 47 -8 47
			-17 0 -27 -21 -27 -55 0 -13 -4 -27 -10 -30 -20 -12 -27 5 -20 45 6 34 5 40
			-10 40 -13 0 -19 -11 -24 -45z"/>
			<path fill="#e7060f" d="M3469 163 c-43 -50 -28 -96 28 -91 20 2 38 9 41 16 3 9 -3 11 -21 7
			-14 -4 -28 -2 -31 4 -5 7 6 11 29 11 35 0 36 1 33 33 -3 28 -7 32 -34 35 -19
			2 -35 -3 -45 -15z m51 -24 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1
			24 -14z"/>
			</g>
			</svg>
			</p>

			<p style={{"fontSize":"20px" ,"textAlign":"center","lineHeight":"26px"}}>Liti.fr - Com'Sol - SARL au capital de 5.373,82 Euros - 3 rue de l'Atlas 75019 PARIS <br /> "T&#xE9;"l : 01 53 65 16 67 - Mail: contact@liti.fr<br />
			R.C. Paris B 398 427 062 - "N&#xB0;" EEC N° : FR 56 398 427 062<br />
			</p>

			<p style={{"fontSize":"20px" ,"textAlign":"center","lineHeight":"26px"}}>IBAN FR 38 3000 2056 6900 0023 0396 G03 - BIC : CRLYFRPP</p>
		</page_footer>

		</>
	);
};

export default InvoiceTemplate;